import * as Yup from 'yup'
import { getExpertPickerValidationSchema } from '@admin/components/ExpertPicker'
import { CATEGORY_HEADER_WIDGET_TYPE } from 'constants/common'
import { categoryHeaderItemSchemaValidation } from './CategoryHeaderItem'

export const FIELD_NAMES = {
  TITLE: 'title',
  SUBTITLE: 'subtitle',
  TYPE: 'categoryHeaderWidgetType',
  EXPERT: 'expert',
  ITEMS: 'cmsCategoryHeaderWidgetItems',
}

export const categoryHeaderSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    [FIELD_NAMES.TYPE]: Yup.string()
      .oneOf(Object.values(CATEGORY_HEADER_WIDGET_TYPE))
      .required()
      .label('Type')
      .default(CATEGORY_HEADER_WIDGET_TYPE.SINGLE_IMAGE),
    [FIELD_NAMES.TITLE]: Yup.string().trim().max(60).required().label('Title'),
    [FIELD_NAMES.SUBTITLE]: Yup.string()
      .trim()
      .max(180)
      .nullable()
      .label('Subtitle'),
    [FIELD_NAMES.EXPERT]: getExpertPickerValidationSchema(),
    [FIELD_NAMES.ITEMS]: categoryHeaderItemSchemaValidation
      .label('Images')
      .when(FIELD_NAMES.TYPE, {
        is: CATEGORY_HEADER_WIDGET_TYPE.CAROUSEL,
        then: (field) =>
          field.min(2, 'At least 2 images are required').required(),
        otherwise: (field) =>
          field
            .min(1, 'One image is required')
            .max(1, 'Only one image is allowed')
            .required(),
      }),
  }),
})
