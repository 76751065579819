import { v4 as uuidv4 } from 'uuid'
import { EMPTY_EXPERT_PICKER_OPTION } from '@admin/components/ExpertPicker'

export const getInitialExpertsItemData = () => ({
  expert: EMPTY_EXPERT_PICKER_OPTION,
  clientId: uuidv4(),
})

export const getExpertsItemErrorsFromForm = ({
  form,
  widgetIndex,
  expertsItemIndex,
}) => {
  const { widgets = [] } = form.errors

  const expertError =
    widgets &&
    widgets[widgetIndex]?.content?.cmsExpertsWidgetItems &&
    widgets[widgetIndex]?.content?.cmsExpertsWidgetItems[expertsItemIndex]
      ?.expert?.id
  return [...(expertError ? [expertError] : [])]
}
