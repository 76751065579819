import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { ImageAttachment } from '@admin/routes/Cms/components/ImageAttachment'
import { Input } from 'components/Input'
import { Flex, Grid } from 'components/Layout'
import { Textarea } from 'components/Textarea'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { SPACE } from 'Theme'
import { getHrefOrExpertError } from '../utils'

export const Introduction = ({ validationPrefix, widgetIndex }) => (
  <Grid gridTemplateColumns="1fr 1fr" gridGap={SPACE.PX_20}>
    <Flex flexDirection="column">
      <Field name={`${validationPrefix}.pretitle`}>
        {({ field, meta }) => (
          <Input
            label="Pre-title"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.title`}>
        {({ field, meta }) => (
          <Input
            label="Title"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.posttitle`}>
        {({ field, meta }) => (
          <Input
            label="Subtitle"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.description`}>
        {({ field, meta }) => (
          <Textarea
            rows="5"
            label="Description"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.buttonText`}>
        {({ field, meta }) => (
          <Input
            label="Button text"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.buttonLink`}>
        {({ field, meta, form }) => {
          const error = getHrefOrExpertError({ form, widgetIndex })
          return (
            <Input
              label="Button link"
              isError={meta.error !== undefined || error}
              hint={(meta.error || error) ?? ''}
              hasHint
              {...field}
            />
          )
        }}
      </Field>
    </Flex>
    <ImageAttachment
      fieldName={`${validationPrefix}.attachment`}
      hasPaddingOnTop={false}
      aspectRatio={ASPECT_RATIO.INTRODUCTION}
    />
  </Grid>
)

Introduction.propTypes = {
  validationPrefix: PropTypes.string.isRequired,
  widgetIndex: PropTypes.number.isRequired,
}
