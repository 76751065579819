import { Field } from 'formik'
import { isString } from 'lodash'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { CheckboxGroup } from 'components/Checkbox'
import { Input, ClickableReadonlyInput } from 'components/Input'
import { Flex, Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { SPACE, FONT_SIZE, COLOR } from 'Theme'
import { AddWidgetItemButton } from '../../AddWidgetItemButton'
import { addNewItemToList } from '../utils'
import {
  CollectionsCarouselItem,
  cmsCollectionsCarouselWidgetItemPropTypes,
  getInitialCollectionsCarouselItemData,
} from './CollectionsCarouselItem'

const ShowroomPagePickerModal = dynamic(
  () =>
    import('@admin/components/ShowroomPagePicker').then(
      (module) => module.ShowroomPagePickerModal
    ),
  {
    ssr: false,
  }
)

export const CollectionsCarousel = ({
  validationPrefix,
  cmsCollectionsCarouselWidgetItems,
  widgetIndex,
}) => {
  const [isShowroomPagePickerModalOpen, setIsShowroomPagePickerModalOpen] =
    useState(false)
  const collectionsCarouselItemsFieldName = `${validationPrefix}.cmsCollectionsCarouselWidgetItems`
  return (
    <>
      <Field name={`${validationPrefix}.title`}>
        {({ field, meta }) => (
          <Input
            label="Title"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.subtitle`}>
        {({ field, meta }) => (
          <Input
            label="Subtitle"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <CheckboxGroup>
        <Field type="checkbox" name={`${validationPrefix}.hasExploreAllButton`}>
          {({ field: { checked, ...restField }, form: { setFieldValue } }) => (
            <CheckboxGroup.Item
              isChecked={checked}
              {...restField}
              onChange={({ target }) => {
                setFieldValue(
                  `${validationPrefix}.hasExploreAllButton`,
                  target.checked
                )
                if (!target.checked) {
                  setFieldValue(`${validationPrefix}.cmsShowroomPage`, {})
                }
              }}
            >
              Show &quot;Explore all&quot; Button
            </CheckboxGroup.Item>
          )}
        </Field>
      </CheckboxGroup>
      <Field name={`${validationPrefix}.cmsShowroomPage`}>
        {({
          field: { name },
          meta,
          form: { setFieldValue, getFieldProps },
        }) => {
          const { value: hasExploreAllButton } = getFieldProps(
            `${validationPrefix}.hasExploreAllButton`
          )
          const { value } = getFieldProps(`${validationPrefix}.cmsShowroomPage`)
          return hasExploreAllButton ? (
            <Box mb={SPACE.PX_15}>
              <ShowroomPagePickerModal
                selectedShowroomPage={value}
                selectedShowroomPageId={value?.id}
                onSelect={(selectedShowroomPage) => {
                  setFieldValue(name, selectedShowroomPage)
                  setIsShowroomPagePickerModalOpen(false)
                }}
                isModalOpen={isShowroomPagePickerModalOpen}
                onModalClose={() => {
                  setIsShowroomPagePickerModalOpen(false)
                }}
              />
              <ClickableReadonlyInput
                onClick={() => {
                  setIsShowroomPagePickerModalOpen(true)
                }}
                label="Link"
                placeholder="Click to select a Showroom Page..."
                value={value?.cmsPage?.title ?? ''}
                hint={meta.error?.id || ''}
                isError={meta.error !== undefined}
              />
            </Box>
          ) : null
        }}
      </Field>
      <Field name={collectionsCarouselItemsFieldName}>
        {({ form: { setFieldValue }, meta }) => (
          <>
            {isString(meta?.error) && (
              <Flex flexDirection="column">
                <Text
                  fontSize={FONT_SIZE.PX_12}
                  mt={SPACE.PX_5}
                  color={COLOR.SECONDARY_1}
                >
                  {meta.error}
                </Text>
              </Flex>
            )}
            <AddWidgetItemButton
              onClick={() => {
                setFieldValue(collectionsCarouselItemsFieldName, [
                  getInitialCollectionsCarouselItemData(),
                  ...(cmsCollectionsCarouselWidgetItems ?? []),
                ])
              }}
            >
              Add collection
            </AddWidgetItemButton>
          </>
        )}
      </Field>
      {cmsCollectionsCarouselWidgetItems?.map(
        (cmsCollectionsCarouselWidgetItem, index) => (
          <React.Fragment key={cmsCollectionsCarouselWidgetItem.clientId}>
            <CollectionsCarouselItem
              widgetIndex={widgetIndex}
              cmsCollectionsCarouselWidgetItems={
                cmsCollectionsCarouselWidgetItems
              }
              cmsCollectionsCarouselWidgetItem={
                cmsCollectionsCarouselWidgetItem
              }
              collectionsCarouselItemsFieldName={
                collectionsCarouselItemsFieldName
              }
              index={index}
            />
            <Field>
              {({ form: { setFieldValue } }) => (
                <AddWidgetItemButton
                  onClick={() => {
                    addNewItemToList({
                      setFieldValue,
                      index,
                      array: cmsCollectionsCarouselWidgetItems,
                      fieldLocation: collectionsCarouselItemsFieldName,
                      initialData: getInitialCollectionsCarouselItemData(),
                    })
                  }}
                >
                  Add collection
                </AddWidgetItemButton>
              )}
            </Field>
          </React.Fragment>
        )
      )}
    </>
  )
}

CollectionsCarousel.propTypes = {
  widgetIndex: PropTypes.number,
  validationPrefix: PropTypes.string.isRequired,
  cmsCollectionsCarouselWidgetItems: PropTypes.arrayOf(
    cmsCollectionsCarouselWidgetItemPropTypes
  ),
}
