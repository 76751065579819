import * as Yup from 'yup'
import { cmsExpertsWidgetItemValidationSchema } from './ExpertsItem'

export const expertsSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    title: Yup.string().trim().max(200).label('Title'),
    isButtonHidden: Yup.boolean().required().label('Hide button'),
    mobilePageSize: Yup.number()
      .required()
      .min(4)
      .max(100)
      .label('Mobile page size'),
    desktopPageSize: Yup.number()
      .required()
      .min(4)
      .max(100)
      .label('Desktop page size'),
    cmsExpertsWidgetItems: cmsExpertsWidgetItemValidationSchema,
  }),
})
