import React, { useState } from 'react'
import { type EXPERT_USER_CAPABILITIES } from 'shared-constants'
import {
  Select,
  type SelectOptionType,
  type SelectProps,
} from 'components/Select'
import { useDebouncedInput } from 'hooks'
import { MODAL_SELECT_DROPDOWN_HEIGHT } from 'Theme'
import {
  ExpertStatus,
  useAdminExpertPickerQuery,
} from 'types/graphql-generated'
import { formatExpertPickerOption } from './constants'

type ExpertOption = {
  id: number
  profileName?: string
  businessName?: string
}

export interface ExpertPickerProps
  extends SelectProps<SelectOptionType<ExpertOption>> {
  capabilitiesIncluded?: (typeof EXPERT_USER_CAPABILITIES)[keyof typeof EXPERT_USER_CAPABILITIES][]
  capabilitiesExcluded?: (typeof EXPERT_USER_CAPABILITIES)[keyof typeof EXPERT_USER_CAPABILITIES][]
  mustHaveShowroom?: boolean
}

export const ExpertPicker: React.FC<ExpertPickerProps> = ({
  capabilitiesIncluded = null,
  capabilitiesExcluded = null,
  mustHaveShowroom = false,
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState('')
  const { isBouncing, debouncedValue } = useDebouncedInput(searchValue)
  const { data } = useAdminExpertPickerQuery({
    variables: {
      input: {
        filter: {
          statuses: [ExpertStatus.Active],
          capabilitiesIncluded,
          capabilitiesExcluded,
          mustHaveShowroom,
        },
        search: debouncedValue,
      },
    },
  })

  const fetchedExperts =
    data?.adminDashboardExperts?.edges.map(({ node }) => node) ?? []

  return (
    <Select
      isSearchable
      isLoading={isBouncing}
      height={MODAL_SELECT_DROPDOWN_HEIGHT}
      options={
        isBouncing
          ? []
          : [
              {
                value: {
                  id: null,
                  profileName: null,
                  businessName: null,
                },
                label: '-- none --',
              },
              ...fetchedExperts.map(({ id, profileName, businessName }) =>
                formatExpertPickerOption({ id, profileName, businessName })
              ),
            ]
      }
      onInputChange={setSearchValue}
      {...rest}
    />
  )
}
