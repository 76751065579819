import { get, reduce } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import {
  EMPTY_EXPERT_PICKER_OPTION,
  formatExpertPickerOption,
} from '@admin/components/ExpertPicker'
import {
  COLLECTION_HEADER_WIDGET_TYPE,
  MULTI_COLLECTION_WIDGET_TYPE,
} from 'constants/common'
import {
  COLLECTION_SORT_FIELDS,
  SORT_DIRECTION_ENUM,
} from 'routes/admin/constants/general'
import { ALLOWED_WIDGET_TYPES } from './constants'
import { widgetItemsDbFormatter } from './WidgetItems/dbFormatters'

const WIDGETS_WITH_ITEMS = {
  [ALLOWED_WIDGET_TYPES.QA]: ALLOWED_WIDGET_TYPES.QA,
  [ALLOWED_WIDGET_TYPES.PRODUCTS]: ALLOWED_WIDGET_TYPES.PRODUCTS,
  [ALLOWED_WIDGET_TYPES.EXPERT_COLLECTIONS]:
    ALLOWED_WIDGET_TYPES.EXPERT_COLLECTIONS,
  [ALLOWED_WIDGET_TYPES.HERO_CAROUSEL]: ALLOWED_WIDGET_TYPES.HERO_CAROUSEL,
  [ALLOWED_WIDGET_TYPES.COLLECTIONS_CAROUSEL]:
    ALLOWED_WIDGET_TYPES.COLLECTIONS_CAROUSEL,
}

const DEFAULT_WIDGET_DB_FORMATTERS = reduce(
  WIDGETS_WITH_ITEMS,
  (acc, widgetType) => ({
    ...acc,
    [widgetType]: ({ content, shared }) => {
      const itemsKey = `cms${widgetType.slice(3)}Items`
      const widgetItems = get(content, itemsKey, [])
      return {
        ...shared,
        content: {
          ...content,
          [itemsKey]: widgetItems.map((item) => ({
            ...item,
            clientId: uuidv4(),
          })),
        },
      }
    },
  }),
  {}
)

const formatGalleryWidgetFromDb = ({ content, shared }) => {
  const { cmsGalleryWidgetItems, title, ...restContent } = content
  return {
    ...shared,
    content: {
      title: title ?? '',
      cmsGalleryWidgetItems: cmsGalleryWidgetItems.map(
        ({ caption, expert, ...rest }) => ({
          expert: expert
            ? formatExpertPickerOption(expert)
            : EMPTY_EXPERT_PICKER_OPTION,
          caption: caption ?? '',
          ...rest,
          clientId: uuidv4(),
        })
      ),
      ...restContent,
    },
  }
}

const formatArticlesWidgetFromDb = ({ content, shared }) => {
  const { cmsArticlesWidgetItems, title } = content
  return {
    ...shared,
    content: {
      title: title ?? '',
      ...content,
      cmsArticlesWidgetItems: (cmsArticlesWidgetItems.data || []).map(
        (cmsArticlesWidgetItem) => ({
          ...cmsArticlesWidgetItem,
          clientId: uuidv4(),
        })
      ),
    },
  }
}

const formatExpertsWidgetFromDb = ({ content, shared }) => {
  const { cmsExpertsWidgetItems, ...restContent } = content
  return {
    ...shared,
    content: {
      cmsExpertsWidgetItems: (cmsExpertsWidgetItems.data || []).map(
        (cmsExpertsWidgetItem) => ({
          ...cmsExpertsWidgetItem,
          expert: formatExpertPickerOption(cmsExpertsWidgetItem.expert),
          clientId: uuidv4(),
        })
      ),
      ...restContent,
    },
  }
}

const formatMultiCollectionWidgetFromDb = ({ content, shared }) => {
  const {
    defaultSortField,
    defaultSortDirection,
    isShopAllProductsButtonEnabled,
    multiCollectionWidgetType,
    firstPublishedAtFilter,
    ...restContent
  } = content

  return {
    ...shared,
    content: {
      isShopAllProductsButtonEnabled,
      multiCollectionWidgetType:
        multiCollectionWidgetType ?? MULTI_COLLECTION_WIDGET_TYPE.DEFAULT,
      defaultSortField:
        defaultSortField ?? COLLECTION_SORT_FIELDS.POSITION_INDEX_WEIGHT,
      defaultSortDesc: defaultSortDirection === SORT_DIRECTION_ENUM.DESC,
      isFirstPublishedAtFilterEnabled: !!firstPublishedAtFilter,
      firstPublishedAtFilter,
      ...restContent,
    },
  }
}

const formatCollectionHeaderWidgetFromDb = ({ content, shared }) => {
  const { collectionHeaderType, ...restContent } = content
  return {
    ...shared,
    content: {
      isWhiteTextColor:
        collectionHeaderType ===
        COLLECTION_HEADER_WIDGET_TYPE.WHITE_TEXT_ON_DARK,
      ...restContent,
    },
  }
}

const formatVideoSectionWidgetFromDb = ({ content, shared }) => {
  const { video, thumbnail, ...restContent } = content
  return {
    ...shared,
    content: {
      videoAttachment: video,
      thumbnailAttachment: thumbnail,
      ...restContent,
    },
  }
}

const formatAlphabeticalIndexWidgetFromDb = ({ content, shared }) => {
  const {
    cmsAlphabeticalIndexWidgetTitle,
    alphabeticalIndexWidgetType,
    ...restContent
  } = content
  return {
    ...shared,
    content: {
      title: cmsAlphabeticalIndexWidgetTitle,
      type: alphabeticalIndexWidgetType,
      ...restContent,
    },
  }
}

const formatCardNavigationWidgetFromDb = ({ content, shared }) => {
  const {
    cardNavigationWidgetPlacement,
    cardNavigationWidgetTitle,
    cardNavigationWidgetButtonText,
    cardNavigationWidgetButtonLink,
    cmsCardNavigationWidgetItems,
    ...restContent
  } = content
  return {
    ...shared,
    content: {
      title: cardNavigationWidgetTitle,
      placement: cardNavigationWidgetPlacement,
      buttonText: cardNavigationWidgetButtonText,
      buttonLink: cardNavigationWidgetButtonLink,
      hasButton: Boolean(
        cardNavigationWidgetButtonText && cardNavigationWidgetButtonLink
      ),
      cmsCardNavigationWidgetItems: cmsCardNavigationWidgetItems.map(
        (item) => ({
          ...item,
          clientId: uuidv4(),
        })
      ),
      ...restContent,
    },
  }
}

const formatSectionWidgetFromDb = ({ content, shared }) => {
  const { widgetItems, ...restContent } = content
  return {
    ...shared,
    content: {
      widgetItems: widgetItemsDbFormatter(widgetItems),
      ...restContent,
    },
  }
}

const formatSpotlightWidgetFromDb = ({ content, shared }) => {
  const { expert, ...restContent } = content
  return {
    ...shared,
    content: {
      expert: expert
        ? formatExpertPickerOption(expert)
        : EMPTY_EXPERT_PICKER_OPTION,
      ...restContent,
    },
  }
}

const formatCategoryHeaderWidgetFromDb = ({ content, shared }) => {
  const { expert, ...restContent } = content
  return {
    ...shared,
    content: {
      expert: expert
        ? formatExpertPickerOption(expert)
        : EMPTY_EXPERT_PICKER_OPTION,
      ...restContent,
    },
  }
}

const formatBannerWidgetFromDb = ({ content, shared }) => {
  const { expert, ...restContent } = content
  return {
    ...shared,
    content: {
      expert: expert
        ? formatExpertPickerOption(expert)
        : EMPTY_EXPERT_PICKER_OPTION,
      ...restContent,
    },
  }
}

export const WIDGET_DB_FORMATTERS_MAP = {
  ...DEFAULT_WIDGET_DB_FORMATTERS,
  [ALLOWED_WIDGET_TYPES.GALLERY]: formatGalleryWidgetFromDb,
  [ALLOWED_WIDGET_TYPES.EXPERTS]: formatExpertsWidgetFromDb,
  [ALLOWED_WIDGET_TYPES.ARTICLES]: formatArticlesWidgetFromDb,
  [ALLOWED_WIDGET_TYPES.MULTI_COLLECTION]: formatMultiCollectionWidgetFromDb,
  [ALLOWED_WIDGET_TYPES.COLLECTION_HEADER]: formatCollectionHeaderWidgetFromDb,
  [ALLOWED_WIDGET_TYPES.VIDEO_SECTION]: formatVideoSectionWidgetFromDb,
  [ALLOWED_WIDGET_TYPES.ALPHABETICAL_INDEX]:
    formatAlphabeticalIndexWidgetFromDb,
  [ALLOWED_WIDGET_TYPES.CARD_NAVIGATION]: formatCardNavigationWidgetFromDb,
  [ALLOWED_WIDGET_TYPES.SECTION]: formatSectionWidgetFromDb,
  [ALLOWED_WIDGET_TYPES.SPOTLIGHT]: formatSpotlightWidgetFromDb,
  [ALLOWED_WIDGET_TYPES.CATEGORY_HEADER]: formatCategoryHeaderWidgetFromDb,
  [ALLOWED_WIDGET_TYPES.BANNER]: formatBannerWidgetFromDb,
}
