import React from 'react'
import { Modal } from 'components/Modal'
import { MODAL_MAX_WIDTH } from 'Theme'
import { ExpertPicker, type ExpertPickerProps } from './ExpertPicker'

const MODAL_TITLE = 'Pick an Expert'

interface ExpertPickerModalProps extends ExpertPickerProps {
  isModalOpen: boolean
  onModalClose: () => void
  isLoading?: boolean
}

export const ExpertPickerModal: React.FC<ExpertPickerModalProps> = ({
  isModalOpen,
  onModalClose,
  ...rest
}) => (
  <Modal
    maxWidth={MODAL_MAX_WIDTH.PX_600}
    ariaLabel={MODAL_TITLE}
    title={MODAL_TITLE}
    isOpen={isModalOpen}
    isWithFooter={false}
    onModalClose={onModalClose}
    overflow="none"
  >
    <ExpertPicker {...rest} />
  </Modal>
)
