import { Field } from 'formik'
import { get, range, toPath } from 'lodash'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import { CheckboxGroup } from 'components/Checkbox'
import { ClickableReadonlyInput } from 'components/Input'
import { Box } from 'components/Layout'
import { SPACE } from 'Theme'

const ListingWidgetPickerModal = dynamic(
  () =>
    import('@admin/components/ListingWidgetPicker').then(
      (module) => module.ListingWidgetPickerModal
    ),
  {
    ssr: false,
  }
)

const CheckboxWrapper = styled(Box)`
  white-space: nowrap;
`

const DEFAULT_NUMBER_OF_AVAILABLE_LISTING_WIDGETS = 2

export const CmsListingWidgetsFormFields = ({
  numberOfAvailable,
  validationPrefix,
}) => {
  const [isListingWidgetPickerModalOpen, setIsListingWidgetPickerModalOpen] =
    useState(false)
  const [selectedListingWidget, setSelectedListingWidget] = useState()
  return (
    <>
      {range(numberOfAvailable).map((index) => (
        <Field key={index} name={`${validationPrefix}[${index}]`}>
          {({
            field: { name, value },
            meta,
            form: { values, setFieldValue },
          }) => {
            const isItemSet = !!value
            if (
              index > 0 &&
              !get(values, [...toPath(validationPrefix), index - 1, 'id'])
            ) {
              return null
            }
            return (
              <>
                <CheckboxWrapper mb={SPACE.PX_10} mt={index ? SPACE.PX_20 : 0}>
                  <CheckboxGroup>
                    <CheckboxGroup.Item
                      isChecked={isItemSet}
                      onChange={({ target }) => {
                        const cmsListingWidgets = get(
                          values,
                          validationPrefix,
                          []
                        )
                        setFieldValue(
                          validationPrefix,
                          target.checked
                            ? [...cmsListingWidgets.slice(0, index), {}]
                            : [
                                ...cmsListingWidgets.slice(0, index),
                                ...cmsListingWidgets.slice(index + 1),
                              ],
                          true
                        )
                      }}
                    >
                      Enable listing widget {index + 1}
                    </CheckboxGroup.Item>
                  </CheckboxGroup>
                </CheckboxWrapper>
                {isItemSet && (
                  <ClickableReadonlyInput
                    onClick={() => {
                      setSelectedListingWidget(name)
                      setIsListingWidgetPickerModalOpen(true)
                    }}
                    label="Listing widget"
                    placeholder="Click to select a listing widget..."
                    value={value?.name ?? ''}
                    isError={meta.error !== undefined}
                    hint={meta.error?.id || ''}
                  />
                )}
              </>
            )
          }}
        </Field>
      ))}
      <Field>
        {({ form: { values, setFieldValue } }) => {
          const cmsListingWidget = get(values, selectedListingWidget)
          return (
            <ListingWidgetPickerModal
              selectedListingWidget={cmsListingWidget}
              selectedListingWidgetId={cmsListingWidget?.id}
              onSelect={(newListingWidget) => {
                setFieldValue(selectedListingWidget, newListingWidget, true)
                setIsListingWidgetPickerModalOpen(false)
              }}
              isModalOpen={isListingWidgetPickerModalOpen}
              onModalClose={() => {
                setIsListingWidgetPickerModalOpen(false)
              }}
            />
          )
        }}
      </Field>
    </>
  )
}

CmsListingWidgetsFormFields.defaultProps = {
  numberOfAvailable: DEFAULT_NUMBER_OF_AVAILABLE_LISTING_WIDGETS,
}

CmsListingWidgetsFormFields.propTypes = {
  numberOfAvailable: PropTypes.number,
  validationPrefix: PropTypes.string.isRequired,
}
