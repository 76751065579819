import * as Yup from 'yup'

export const introductionSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    pretitle: Yup.string().trim().max(200).nullable().label('Pre-title'),
    title: Yup.string().required().trim().max(200).label('Title'),
    posttitle: Yup.string().trim().max(200).nullable().label('Post-title'),
    description: Yup.string().trim().required().label('Description'),
    buttonText: Yup.string().trim().max(200).required().label('Button text'),
    buttonLink: Yup.string()
      .trim()
      .max(200)
      .url()
      .required()
      .label('Button link'),
    attachment: Yup.object().shape({
      id: Yup.number().required().label('Image'),
    }),
  }),
})
