import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'components/Modal'
import { MODAL_MAX_WIDTH } from 'Theme'
import {
  cmsShowroomPageFilterPropTypes,
  cmsShowroomPagePropTypes,
  ShowroomPagePicker,
} from './ShowroomPagePicker'

const MODAL_TITLE = 'Pick a Showroom Page'

export const ShowroomPagePickerModal = ({
  isModalOpen,
  onModalClose,
  onSelect,
  selectedShowroomPageId,
  selectedShowroomPage,
  filter,
}) => (
  <Modal
    maxWidth={MODAL_MAX_WIDTH.PX_600}
    ariaLabel={MODAL_TITLE}
    title={MODAL_TITLE}
    isOpen={isModalOpen}
    isWithFooter={false}
    onModalClose={onModalClose}
    overflow="none"
  >
    <ShowroomPagePicker
      onSelect={onSelect}
      selectedShowroomPageId={selectedShowroomPageId}
      selectedShowroomPage={selectedShowroomPage}
      filter={filter}
    />
  </Modal>
)

ShowroomPagePickerModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedShowroomPageId: PropTypes.number,
  selectedShowroomPage: cmsShowroomPagePropTypes,
  filter: cmsShowroomPageFilterPropTypes,
}
