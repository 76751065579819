import * as Yup from 'yup'

export const cmsCollectionsCarouselWidgetItemValidationSchema = Yup.array()
  .of(
    Yup.object({
      cmsShowroomPage: Yup.object().shape({
        id: Yup.number().required().label('Showroom Page'),
      }),
      previewImageAttachment: Yup.object().shape({
        id: Yup.number().required().label('Preview Image'),
      }),
      profileImageAttachment: Yup.object()
        .shape({
          id: Yup.number().nullable().label('Profile Image'),
        })
        .nullable(),
      caption: Yup.string().nullable().label('Caption'),
    })
  )
  .label('Collections')
  .min(1)
