import { find } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { AltValueProposition } from '@admin/routes/Cms/components/Widgets/AltValueProposition'
import { CardNavigation } from '@admin/routes/Cms/components/Widgets/CardNavigation'
import { Box, Flex } from 'components/Layout'
import { ContextMenuButton } from '../ContextMenuButton'
import { WidgetWrapper } from '../WidgetWrapper'
import { AlphabeticalIndex } from './AlphabeticalIndex'
import { AltExpertsListing } from './AltExpertsListing'
import { ArticleHeading } from './ArticleHeading'
import { Articles } from './Articles'
import { Banner } from './Banner'
import { CategoryHeader } from './CategoryHeader'
import { CollectionHeader } from './CollectionHeader'
import { CollectionsCarousel } from './CollectionsCarousel'
import { Concierge } from './Concierge'
import {
  ALLOWED_WIDGET_TYPES,
  INITIAL_WIDGET_VALUES,
  WIDGET_MENU_ITEMS,
  WIDGET_NAMES,
} from './constants'
import { DoubleImage } from './DoubleImage'
import { ExpertCollections } from './ExpertCollections'
import { Experts } from './Experts'
import { ExpertsListing } from './ExpertsListing'
import { FeaturedIn } from './FeaturedIn'
import { Gallery } from './Gallery'
import { GiftCard } from './GiftCard'
import { Hero } from './Hero'
import { HeroCarousel } from './HeroCarousel'
import { HeroMain } from './HeroMain'
import { InfoCards } from './InfoCards'
import { Introduction } from './Introduction'
import { MultiCollection } from './MultiCollection'
import { Products } from './Products'
import { QA } from './QA'
import { RichText } from './RichText'
import { Section } from './Section'
import { SingleImage } from './SingleImage'
import { Spotlight } from './Spotlight'
import { Testimonials } from './Testimonials'
import {
  addNewItemToList,
  deleteItemFromFormList,
  getWidgetsValidationPrefix,
  initialWidgetData,
  moveItemDownInFormList,
  moveItemUpInFormList,
} from './utils'
import { ValueProposition } from './ValueProposition'
import { Video } from './Video'
import { VideoSection } from './VideoSection'

export const Form = ({
  setFieldValue,
  widgets,
  isMultiCollectionEditingDisabled = false,
}) => (
  <Box position="relative">
    <ContextMenuButton
      menuItems={WIDGET_MENU_ITEMS}
      onClick={(__typename) => {
        setFieldValue('widgets', [
          initialWidgetData(__typename, INITIAL_WIDGET_VALUES[__typename]),
          ...widgets,
        ])
      }}
    >
      Add widget
    </ContextMenuButton>
    {widgets.map((widget, index) => {
      const { content } = widget
      const { __typename: widgetTypename } = content
      const validationPrefix = getWidgetsValidationPrefix(index)
      const widgetActionInput = {
        setFieldValue,
        index,
        array: widgets,
        formLocation: 'widgets',
      }
      const isDeleteDisabled =
        isMultiCollectionEditingDisabled &&
        widgetTypename === ALLOWED_WIDGET_TYPES.MULTI_COLLECTION

      return (
        <Flex flexDirection="column" key={widget.clientId}>
          <WidgetWrapper
            widgetName={`${index + 1}. ${
              (
                find(
                  WIDGET_NAMES,
                  ({ __typename: typename }) => typename === widgetTypename
                ) || {}
              ).name
            }`}
            isMoveDownDisabled={index === widgets.length - 1}
            isMoveUpDisabled={index === 0}
            onDeleteWidget={() => {
              deleteItemFromFormList(widgetActionInput)
            }}
            onMoveDownWidget={() => {
              moveItemDownInFormList(widgetActionInput)
            }}
            onMoveUpWidget={() => {
              moveItemUpInFormList(widgetActionInput)
            }}
            isDeleteDisabled={isDeleteDisabled}
          >
            {widgetTypename === ALLOWED_WIDGET_TYPES.GALLERY && (
              <Gallery
                widgetIndex={index}
                validationPrefix={validationPrefix}
                cmsGalleryWidgetItems={content.cmsGalleryWidgetItems}
                setFieldValue={setFieldValue}
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.HERO_LEGACY && (
              <Hero validationPrefix={validationPrefix} />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.HERO_CAROUSEL && (
              <HeroCarousel
                validationPrefix={validationPrefix}
                cmsHeroCarouselItems={content.cmsHeroCarouselWidgetItems}
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.HERO_MAIN && (
              <HeroMain validationPrefix={validationPrefix} />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.INFO_CARDS && (
              <InfoCards />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.FEATURED_IN && (
              <FeaturedIn />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.EXPERTS && (
              <Experts
                widgetIndex={index}
                validationPrefix={validationPrefix}
                cmsExpertsWidgetItems={content.cmsExpertsWidgetItems}
                setFieldValue={setFieldValue}
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.EXPERTS_LISTING && (
              <ExpertsListing
                widgetIndex={index}
                validationPrefix={validationPrefix}
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.ARTICLES && (
              <Articles
                widgetIndex={index}
                validationPrefix={validationPrefix}
                cmsArticlesWidgetItems={content.cmsArticlesWidgetItems}
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.TESTIMONIALS && (
              <Testimonials
                validationPrefix={validationPrefix}
                cmsTestimonialsWidgetItems={content.cmsTestimonialsWidgetItems}
                setFieldValue={setFieldValue}
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.VIDEO && (
              <Video
                widgetIndex={index}
                validationPrefix={validationPrefix}
                setFieldValue={setFieldValue}
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.VIDEO_SECTION && (
              <VideoSection
                widgetIndex={index}
                validationPrefix={validationPrefix}
                setFieldValue={setFieldValue}
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.SPOTLIGHT && (
              <Spotlight
                widgetIndex={index}
                validationPrefix={validationPrefix}
                setFieldValue={setFieldValue}
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.RICH_TEXT && (
              <RichText validationPrefix={validationPrefix} />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.ARTICLE_HEADING && (
              <ArticleHeading validationPrefix={validationPrefix} />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.BANNER && (
              <Banner widgetIndex={index} validationPrefix={validationPrefix} />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.SINGLE_IMAGE && (
              <SingleImage validationPrefix={validationPrefix} />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.PRODUCTS && (
              <Products
                widgetIndex={index}
                validationPrefix={validationPrefix}
                cmsProductsWidgetItems={content.cmsProductsWidgetItems}
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.DOUBLE_IMAGE && (
              <DoubleImage validationPrefix={validationPrefix} />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.QA && (
              <QA
                widgetIndex={index}
                validationPrefix={validationPrefix}
                cmsQAWidgetItems={content.cmsQAWidgetItems}
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.GIFT_CARD && (
              <GiftCard validationPrefix={validationPrefix} />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.MULTI_COLLECTION && (
              <MultiCollection
                widgetIndex={index}
                validationPrefix={validationPrefix}
                cmsMultiCollectionWidgetItems={
                  content.cmsMultiCollectionWidgetItems
                }
                isTypeDisabled={isMultiCollectionEditingDisabled}
                isCollectionDisabled={isMultiCollectionEditingDisabled}
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.EXPERT_COLLECTIONS && (
              <ExpertCollections
                widgetIndex={index}
                validationPrefix={validationPrefix}
                cmsExpertCollectionsWidgetItems={
                  content.cmsExpertCollectionsWidgetItems
                }
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.CATEGORY_HEADER && (
              <CategoryHeader
                widgetIndex={index}
                validationPrefix={validationPrefix}
                cmsCategoryHeaderWidgetItems={
                  content.cmsCategoryHeaderWidgetItems
                }
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.COLLECTION_HEADER && (
              <CollectionHeader validationPrefix={validationPrefix} />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.COLLECTIONS_CAROUSEL && (
              <CollectionsCarousel
                widgetIndex={index}
                validationPrefix={validationPrefix}
                cmsCollectionsCarouselWidgetItems={
                  content.cmsCollectionsCarouselWidgetItems
                }
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.CARD_NAVIGATION && (
              <CardNavigation
                widgetIndex={index}
                validationPrefix={validationPrefix}
                cmsCardNavigationWidgetItems={
                  content.cmsCardNavigationWidgetItems
                }
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.INTRODUCTION && (
              <Introduction
                widgetIndex={index}
                validationPrefix={validationPrefix}
              />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.VALUE_PROPOSITION && (
              <ValueProposition />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.ALT_VALUE_PROPOSITION && (
              <AltValueProposition />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.ALPHABETICAL_INDEX && (
              <AlphabeticalIndex validationPrefix={validationPrefix} />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.ALT_EXPERTS_LISTING && (
              <AltExpertsListing />
            )}
            {widgetTypename === ALLOWED_WIDGET_TYPES.CONCIERGE && <Concierge />}
            {widgetTypename === ALLOWED_WIDGET_TYPES.SECTION && (
              <Section
                validationPrefix={validationPrefix}
                setFieldValue={setFieldValue}
              />
            )}
          </WidgetWrapper>
          <ContextMenuButton
            menuItems={WIDGET_MENU_ITEMS}
            onClick={(__typename) => {
              addNewItemToList({
                setFieldValue,
                index,
                array: widgets,
                initialData: initialWidgetData(
                  __typename,
                  INITIAL_WIDGET_VALUES[__typename]
                ),
                fieldLocation: 'widgets',
              })
            }}
          >
            Add widget
          </ContextMenuButton>
        </Flex>
      )
    })}
  </Box>
)

Form.propTypes = {
  widgets: PropTypes.arrayOf(PropTypes.shape({})),
  setFieldValue: PropTypes.func.isRequired,
  isMultiCollectionEditingDisabled: PropTypes.bool,
}
