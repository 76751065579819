import { type FieldProps, useField } from 'formik'
import React from 'react'
import { Flex } from 'components/Layout'
import { WidgetItemsForm } from '../WidgetItems/WidgetItemsForm'

interface SectionProps {
  setFieldValue: FieldProps['form']['setFieldValue']
  validationPrefix: string
}

export const Section: React.FC<React.PWC<SectionProps>> = ({
  setFieldValue,
  validationPrefix,
}) => {
  const [{ value: widgetItems = [] }] = useField(
    `${validationPrefix}.widgetItems`
  )
  return (
    <Flex flexDirection="column">
      <WidgetItemsForm
        widgetValidationPrefix={validationPrefix}
        widgetItems={widgetItems}
        setFieldValue={setFieldValue}
        addItemButtonLabel="Add section item"
      />
    </Flex>
  )
}
