import * as Yup from 'yup'
import { buttonWidgetItemValidationSchema } from './Button'
import { ALLOWED_WIDGET_ITEM_TYPES } from './constants'
import { headlineWidgetItemValidationSchema } from './Headline'
import { threeColumnCardWidgetItemValidationSchema } from './ThreeColumnCard'
import { twoColumnCardWidgetItemValidationSchema } from './TwoColumnCard'

const WIDGET_ITEMS_VALIDATION_SCHEMA_MAP = {
  [ALLOWED_WIDGET_ITEM_TYPES.BUTTON]: buttonWidgetItemValidationSchema,
  [ALLOWED_WIDGET_ITEM_TYPES.HEADLINE]: headlineWidgetItemValidationSchema,
  [ALLOWED_WIDGET_ITEM_TYPES.TWO_COLUMN_CARD]:
    twoColumnCardWidgetItemValidationSchema,
  [ALLOWED_WIDGET_ITEM_TYPES.THREE_COLUMN_CARD]:
    threeColumnCardWidgetItemValidationSchema,
}

export const widgetItemsValidationSchema = Yup.array()
  .of(
    Yup.lazy((widgetItem) => {
      const {
        content: { __typename: typename },
      } = widgetItem
      return WIDGET_ITEMS_VALIDATION_SCHEMA_MAP[typename] ?? Yup.object()
    })
  )
  .label('Widget Items')
