import { Field } from 'formik'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { WidgetWrapper } from '@admin/routes/Cms/components/WidgetWrapper'
import { ClickableReadonlyInput } from 'components/Input'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { SPACE, COLOR, FONT_SIZE } from 'Theme'
import {
  deleteItemFromFormList,
  moveItemDownInFormList,
  moveItemUpInFormList,
} from '../../utils'

const ArticlePickerModal = dynamic(
  () =>
    import('@admin/components/ArticlePicker').then(
      (module) => module.ArticlePickerModal
    ),
  {
    ssr: false,
  }
)

export const ArticlesItem = ({
  cmsArticlesWidgetItems,
  cmsArticlesWidgetItem,
  setFieldValue,
  articlesField,
  index,
}) => {
  const [isArticlePickerModalOpen, setIsArticlePickerModalOpen] =
    useState(false)
  const articlesItemActionParams = {
    setFieldValue,
    index,
    array: cmsArticlesWidgetItems,
    formLocation: articlesField,
  }
  return (
    <WidgetWrapper
      widgetName={`${index + 1}. Article`}
      isMoveDownDisabled={index === cmsArticlesWidgetItems.length - 1}
      isMoveUpDisabled={index === 0}
      onDeleteWidget={() => {
        deleteItemFromFormList(articlesItemActionParams)
      }}
      onMoveDownWidget={() => {
        moveItemDownInFormList(articlesItemActionParams)
      }}
      onMoveUpWidget={() => {
        moveItemUpInFormList(articlesItemActionParams)
      }}
    >
      <Flex flexDirection="column" width="100%">
        <Flex mb={SPACE.PX_20} flexDirection="column">
          <Field name={`${articlesField}[${index}].article.cmsPage.title`}>
            {({ field }) => (
              <ClickableReadonlyInput
                onClick={() => {
                  setIsArticlePickerModalOpen(true)
                }}
                placeholder="Click to select an article..."
                label="Article title"
                {...field}
              />
            )}
          </Field>
          <Field name={`${articlesField}[${index}].article.id`}>
            {({ meta }) =>
              meta?.error !== undefined && (
                <Text
                  fontSize={FONT_SIZE.PX_12}
                  mt={SPACE.PX_5}
                  color={COLOR.SECONDARY_1}
                >
                  {meta.error}
                </Text>
              )
            }
          </Field>
        </Flex>
      </Flex>
      <ArticlePickerModal
        selectedArticle={cmsArticlesWidgetItem?.article}
        selectedArticleId={cmsArticlesWidgetItem?.article?.id}
        onSelect={({ id, title }) => {
          setFieldValue(`${articlesField}[${index}].article`, {
            id,
            cmsPage: { title },
          })
          setIsArticlePickerModalOpen(false)
        }}
        isModalOpen={isArticlePickerModalOpen}
        onModalClose={() => {
          setIsArticlePickerModalOpen(false)
        }}
      />
    </WidgetWrapper>
  )
}

const cmsArticlesWidgetItemPropType = PropTypes.shape({
  clientId: PropTypes.string.isRequired,
  article: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    id: PropTypes.number,
  }),
})

ArticlesItem.propTypes = {
  cmsArticlesWidgetItems: PropTypes.arrayOf(cmsArticlesWidgetItemPropType),
  cmsArticlesWidgetItem: cmsArticlesWidgetItemPropType,
  setFieldValue: PropTypes.func.isRequired,
  articlesField: PropTypes.string.isRequired,
  index: PropTypes.number,
}
