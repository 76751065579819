import * as Yup from 'yup'
import { ALPHABETICAL_INDEX_WIDGET_TYPE } from 'constants/common'

export const FIELD_NAMES = {
  TITLE: 'title',
  TYPE: 'alphabeticalIndexWidgetType',
} as const

export const alphabeticalIndexSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    [FIELD_NAMES.TITLE]: Yup.string().trim().max(60).label('Title').nullable(),
    [FIELD_NAMES.TYPE]: Yup.string()
      .oneOf(Object.values(ALPHABETICAL_INDEX_WIDGET_TYPE))
      .required()
      .label('Type')
      .default(ALPHABETICAL_INDEX_WIDGET_TYPE.BRAND),
  }),
})
