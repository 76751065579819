import { useField, type FieldProps } from 'formik'
import { find, isString } from 'lodash'
import React from 'react'
import { WidgetWrapper } from '@admin/routes/Cms/components/WidgetWrapper'
import { Hint } from 'components/Input'
import { Flex } from 'components/Layout'
import { ContextMenuButton } from '../../ContextMenuButton'
import {
  addNewItemToList,
  deleteItemFromFormList,
  getWidgetItemsValidationPrefix,
  initialWidgetData,
  moveItemDownInFormList,
  moveItemUpInFormList,
} from '../utils'
import { Button } from './Button'
import {
  ALLOWED_WIDGET_ITEM_TYPES,
  INITIAL_WIDGET_ITEM_VALUES,
  WIDGET_ITEM_MENU_ITEMS,
  WIDGET_ITEM_NAMES,
} from './constants'
import { Headline } from './Headline'
import { ThreeColumnCard } from './ThreeColumnCard'
import { TwoColumnCard } from './TwoColumnCard'

interface WidgetItemsFormProps {
  setFieldValue: FieldProps['form']['setFieldValue']
  widgetItems: any[]
  widgetValidationPrefix: string
  addItemButtonLabel?: string
}

export const WidgetItemsForm: React.FC<React.PWC<WidgetItemsFormProps>> = ({
  setFieldValue,
  widgetItems,
  widgetValidationPrefix,
  addItemButtonLabel = 'Add widget item',
}) => {
  const widgetItemsFieldName = `${widgetValidationPrefix}.widgetItems`
  const [, { error: widgetItemsFieldError }] = useField(widgetItemsFieldName)
  return (
    <>
      {isString(widgetItemsFieldError) && (
        <Hint isError>{widgetItemsFieldError}</Hint>
      )}
      <ContextMenuButton
        menuItems={WIDGET_ITEM_MENU_ITEMS}
        onClick={(__typename) => {
          const initialWidgetItemValues = INITIAL_WIDGET_ITEM_VALUES[__typename]
          setFieldValue(widgetItemsFieldName, [
            initialWidgetData(__typename, initialWidgetItemValues),
            ...widgetItems,
          ])
        }}
      >
        {addItemButtonLabel}
      </ContextMenuButton>
      {widgetItems.map((widgetItem, index) => {
        const { content } = widgetItem
        const { __typename: widgetItemTypename } = content
        const validationPrefix = getWidgetItemsValidationPrefix(
          widgetItemsFieldName,
          index
        )
        const widgetItemActionInput = {
          setFieldValue,
          index,
          array: widgetItems,
          formLocation: widgetItemsFieldName,
        }

        return (
          <Flex flexDirection="column" key={widgetItem.clientId}>
            <WidgetWrapper
              widgetName={`${index + 1}. ${
                (
                  find(
                    WIDGET_ITEM_NAMES,
                    ({ __typename: typename }) =>
                      typename === widgetItemTypename
                  ) || {}
                ).name
              }`}
              isMoveDownDisabled={index === widgetItems.length - 1}
              isMoveUpDisabled={index === 0}
              onDeleteWidget={() => {
                deleteItemFromFormList(widgetItemActionInput)
              }}
              onMoveDownWidget={() => {
                moveItemDownInFormList(widgetItemActionInput)
              }}
              onMoveUpWidget={() => {
                moveItemUpInFormList(widgetItemActionInput)
              }}
            >
              {widgetItemTypename === ALLOWED_WIDGET_ITEM_TYPES.BUTTON && (
                <Button validationPrefix={validationPrefix} />
              )}
              {widgetItemTypename === ALLOWED_WIDGET_ITEM_TYPES.HEADLINE && (
                <Headline validationPrefix={validationPrefix} />
              )}
              {widgetItemTypename ===
                ALLOWED_WIDGET_ITEM_TYPES.TWO_COLUMN_CARD && (
                <TwoColumnCard validationPrefix={validationPrefix} />
              )}
              {widgetItemTypename ===
                ALLOWED_WIDGET_ITEM_TYPES.THREE_COLUMN_CARD && (
                <ThreeColumnCard validationPrefix={validationPrefix} />
              )}
            </WidgetWrapper>
            <ContextMenuButton
              menuItems={WIDGET_ITEM_MENU_ITEMS}
              onClick={(__typename) => {
                const initialWidgetValues =
                  INITIAL_WIDGET_ITEM_VALUES[__typename]
                addNewItemToList({
                  setFieldValue,
                  index,
                  array: widgetItems,
                  initialData: initialWidgetData(
                    __typename,
                    initialWidgetValues
                  ),
                  fieldLocation: widgetItemsFieldName,
                })
              }}
            >
              {addItemButtonLabel}
            </ContextMenuButton>
          </Flex>
        )
      })}
    </>
  )
}
