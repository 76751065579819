import { Field } from 'formik'
import React from 'react'
import { Input } from 'components/Input'
import { Flex } from 'components/Layout'
import { FIELD_NAMES } from './validationSchema'

interface ButtonProps {
  validationPrefix: string
}

export const Button: React.FC<React.PWC<ButtonProps>> = ({
  validationPrefix,
}) => (
  <Flex flexDirection="column">
    <Field name={`${validationPrefix}.${FIELD_NAMES.TEXT}`}>
      {({ field, meta }) => (
        <Input
          label="Button text*"
          isError={meta.error !== undefined}
          hint={meta.error ?? ''}
          hasHint
          {...field}
        />
      )}
    </Field>
    <Field name={`${validationPrefix}.${FIELD_NAMES.LINK}`}>
      {({ field, meta }) => (
        <Input
          label="Button URL*"
          isError={meta.error !== undefined}
          hint={meta.error ?? ''}
          hasHint
          {...field}
        />
      )}
    </Field>
  </Flex>
)
