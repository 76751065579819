import { Field, useFormikContext } from 'formik'
import { find, get } from 'lodash'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import { COLLECTION_SORT_FIELDS } from '@admin/constants/general'
import { CheckboxGroup } from 'components/Checkbox'
import { ClickableReadonlyInput, Input } from 'components/Input'
import { Box, Flex } from 'components/Layout'
import { Select } from 'components/Select'
import { Text } from 'components/Typography'
import { MULTI_COLLECTION_WIDGET_TYPE } from 'constants/common'
import { FONT_SIZE, SPACE, getTextColor } from 'Theme'
import { getNestedStringProperty } from 'utils/formatters'
import { AddWidgetItemButton } from '../../AddWidgetItemButton'
import { CmsListingWidgetsFormFields } from '../../CmsListingWidgetsFormFields'
import {
  COLLECTION_SORT_FIELD_OPTIONS,
  MULTI_COLLECTION_WIDGET_TYPE_OPTIONS,
} from '../constants'
import { addNewItemToList } from '../utils'
import {
  getInitialMultiCollectionItemData,
  MultiCollectionItem,
} from './MultiCollectionItem'

const CheckboxWrapper = styled(Box)`
  white-space: nowrap;
`

const ShowroomPagePickerModal = dynamic(
  () =>
    import('@admin/components/ShowroomPagePicker').then(
      (module) => module.ShowroomPagePickerModal
    ),
  {
    ssr: false,
  }
)

const ExpertPickerModal = dynamic(
  () =>
    import('@admin/components/ExpertPicker').then(
      (module) => module.ExpertPickerModal
    ),
  {
    ssr: false,
  }
)

export const MultiCollection = ({
  validationPrefix,
  cmsMultiCollectionWidgetItems,
  widgetIndex,
  isTypeDisabled = false,
  isCollectionDisabled = false,
}) => {
  const [isShowroomPagePickerModalOpen, setIsShowroomPagePickerModalOpen] =
    useState(false)
  const [isExpertPickerModalOpen, setIsExpertPickerModalOpen] = useState(false)
  const multiCollectionItemsFieldName = `${validationPrefix}.cmsMultiCollectionWidgetItems`
  const formikContext = useFormikContext()
  const thisWidgetValues = get(formikContext, [
    'values',
    'widgets',
    widgetIndex,
  ])
  const isDefaultType =
    get(
      thisWidgetValues,
      ['content', 'multiCollectionWidgetType'],
      MULTI_COLLECTION_WIDGET_TYPE.DEFAULT
    ) === MULTI_COLLECTION_WIDGET_TYPE.DEFAULT

  const isShowroomPageSelectShown = get(
    thisWidgetValues,
    ['content', 'isShopAllProductsButtonEnabled'],
    false
  )

  const isFirstPublishedAtInputVisible = get(
    thisWidgetValues,
    ['content', 'isFirstPublishedAtFilterEnabled'],
    false
  )

  return (
    <>
      <Flex flexDirection="column" mb={SPACE.PX_20}>
        <Field name={`${validationPrefix}.multiCollectionWidgetType`}>
          {({ field, meta, form: { setFieldValue } }) => (
            <Select
              defaultValue={find(MULTI_COLLECTION_WIDGET_TYPE_OPTIONS, {
                value: MULTI_COLLECTION_WIDGET_TYPE.DEFAULT,
              })}
              label="Type"
              options={MULTI_COLLECTION_WIDGET_TYPE_OPTIONS}
              isError={meta.error !== undefined}
              hint={meta.error ?? ''}
              isDisabled={isTypeDisabled}
              hasHint
              {...field}
              onChange={({ value }) => {
                setFieldValue(
                  `${validationPrefix}.multiCollectionWidgetType`,
                  false
                )
                setFieldValue(field.name, value, { shouldValidate: true })
                if (value === MULTI_COLLECTION_WIDGET_TYPE.FEATURED) {
                  setFieldValue(
                    `${validationPrefix}.isShopAllProductsButtonEnabled`,
                    true
                  )
                } else {
                  setFieldValue(
                    `${validationPrefix}.isShopAllProductsButtonEnabled`,
                    false
                  )
                }
              }}
            />
          )}
        </Field>
        {isDefaultType ? (
          <>
            <CheckboxWrapper>
              <CheckboxGroup>
                <Field
                  type="checkbox"
                  name={`${validationPrefix}.isSortEnabled`}
                >
                  {({ field: { checked, ...restField } }) => (
                    <CheckboxGroup.Item isChecked={checked} {...restField}>
                      Sort field enabled
                    </CheckboxGroup.Item>
                  )}
                </Field>
              </CheckboxGroup>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <CheckboxGroup>
                <Field
                  type="checkbox"
                  name={`${validationPrefix}.useBreakoutVariants`}
                >
                  {({ field: { checked, ...restField } }) => (
                    <CheckboxGroup.Item isChecked={checked} {...restField}>
                      Split by options
                    </CheckboxGroup.Item>
                  )}
                </Field>
              </CheckboxGroup>
            </CheckboxWrapper>
            <Flex flexDirection="row" alignItems="center">
              <Field name={`${validationPrefix}.defaultSortField`}>
                {({ field, meta, form: { setFieldValue, values } }) => (
                  <Select
                    isDisabled={
                      !get(values, `${validationPrefix}.isSortEnabled`)
                    }
                    label="Default sorting"
                    options={COLLECTION_SORT_FIELD_OPTIONS}
                    isError={meta.error !== undefined}
                    hint={meta.error ?? ''}
                    hasHint
                    {...field}
                    onChange={({ value }) => {
                      if (
                        value === COLLECTION_SORT_FIELDS.FIRST_PUBLISHED_AT_UTC
                      ) {
                        setFieldValue(
                          `${validationPrefix}.defaultSortDesc`,
                          true
                        )
                      } else {
                        setFieldValue(
                          `${validationPrefix}.defaultSortDesc`,
                          false
                        )
                      }
                      setFieldValue(field.name, value, { shouldValidate: true })
                    }}
                  />
                )}
              </Field>
              <CheckboxWrapper>
                <CheckboxGroup ml={SPACE.PX_20}>
                  <Field
                    type="checkbox"
                    name={`${validationPrefix}.defaultSortDesc`}
                  >
                    {({
                      field: { checked, ...restField },
                      form: { values },
                    }) => {
                      const { isSortEnabled, defaultSortField } = get(
                        values,
                        validationPrefix
                      )
                      return (
                        <CheckboxGroup.Item
                          isDisabled={
                            !isSortEnabled ||
                            !defaultSortField ||
                            defaultSortField ===
                              COLLECTION_SORT_FIELDS.POSITION_INDEX_WEIGHT ||
                            defaultSortField ===
                              COLLECTION_SORT_FIELDS.FIRST_PUBLISHED_AT_UTC
                          }
                          isChecked={checked}
                          {...restField}
                        >
                          Descending
                        </CheckboxGroup.Item>
                      )
                    }}
                  </Field>
                </CheckboxGroup>
              </CheckboxWrapper>
            </Flex>
            <CmsListingWidgetsFormFields
              validationPrefix={`${validationPrefix}.cmsListingWidgets`}
            />
            <CheckboxWrapper>
              <CheckboxGroup>
                <Field
                  type="checkbox"
                  name={`${validationPrefix}.isFirstPublishedAtFilterEnabled`}
                >
                  {({
                    field: { checked, ...restField },
                    form: { setFieldValue },
                  }) => (
                    <CheckboxGroup.Item
                      isChecked={checked}
                      {...restField}
                      onChange={({ target }) => {
                        setFieldValue(
                          `${validationPrefix}.isFirstPublishedAtFilterEnabled`,
                          target.checked
                        )
                        if (!target.checked) {
                          setFieldValue(
                            `${validationPrefix}.firstPublishedAtFilter`,
                            null
                          )
                        }
                      }}
                    >
                      Enable filter by first time published at
                    </CheckboxGroup.Item>
                  )}
                </Field>
              </CheckboxGroup>
            </CheckboxWrapper>
            {isFirstPublishedAtInputVisible && (
              <Field name={`${validationPrefix}.firstPublishedAtFilter`}>
                {({ field, meta }) => (
                  <Input
                    label="Number of days"
                    isError={meta.error !== undefined}
                    hint={meta.error ?? ''}
                    hasHint
                    type="number"
                    {...field}
                  />
                )}
              </Field>
            )}
          </>
        ) : (
          <>
            <Field name={`${validationPrefix}.headline`}>
              {({ field, meta }) => (
                <Input
                  label="Headline"
                  isError={meta.error !== undefined}
                  hint={meta.error ?? ''}
                  hasHint
                  {...field}
                />
              )}
            </Field>
            <CheckboxWrapper>
              <CheckboxGroup>
                <Field
                  type="checkbox"
                  name={`${validationPrefix}.isShopAllProductsButtonEnabled`}
                >
                  {({ field: { checked, ...restField } }) => (
                    <CheckboxGroup.Item isChecked={checked} {...restField}>
                      Shop all products button enabled
                    </CheckboxGroup.Item>
                  )}
                </Field>
              </CheckboxGroup>
            </CheckboxWrapper>
            {isShowroomPageSelectShown && (
              <>
                <Flex mb={SPACE.PX_10}>
                  <Field name={`${validationPrefix}.cmsShowroomPage`}>
                    {({
                      field: { name, value },
                      meta,
                      form: { setFieldValue },
                    }) => (
                      <>
                        <ShowroomPagePickerModal
                          selectedShowroomPage={value}
                          selectedShowroomPageId={value?.id}
                          onSelect={(selectedShowroomPage) => {
                            setFieldValue(name, selectedShowroomPage)
                            setFieldValue(
                              `${validationPrefix}.expert`,
                              undefined
                            )
                            setIsShowroomPagePickerModalOpen(false)
                          }}
                          isModalOpen={isShowroomPagePickerModalOpen}
                          onModalClose={() => {
                            setIsShowroomPagePickerModalOpen(false)
                          }}
                          filter={{
                            isPublished: true,
                          }}
                        />
                        <ClickableReadonlyInput
                          onClick={() => {
                            setIsShowroomPagePickerModalOpen(true)
                          }}
                          label="Showroom page"
                          placeholder="Click to select a Showroom Page..."
                          value={value?.cmsPage?.title ?? ''}
                          hint={meta.error?.id || ''}
                          isError={meta.error !== undefined}
                        />
                      </>
                    )}
                  </Field>
                </Flex>
                <Field name={`${validationPrefix}.expert`}>
                  {({
                    field,
                    meta,
                    form: { setFieldValue, setFieldTouched },
                  }) => (
                    <>
                      <ExpertPickerModal
                        name={field.name}
                        value={{
                          label: field.value?.profileName,
                          value: field.value,
                        }}
                        onChange={({ value }) => {
                          setFieldValue(field.name, value)
                          setFieldValue(
                            `${validationPrefix}.cmsShowroomPage`,
                            undefined
                          )
                          setFieldTouched(field.name, true)
                          setIsExpertPickerModalOpen(false)
                        }}
                        isModalOpen={isExpertPickerModalOpen}
                        onModalClose={() => {
                          setIsExpertPickerModalOpen(false)
                        }}
                        mustHaveShowroom
                      />
                      <ClickableReadonlyInput
                        onClick={() => {
                          setIsExpertPickerModalOpen(true)
                        }}
                        label="Expert showroom"
                        value={field.value?.profileName ?? ''}
                        placeholder="Click to select an expert with showroom..."
                        isError={meta.error !== undefined}
                        hint={
                          meta.error
                            ? getNestedStringProperty(meta.error)
                            : null
                        }
                        hasHint
                      />
                    </>
                  )}
                </Field>
              </>
            )}
          </>
        )}
      </Flex>
      <Field name={multiCollectionItemsFieldName}>
        {({ meta }) => (
          <Flex flexDirection="column">
            {meta?.error !== undefined && typeof meta.error === 'string' && (
              <Text
                fontSize={FONT_SIZE.PX_12}
                mt={SPACE.PX_5}
                color={getTextColor({ isWarning: false, isError: true })}
              >
                {meta.error}
              </Text>
            )}
          </Flex>
        )}
      </Field>
      <Field>
        {({ form: { setFieldValue } }) => (
          <AddWidgetItemButton
            onClick={() => {
              setFieldValue(multiCollectionItemsFieldName, [
                getInitialMultiCollectionItemData(),
                ...(cmsMultiCollectionWidgetItems ?? []),
              ])
            }}
            isDisabled={isCollectionDisabled}
          >
            Add collection
          </AddWidgetItemButton>
        )}
      </Field>
      {cmsMultiCollectionWidgetItems?.map(
        (cmsMultiCollectionWidgetItem, index) => (
          <React.Fragment key={cmsMultiCollectionWidgetItem.id}>
            <MultiCollectionItem
              widgetIndex={widgetIndex}
              cmsMultiCollectionWidgetItems={cmsMultiCollectionWidgetItems}
              cmsMultiCollectionWidgetItem={cmsMultiCollectionWidgetItem}
              multiCollectionItemsFieldName={multiCollectionItemsFieldName}
              index={index}
              isDisabled={isCollectionDisabled}
            />
            <Field>
              {({ form: { setFieldValue } }) => (
                <AddWidgetItemButton
                  onClick={() => {
                    addNewItemToList({
                      setFieldValue,
                      index,
                      array: cmsMultiCollectionWidgetItems,
                      fieldLocation: multiCollectionItemsFieldName,
                      initialData: getInitialMultiCollectionItemData(),
                    })
                  }}
                  isDisabled={isCollectionDisabled}
                >
                  Add collection
                </AddWidgetItemButton>
              )}
            </Field>
          </React.Fragment>
        )
      )}
    </>
  )
}

MultiCollection.propTypes = {
  widgetIndex: PropTypes.number,
  validationPrefix: PropTypes.string.isRequired,
  cmsMultiCollectionWidgetItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  isTypeDisabled: PropTypes.bool,
  isCollectionDisabled: PropTypes.bool,
}
