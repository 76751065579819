import * as Yup from 'yup'
import { isUrlValid } from 'utils/validation'

export const cmsProductsWidgetItemValidationSchema = Yup.array()
  .of(
    Yup.object({
      pretitle: Yup.string().label('Pretitle').trim(),
      title: Yup.string().label('Title').trim().required(),
      posttitle: Yup.string().label('Posttitle').trim(),
      description: Yup.string().label('Description').trim(),
      href: Yup.string()
        .label('URL')
        .trim()
        .test('valid-url', 'Wrong URL format', isUrlValid),
      attachment: Yup.object({
        id: Yup.number().label('Image').required(),
      }),
    })
  )
  .label('Products items')
  .min(1)
