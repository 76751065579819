import React from 'react'
import styled from 'styled-components'
import Icon, { ICON_SIZE } from 'components/Icon'
import { COLOR_INTENT } from 'Theme'

const IconWrapper = styled('div')`
  ${({ isDisabled }) => isDisabled && `opacity: 0.4;`}
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`

interface DropdownArrowProps {
  isDisabled?: boolean
  color?: string
}

export const DropdownArrow: React.FC<React.PWC<DropdownArrowProps>> = ({
  isDisabled,
  color = COLOR_INTENT.GRAY_70,
}) => (
  <IconWrapper isDisabled={isDisabled}>
    <Icon.CaretDown size={ICON_SIZE.PX_20} color={color} />
  </IconWrapper>
)
