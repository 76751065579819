import * as Yup from 'yup'
import { cmsArticlesWidgetItemValidationSchema } from './ArticlesItem'

export const articlesSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    title: Yup.string().trim().max(200).label('Title'),
    subtitle: Yup.string().trim().max(200).nullable().label('Subtitle'),
    cmsArticlesWidgetItems: cmsArticlesWidgetItemValidationSchema,
  }),
})
