import * as Yup from 'yup'

export const cmsExpertCollectionsWidgetItemValidationSchema = Yup.array()
  .of(
    Yup.object({
      cmsShowroomPage: Yup.object().when('expert', {
        is: null,
        then: (schema) =>
          schema.shape({
            id: Yup.number().required().label('Showroom Page'),
          }),
        otherwise: (schema) =>
          schema
            .shape({
              id: Yup.number().nullable().label('Showroom Page'),
            })
            .nullable(),
      }),
      expert: Yup.object()
        .shape({
          id: Yup.number().nullable().label('Expert'),
        })
        .nullable(),
      previewImageAttachment: Yup.object().shape({
        id: Yup.number().required().label('Preview Image'),
      }),
      profileImageAttachment: Yup.object()
        .shape({
          id: Yup.number().nullable().label('Profile Image'),
        })
        .nullable(),
      caption: Yup.string().nullable().label('Caption'),
    })
  )
  .label('Expert Pages')
  .min(1)
