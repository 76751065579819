import { Field } from 'formik'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React from 'react'
import { Button, BUTTON_VARIANT } from 'components/Button'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Hint } from 'components/Input'
import { Flex } from 'components/Layout'
import { Toast } from 'components/Toast'
import { ASPECT_RATIO } from 'components/UploadImage'
import { SPACE, COLOR } from 'Theme'

const UploadImageModal = dynamic(
  () =>
    import('components/UploadImage').then(
      (modules) => modules.UploadImageModal
    ),
  {
    ssr: false,
  }
)

export const UploadField = ({
  fieldName,
  validationPrefix,
  executeCreateAttachmentMutation,
  setUploadModalOpenImageId,
  uploadModalOpenImageId,
  label,
  aspectRatio,
}) => (
  <Field key={fieldName} name={`${validationPrefix}.${fieldName}`}>
    {({ field: { name, value }, form: { setFieldValue }, meta }) => {
      const hint = meta.error?.id ?? null
      return (
        <>
          <UploadImageModal
            ariaLabel="Crop and upload image"
            title="Crop and upload image"
            aspectRatio={aspectRatio}
            onConfirm={async ({ blob, crop }) => {
              if (blob) {
                try {
                  const {
                    data: { createAttachment },
                  } = await executeCreateAttachmentMutation({
                    variables: {
                      input: {
                        file: blob,
                        crop,
                      },
                    },
                  })
                  setFieldValue(name, createAttachment)
                  setUploadModalOpenImageId(null)
                } catch (error) {
                  Toast.error(
                    'Image could not get uploaded. Please try again later.'
                  )
                }
              }
            }}
            isOpen={uploadModalOpenImageId === fieldName}
            onModalClose={() => {
              setUploadModalOpenImageId(null)
            }}
          />
          <Flex flexDirection="column" alignSelf="start" flex={1}>
            <Image
              layout={IMAGE_LAYOUT.RESPONSIVE}
              aspectRatio={aspectRatio}
              alt={label}
              src={value?.url}
            />
            <Button
              variant={BUTTON_VARIANT.OUTLINE}
              onClick={() => {
                setUploadModalOpenImageId(fieldName)
              }}
              width="fit-content"
              mt={SPACE.PX_15}
            >
              Upload {label}
            </Button>
            <Hint color={COLOR.SECONDARY_1}>{hint}</Hint>
          </Flex>
        </>
      )
    }}
  </Field>
)

UploadField.defaultProps = {
  aspectRatio: ASPECT_RATIO.ARTICLE_PREVIEW_IMAGE,
}

UploadField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  validationPrefix: PropTypes.string.isRequired,
  executeCreateAttachmentMutation: PropTypes.func.isRequired,
  setUploadModalOpenImageId: PropTypes.func.isRequired,
  uploadModalOpenImageId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  aspectRatio: PropTypes.oneOf(Object.values(ASPECT_RATIO)),
}
