import React, { forwardRef } from 'react'
import {
  Button,
  BUTTON_SIZE,
  BUTTON_VARIANT,
  type ButtonProps,
} from 'components/Button'
import { Flex } from 'components/Layout'

export const AddWidgetItemButton: React.FC<React.PWC<ButtonProps>> = forwardRef(
  ({ children, ...rest }, ref) => (
    <Flex justifyContent="center">
      <Button
        width="initial"
        variant={BUTTON_VARIANT.OUTLINE}
        size={BUTTON_SIZE.S}
        {...rest}
        ref={ref}
      >
        {children}
      </Button>
    </Flex>
  )
)
