import * as Yup from 'yup'
import { COLLECTION_SORT_FIELDS } from '@admin/constants/general'
import { MULTI_COLLECTION_WIDGET_TYPE } from 'constants/common'
import { cmsMultiCollectionWidgetItemValidationSchema } from './MultiCollectionItem'

export const multiCollectionSchemaValidation = Yup.object().shape({
  content: Yup.object({
    multiCollectionWidgetType: Yup.string()
      .oneOf(Object.values(MULTI_COLLECTION_WIDGET_TYPE))
      .required('Select type')
      .label('Type')
      .default(MULTI_COLLECTION_WIDGET_TYPE.DEFAULT),
    headline: Yup.string()
      .trim()
      .max(50)
      .label('Headline')
      .when('type', {
        is: MULTI_COLLECTION_WIDGET_TYPE.FEATURED,
        then: (field) => field.required(),
      }),
    isShopAllProductsButtonEnabled: Yup.boolean()
      .default(true)
      .when('type', {
        is: MULTI_COLLECTION_WIDGET_TYPE.FEATURED,
        then: (field) => field.required(),
      }),
    cmsShowroomPage: Yup.object()
      .shape({
        id: Yup.number().required().label('Showroom Page'),
      })
      .nullable()
      .default(null)
      .when(['isShopAllProductsButtonEnabled', 'type'], {
        is: (isShopAllProductsButtonEnabled, type) =>
          type === MULTI_COLLECTION_WIDGET_TYPE.FEATURED &&
          isShopAllProductsButtonEnabled,
        then: (field) => field.required(),
      }),
    expert: Yup.object()
      .shape({
        id: Yup.number().required().label('Expert Showroom'),
      })
      .nullable()
      .default(null)
      .when(['isShopAllProductsButtonEnabled', 'type', 'cmsShowroomPage'], {
        is: (isShopAllProductsButtonEnabled, type, cmsShowroomPage) =>
          type === MULTI_COLLECTION_WIDGET_TYPE.FEATURED &&
          isShopAllProductsButtonEnabled &&
          !cmsShowroomPage,
        then: (field) => {
          field.required()
        },
      }),
    isSortEnabled: Yup.boolean(),
    useBreakoutVariants: Yup.boolean(),
    defaultSortDesc: Yup.boolean(),
    defaultSortField: Yup.string()
      .oneOf(Object.values(COLLECTION_SORT_FIELDS))
      .label('Default sorting')
      .when('isSortEnabled', {
        is: true,
        then: (field) =>
          field.required('Default sorting is required when sorting is enabled'),
      }),
    firstPublishedAtFilter: Yup.number()
      .nullable()
      .when('isFirstPublishedAtFilterEnabled', {
        is: true,
        then: (field) =>
          field
            .min(0)
            .required(
              'Days are required when first published at filter is enabled'
            ),
      }),
    cmsMultiCollectionWidgetItems: cmsMultiCollectionWidgetItemValidationSchema,
  }),
})
