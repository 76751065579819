import { Field } from 'formik'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { AddWidgetItemButton } from '@admin/routes/Cms/components/AddWidgetItemButton'
import { CheckboxGroup } from 'components/Checkbox'
import { Hint } from 'components/Input'
import { type CmsHeroCarouselWidgetFieldsFragment } from 'types/graphql-generated'
import { addNewItemToList } from '../utils'
import { HeroCarouselItem } from './HeroCarouselItem'

const initialHeroCarouselItemData = () => ({
  hasButton: false,
  clientId: uuidv4(),
})

interface HeroCarouselProps {
  validationPrefix: string
  cmsHeroCarouselItems: CmsHeroCarouselWidgetFieldsFragment['cmsHeroCarouselWidgetItems'] & {
    clientId: string
  }
}

export const HeroCarousel: React.FC<React.PWC<HeroCarouselProps>> = ({
  validationPrefix,
  cmsHeroCarouselItems = [],
}) => {
  const heroCarouselItemsFieldName = `${validationPrefix}.cmsHeroCarouselWidgetItems`
  return (
    <>
      <Field>
        {({ form: { setFieldValue } }) => (
          <AddWidgetItemButton
            onClick={() => {
              setFieldValue(heroCarouselItemsFieldName, [
                initialHeroCarouselItemData(),
                ...cmsHeroCarouselItems,
              ])
            }}
          >
            Add slide
          </AddWidgetItemButton>
        )}
      </Field>
      <Field name={heroCarouselItemsFieldName}>
        {({ field: { value = [] }, meta }) => (
          <>
            {/* This is needed, because meta.error can be an array of errors of children */}
            {value.length === 0 && meta.error && (
              <Hint isError>{meta.error}</Hint>
            )}
            {cmsHeroCarouselItems.map((item, index) => (
              <React.Fragment key={item.clientId}>
                <HeroCarouselItem
                  items={cmsHeroCarouselItems}
                  heroCarouselItemsFieldName={heroCarouselItemsFieldName}
                  index={index}
                />
                <Field>
                  {({ form: { setFieldValue } }) => (
                    <AddWidgetItemButton
                      onClick={() => {
                        addNewItemToList({
                          setFieldValue,
                          index,
                          array: cmsHeroCarouselItems,
                          fieldLocation: heroCarouselItemsFieldName,
                          initialData: initialHeroCarouselItemData(),
                        })
                      }}
                    >
                      Add slide
                    </AddWidgetItemButton>
                  )}
                </Field>
              </React.Fragment>
            ))}
          </>
        )}
      </Field>
      <Field type="checkbox" name={`${validationPrefix}.isAutoRotationEnabled`}>
        {({ field: { value, name }, form }) => (
          <CheckboxGroup>
            <CheckboxGroup.Item
              isChecked={value}
              onChange={({ target }) => {
                form.setFieldValue(name, target.checked)
              }}
            >
              Enable auto rotation
            </CheckboxGroup.Item>
          </CheckboxGroup>
        )}
      </Field>
    </>
  )
}
