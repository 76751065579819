import PropTypes from 'prop-types'
import React from 'react'
import { Button, BUTTON_VARIANT } from 'components/Button'
import { Flex } from 'components/Layout'
import { Toast } from 'components/Toast'
import {
  ASPECT_RATIO,
  UploadImageArea,
  UploadImageProvider,
} from 'components/UploadImage'
import { UploadVideoProvider, UploadVideoArea } from 'components/UploadVideo'
import { SPACE } from 'Theme'
import {
  useCreateVideoAttachmentMutation,
  useCreateAttachmentMutation,
} from 'types/graphql-generated'

export const ATTACHMENT_TYPE = {
  VIDEO: 'video/*',
  IMAGE: 'image/*',
}

export const UploadAttachment = ({ onSelect, inputAccept, aspectRatio }) => {
  const [
    executeCreateAttachmentMutation,
    { loading: isCreateAttachmentLoading },
  ] = useCreateAttachmentMutation()
  const [
    executeCreateVideoAttachmentMutation,
    { loading: isCreateVideoAttachmentLoading },
  ] = useCreateVideoAttachmentMutation()
  return (
    <Flex width="100%" flex={1}>
      {inputAccept === ATTACHMENT_TYPE.IMAGE && (
        <UploadImageProvider aspectRatio={aspectRatio}>
          {({ resetImage, saveImage, ...uploadImageAreaProps }) => {
            const { uploadedImage } = uploadImageAreaProps
            return (
              <Flex flexDirection="column" width="100%" flex={1}>
                <Flex width="100%" flex={1}>
                  <UploadImageArea
                    accept={inputAccept}
                    {...uploadImageAreaProps}
                  />
                </Flex>
                {uploadedImage && (
                  <>
                    <Button
                      variant={BUTTON_VARIANT.OUTLINE}
                      disabled={isCreateAttachmentLoading}
                      mt={SPACE.PX_15}
                      onClick={() => {
                        resetImage()
                      }}
                    >
                      Remove image
                    </Button>
                    <Button
                      isLoading={isCreateAttachmentLoading}
                      mt={SPACE.PX_10}
                      onClick={async () => {
                        await saveImage(async ({ blob, crop }) => {
                          if (blob) {
                            try {
                              const {
                                data: { createAttachment },
                              } = await executeCreateAttachmentMutation({
                                variables: {
                                  input: {
                                    file: blob,
                                    crop,
                                  },
                                },
                              })
                              onSelect(createAttachment)
                            } catch (error) {
                              Toast.error(
                                'Image could not get uploaded. Please try again later.'
                              )
                            }
                          }
                        })
                        resetImage()
                      }}
                    >
                      Use this image
                    </Button>
                  </>
                )}
              </Flex>
            )
          }}
        </UploadImageProvider>
      )}
      {inputAccept === ATTACHMENT_TYPE.VIDEO && (
        <UploadVideoProvider aspectRatio={aspectRatio}>
          {({ resetVideo, ...uploadImageAreaProps }) => {
            const { uploadedVideo } = uploadImageAreaProps
            return (
              <Flex flexDirection="column" width="100%" flex={1}>
                <Flex width="100%" flex={1}>
                  <UploadVideoArea
                    accept={inputAccept}
                    {...uploadImageAreaProps}
                  />
                </Flex>
                {uploadedVideo && (
                  <>
                    <Button
                      variant={BUTTON_VARIANT.OUTLINE}
                      disabled={isCreateVideoAttachmentLoading}
                      mt={SPACE.PX_15}
                      onClick={() => {
                        resetVideo()
                      }}
                    >
                      Remove video
                    </Button>
                    <Button
                      mt={SPACE.PX_10}
                      isLoading={isCreateVideoAttachmentLoading}
                      onClick={async () => {
                        if (uploadedVideo) {
                          try {
                            const {
                              data: { createVideoAttachment },
                            } = await executeCreateVideoAttachmentMutation({
                              variables: {
                                input: {
                                  file: uploadedVideo,
                                },
                              },
                            })
                            onSelect(createVideoAttachment)
                          } catch (error) {
                            Toast.error(
                              'Image could not get uploaded. Please try again later.'
                            )
                          }
                        }
                        resetVideo()
                      }}
                    >
                      Use this video
                    </Button>
                  </>
                )}
              </Flex>
            )
          }}
        </UploadVideoProvider>
      )}
    </Flex>
  )
}

UploadAttachment.defaultProps = {
  aspectRatio: ASPECT_RATIO.GALLERY,
  inputAccept: ATTACHMENT_TYPE.IMAGE,
}

UploadAttachment.propTypes = {
  aspectRatio: PropTypes.oneOf(Object.values(ASPECT_RATIO)),
  onSelect: PropTypes.func.isRequired,
  inputAccept: PropTypes.oneOf(Object.values(ATTACHMENT_TYPE)),
}
