import dynamic from 'next/dynamic'
import { rem } from 'polished'
import React, { forwardRef } from 'react'
import { WrappedLoader } from 'components/WrappedLoader'

const EDITOR_HEIGHT = {
  CONTENT: rem(150),
  TOOLBAR: rem(50),
}

const DynamicallyImportedTextEditor = dynamic(() => import('./TextEditor'), {
  loading: () => (
    <WrappedLoader
      isFillSpace={false}
      height={`calc(${EDITOR_HEIGHT.CONTENT} + ${EDITOR_HEIGHT.TOOLBAR})`}
    />
  ),
  ssr: false,
})

type DynamicallyImportedTextEditorProps = React.ComponentProps<
  typeof DynamicallyImportedTextEditor
>

export const TextEditor = forwardRef<
  any,
  Omit<DynamicallyImportedTextEditorProps, 'toolbarHeight' | 'contentHeight'> &
    Partial<
      Pick<
        DynamicallyImportedTextEditorProps,
        'toolbarHeight' | 'contentHeight'
      >
    >
>(({ ...props }, ref) => (
  <DynamicallyImportedTextEditor
    editorRef={ref}
    contentHeight={EDITOR_HEIGHT.CONTENT}
    toolbarHeight={EDITOR_HEIGHT.TOOLBAR}
    {...props}
  />
))
