import { Field } from 'formik'
import React from 'react'
import { AttachmentPickerField } from '@admin/routes/Navigation/AttachmentPickerField'
import { Input } from 'components/Input'
import { Flex, Grid } from 'components/Layout'
import { ASPECT_RATIO } from 'components/UploadImage'
import { SPACE } from 'Theme'
import { FIELD_NAMES } from './validationSchema'

interface CardProps {
  validationPrefix: string
}

export const Card: React.FC<React.PWC<CardProps>> = ({ validationPrefix }) => (
  <Grid gridGap={SPACE.PX_20} gridTemplateColumns="1fr 2fr">
    <Flex alignItems="start">
      <AttachmentPickerField
        name={`${validationPrefix}.${FIELD_NAMES.ATTACHMENT}`}
        aspectRatio={ASPECT_RATIO.WIDGET_ITEM_COLUMN_CARD}
      />
    </Flex>
    <Flex flexDirection="column" width="100%">
      <Field name={`${validationPrefix}.${FIELD_NAMES.TITLE}`}>
        {({ field, meta }) => (
          <Input
            label="Title*"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.${FIELD_NAMES.LINK}`}>
        {({ field, meta }) => (
          <Input
            label="URL"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
    </Flex>
  </Grid>
)
