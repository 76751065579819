import { Field } from 'formik'
import React from 'react'
import { CheckboxGroup } from 'components/Checkbox'
import { Input } from 'components/Input'
import { Flex, Grid } from 'components/Layout'
import { SPACE } from 'Theme'
import { VideoAttachmentField } from '../VideoAttachmentField'
import { VideoThumbnailField } from './VideoThumbnailField'

interface VideoSectionProps {
  widgetIndex: number
  validationPrefix: string
  setFieldsValue: (values: any) => void
}

export const VideoSection = ({
  validationPrefix,
  widgetIndex,
}: VideoSectionProps) => (
  <Flex flexDirection="column">
    <Field name={`${validationPrefix}.title`}>
      {({ field, meta }) => (
        <Input
          label="Headline*"
          isError={meta.error !== undefined}
          hint={meta.error ?? ''}
          hasHint
          {...field}
        />
      )}
    </Field>
    <Field name={`${validationPrefix}.subtitle`}>
      {({ field, meta }) => (
        <Input
          label="Subheadline"
          isError={meta.error !== undefined}
          hint={meta.error ?? ''}
          hasHint
          {...field}
        />
      )}
    </Field>
    <Field type="checkbox" name={`${validationPrefix}.hasCtaButton`}>
      {({
        field: { value, checked, ...restField },
        meta,
        form: { setFieldValue },
      }) => (
        <>
          {/* @ts-ignore - CheckboxGroup needs to be transpiled to Typescript */}
          <CheckboxGroup mt={SPACE.PX_20}>
            {/* @ts-ignore - CheckboxGroup needs to be transpiled to Typescript */}
            <CheckboxGroup.Item
              isChecked={checked}
              isError={meta.touched && meta.error !== undefined}
              {...restField}
              onChange={async ({ target }) => {
                await setFieldValue(
                  `${validationPrefix}.hasCtaButton`,
                  target.checked
                )
                if (!target.checked) {
                  setFieldValue(`${validationPrefix}.ctaCopy`, '')
                  setFieldValue(`${validationPrefix}.ctaCopyUrl`, '')
                }
              }}
            >
              Enable button
              {/* @ts-ignore - CheckboxGroup needs to be transpiled to Typescript */}
            </CheckboxGroup.Item>
          </CheckboxGroup>
        </>
      )}
    </Field>
    <Field name={`${validationPrefix}.ctaCopyUrl`}>
      {({ field, meta, form: { getFieldProps } }) => {
        const { value: hasCtaButton } = getFieldProps(
          `${validationPrefix}.hasCtaButton`
        )
        return hasCtaButton ? (
          <Input
            label="Button link*"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        ) : null
      }}
    </Field>
    <Field name={`${validationPrefix}.ctaCopy`}>
      {({ field, meta, form: { getFieldProps } }) => {
        const { value: hasCtaButton } = getFieldProps(
          `${validationPrefix}.hasCtaButton`
        )
        return hasCtaButton ? (
          <Input
            label="Button text*"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        ) : null
      }}
    </Field>
    <Grid gridTemplateColumns="1fr 1fr" mt={SPACE.PX_20} gridGap={SPACE.PX_20}>
      <VideoThumbnailField
        validationPrefix={validationPrefix}
        widgetIndex={widgetIndex}
      />
      <VideoAttachmentField
        validationPrefix={validationPrefix}
        widgetIndex={widgetIndex}
      />
    </Grid>
  </Flex>
)
