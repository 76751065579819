import { find, get, isNull, isObject } from 'lodash'
import React from 'react'
import ReactSelect, {
  type GroupBase,
  type Props,
  type PropsValue,
  components,
  type ActionMeta,
} from 'react-select'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Hint } from 'components/Input'
import { Flex } from 'components/Layout'
import { disabledHover } from 'components/mixins'
import { Text } from 'components/Typography'
import { useValidationErrorTrackingForGA4Provider } from 'providers/googleAnalytics/utils/ErrorTracking/useValidationErrorTrackingForGA4'
import {
  COLOR_INTENT,
  FONT_SIZE,
  FONT_WEIGHT,
  getTextColor,
  HINT_HEIGHT,
  LINE_HEIGHT,
  SPACE,
} from 'Theme'
import {
  SELECT_DROPDOWN_HEIGHT,
  SELECT_INTENT,
  SELECT_SIZE,
  SELECT_VARIANT,
} from './constants'
import { DropdownArrow } from './DropdownArrow'
import { selectStyles } from './styles'

declare module 'react-select/dist/declarations/src/Select' {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  export interface Props<
    Option,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option>,
  > {
    // These props will be present in the component under `selectProps` object. See styles.ts
    // https://react-select.com/typescript#custom-select-props
    size?: (typeof SELECT_SIZE)[keyof typeof SELECT_SIZE]
    isError?: boolean
    isWarning?: boolean
    variant?: (typeof SELECT_VARIANT)[keyof typeof SELECT_VARIANT]
    intent?: (typeof SELECT_INTENT)[keyof typeof SELECT_INTENT]
    inputFontWeight?: (typeof FONT_WEIGHT)[keyof typeof FONT_WEIGHT]
    height?: string
  }
}

const Wrapper = styled(Flex)`
  font-size: ${FONT_SIZE.PX_14};
  ${({ disabled }) =>
    disabled &&
    `
    ${disabledHover}
  `}
`

export type SelectOptionType<ValueType = unknown> = {
  value: ValueType
  label: string
}

export interface SelectProps<Option = SelectOptionType>
  extends Omit<Props<Option, false>, 'value' | 'onChange'> {
  label?: string
  hint?: string
  hasHint?: boolean
  isInline?: boolean
  customNoOptionsText?: string
  name?: string
  'data-test-id'?: string
  // Payload can be anything
  tooltip?: (option: React.ReactNode, payload: any) => React.ReactElement
  value?: string | PropsValue<Option>
  onChange?: (
    data: string | PropsValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => void
  autocomplete?: boolean
}

export const Select = <
  Option extends
    | SelectOptionType
    | {
        [key: string]: unknown
      }
    | string = SelectOptionType,
>({
  isDisabled,
  placeholder = 'Select option...',
  options = [],
  onChange,
  value,
  size = SELECT_SIZE.M,
  variant = SELECT_VARIANT.DEFAULT,
  intent = SELECT_INTENT.NONE,
  inputFontWeight = FONT_WEIGHT.NORMAL,
  label,
  hint,
  hasHint = false,
  isInline = false,
  isError = false,
  isWarning = false,
  isSearchable = false,
  onInputChange,
  customNoOptionsText,
  isLoading,
  height = SELECT_DROPDOWN_HEIGHT,
  isMulti,
  hideSelectedOptions = true,
  name,
  'data-test-id': dataTestId,
  tooltip = (children) => <>{children}</>,
  ...rest
}: SelectProps<Option>) => {
  useValidationErrorTrackingForGA4Provider({
    hint,
    isError,
  })

  return (
    <Wrapper
      display="inline-flex"
      disabled={isDisabled}
      width="100%"
      flexDirection={isInline ? 'row' : 'column'}
      data-test-id={dataTestId ?? name}
      data-test-class={DATA_TEST_CLASS.SELECT}
    >
      {label && (
        <Text
          name={name} // used for scrolling to the label in case of error
          color={COLOR_INTENT.GRAY_90}
          marginBottom={SPACE.PX_5}
          lineHeight="1.15"
          height={isInline && size ? size : ''}
          alignItems={isInline && size ? 'center' : ''}
          paddingRight={isInline && size ? SPACE.PX_30 : ''}
        >
          {label}
        </Text>
      )}
      <Flex
        height={
          hasHint ? `calc(${size} + ${SPACE.PX_5} + ${HINT_HEIGHT})` : 'initial'
        }
        flexDirection="column"
        flexGrow={1}
        display="inline-text"
      >
        <ReactSelect<Option, false>
          isSearchable={isSearchable}
          value={
            isObject(value) || isNull(value)
              ? value
              : (find(options, { value }) as PropsValue<Option>)
          }
          onInputChange={onInputChange}
          options={options}
          onChange={onChange}
          size={size}
          inputFontWeight={inputFontWeight}
          variant={variant}
          intent={intent}
          styles={selectStyles as any}
          components={{
            Option: (props) =>
              tooltip(
                <components.Option {...props}>
                  {props.children}
                </components.Option>,
                get(props, ['data', 'payload'])
              ),
            DropdownIndicator: ({ hasValue }) =>
              DropdownArrow({
                isDisabled,
                color:
                  intent === SELECT_INTENT.PRIMARY && hasValue
                    ? COLOR_INTENT.SELECT.CARET
                    : COLOR_INTENT.GRAY_70,
              }),
            ...(customNoOptionsText
              ? {
                  NoOptionsMessage: () => (
                    <Flex p={SPACE.PX_10} width="100%" justifyContent="center">
                      <Text
                        color={COLOR_INTENT.GRAY_50}
                        data-test-class={DATA_TEST_CLASS.SELECT_NO_OPTIONS}
                      >
                        {customNoOptionsText}
                      </Text>
                    </Flex>
                  ),
                }
              : {}),
          }}
          isLoading={isLoading}
          height={height}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isError={isError}
          isWarning={isWarning}
          name={name}
          hideSelectedOptions={hideSelectedOptions}
          {...rest}
          isMulti={false}
        />
        <Hint
          lineHeight={LINE_HEIGHT.L}
          color={getTextColor({ isWarning, isError })}
          data-test-class={DATA_TEST_CLASS.SELECT_HINT}
        >
          {hint}
        </Hint>
      </Flex>
    </Wrapper>
  )
}
