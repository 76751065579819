import * as Yup from 'yup'
import { getExpertPickerValidationSchema } from '@admin/components/ExpertPicker'

export const bannerSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    title: Yup.string().required().trim().max(200).label('Title'),
    buttonText: Yup.string().trim().max(200).required().label('Button text'),
    href: Yup.string().trim().url().nullable().label('Button link'),
    expert: getExpertPickerValidationSchema(),
    atLeastOneRequired: Yup.string().when(['href', 'expert'], {
      is: (href, expert) => !href && !expert?.value?.id,
      then: Yup.string().required('Href or expert is required'),
    }),
  }),
})
