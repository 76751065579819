import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Input } from 'components/Input'
import { Flex } from 'components/Layout'

export const ArticleHeading = ({ validationPrefix }) => (
  <Flex flexDirection="column">
    <Field name={`${validationPrefix}.caption`}>
      {({ field, meta }) => (
        <Input
          label="Caption"
          isError={meta.error !== undefined}
          hint={meta.error ?? ''}
          hasHint
          {...field}
        />
      )}
    </Field>
    <Field name={`${validationPrefix}.title`}>
      {({ field, meta }) => (
        <Input
          label="Title"
          isError={meta.error !== undefined}
          hint={meta.error ?? ''}
          hasHint
          {...field}
        />
      )}
    </Field>
    <Field name={`${validationPrefix}.author`}>
      {({ field, meta }) => (
        <Input
          label="Author"
          isError={meta.error !== undefined}
          hint={meta.error ?? ''}
          hasHint
          {...field}
        />
      )}
    </Field>
  </Flex>
)

ArticleHeading.propTypes = {
  validationPrefix: PropTypes.string.isRequired,
}
