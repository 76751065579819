import { Field } from 'formik'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { WidgetWrapper } from '@admin/routes/Cms/components/WidgetWrapper'
import { ClickableReadonlyInput, Input } from 'components/Input'
import { Box, Flex } from 'components/Layout'
import { SPACE } from 'Theme'
import {
  deleteItemFromFormList,
  moveItemDownInFormList,
  moveItemUpInFormList,
} from '../../utils'

const CollectionPickerModal = dynamic(
  () =>
    import('@admin/components/CollectionPicker').then(
      (module) => module.CollectionPickerModal
    ),
  {
    ssr: false,
  }
)

export const MultiCollectionItem = ({
  cmsMultiCollectionWidgetItems,
  cmsMultiCollectionWidgetItem,
  multiCollectionItemsFieldName,
  index,
  isDisabled,
}) => {
  const [isCollectionPickerModalOpen, setIsCollectionPickerModalOpen] =
    useState(false)
  const collectionActionParams = {
    index,
    array: cmsMultiCollectionWidgetItems,
    formLocation: multiCollectionItemsFieldName,
  }
  const multicollectionItemName = `${multiCollectionItemsFieldName}[${index}]`
  return (
    <Field>
      {({ form: { setFieldValue } }) => (
        <WidgetWrapper
          widgetName={`${index + 1}. Collection`}
          isMoveDownDisabled={
            index === cmsMultiCollectionWidgetItems.length - 1
          }
          isMoveUpDisabled={index === 0}
          isDeleteDisabled={isDisabled}
          onDeleteWidget={() => {
            deleteItemFromFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
          onMoveDownWidget={() => {
            moveItemDownInFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
          onMoveUpWidget={() => {
            moveItemUpInFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
        >
          <Flex flexDirection="column" width="100%">
            <Field name={`${multicollectionItemName}.collection`}>
              {({ field: { name }, meta }) => {
                const { collection } = cmsMultiCollectionWidgetItem
                return (
                  <Box mb={SPACE.PX_15}>
                    <CollectionPickerModal
                      selectedCollection={collection}
                      selectedCollectionId={collection?.id}
                      onSelect={(selectedCollection) => {
                        setFieldValue(name, selectedCollection)
                        setIsCollectionPickerModalOpen(false)
                      }}
                      isModalOpen={isCollectionPickerModalOpen}
                      onModalClose={() => {
                        setIsCollectionPickerModalOpen(false)
                      }}
                    />
                    <ClickableReadonlyInput
                      onClick={() => {
                        setIsCollectionPickerModalOpen(true)
                      }}
                      label="Collection"
                      placeholder="Click to select a Collection..."
                      value={collection?.title ?? ''}
                      isDisabled={isDisabled}
                      isError={meta.error !== undefined}
                      hint={meta.error?.id || ''}
                    />
                  </Box>
                )
              }}
            </Field>
            <Field name={`${multicollectionItemName}.caption`}>
              {({ field, meta }) => (
                <Input
                  label="Display name"
                  isDisabled={isDisabled}
                  isError={meta.error !== undefined}
                  hint={meta.error ?? ''}
                  hasHint
                  {...field}
                />
              )}
            </Field>
          </Flex>
        </WidgetWrapper>
      )}
    </Field>
  )
}

MultiCollectionItem.propTypes = {
  cmsMultiCollectionWidgetItems: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      collection: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  cmsMultiCollectionWidgetItem: PropTypes.shape({
    caption: PropTypes.string,
    collection: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string.isRequired,
    }).isRequired,
  }),
  multiCollectionItemsFieldName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
}
