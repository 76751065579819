import * as Yup from 'yup'
import { cmsCollectionsCarouselWidgetItemValidationSchema } from './CollectionsCarouselItem'

export const collectionsCarouselSchemaValidation = Yup.object().shape({
  content: Yup.object({
    title: Yup.string().trim().max(200).required().label('Title'),
    subtitle: Yup.string().trim().max(200).nullable().label('Subtitle'),
    hasExploreAllButton: Yup.boolean(),
    cmsCollectionsCarouselWidgetItems:
      cmsCollectionsCarouselWidgetItemValidationSchema.required(),
    cmsShowroomPage: Yup.object()
      .shape({
        id: Yup.number().label('Link'),
      })
      .when('hasExploreAllButton', {
        is: true,
        then: Yup.object().shape({
          id: Yup.number().required().label('Link'),
        }),
      }),
  }),
})
