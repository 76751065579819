import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'components/Button'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { COLOR, SPACE } from 'Theme'
import { UploadAttachment } from '../UploadAttachment'
import { useChangeVideoThumbnail } from './hooks'

export const VideoThumbnailField = ({ validationPrefix, widgetIndex }) => {
  const [executeChangeVideoThumbnail] = useChangeVideoThumbnail()
  return (
    <Flex width="100%" flexShrink={0}>
      <Field name={`${validationPrefix}.thumbnailAttachment`}>
        {({ field, meta, form }) => {
          let content = (
            <UploadAttachment
              aspectRatio={ASPECT_RATIO.VIDEO_THUMBNAIL}
              onSelect={async (thumbnailAttachment) => {
                form.setFieldValue(
                  `${validationPrefix}.thumbnailAttachment`,
                  thumbnailAttachment
                )
                const videoId =
                  form.values.widgets[widgetIndex]?.content?.videoAttachment?.id
                if (videoId && thumbnailAttachment) {
                  await executeChangeVideoThumbnail({
                    variables: {
                      input: {
                        videoId,
                        thumbnailAttachmentId: thumbnailAttachment.id,
                      },
                    },
                  })
                }
              }}
            />
          )
          if (field?.value?.url) {
            content = (
              <Flex flexDirection="column">
                <Image
                  layout={IMAGE_LAYOUT.RESPONSIVE}
                  aspectRatio={ASPECT_RATIO.VIDEO_THUMBNAIL}
                  alt="Video thumbnail"
                  src={field.value.url}
                />
                <Button
                  mt={SPACE.PX_15}
                  onClick={() => {
                    form.setFieldValue(
                      `${validationPrefix}.thumbnailAttachment`,
                      {}
                    )
                  }}
                >
                  Change image
                </Button>
              </Flex>
            )
          }
          return (
            <Flex flexDirection="column" width="100%">
              <Text mb={meta.error === undefined ? SPACE.PX_20 : 0}>
                Thumbnail
              </Text>
              {meta.error && (
                <Text color={COLOR.SECONDARY_1}>{meta.error.id}</Text>
              )}
              {content}
            </Flex>
          )
        }}
      </Field>
    </Flex>
  )
}

VideoThumbnailField.propTypes = {
  validationPrefix: PropTypes.string.isRequired,
  widgetIndex: PropTypes.number.isRequired,
}
