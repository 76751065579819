import * as Yup from 'yup'

export const videoSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    title: Yup.string().required().label('Title'),
    thumbnailAttachment: Yup.object().shape({
      id: Yup.number().required().label('Thumbnail'),
    }),
    videoAttachment: Yup.object().shape({
      id: Yup.number().required().label('Video'),
    }),
  }),
})
