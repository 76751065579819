import { Field } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import { TextEditor } from 'components/TextEditor'
import { TEXT_EDITOR_FORMAT } from 'constants/common'

const CONTENT_HEIGHT = rem(400)

export const RichText = ({ validationPrefix }) => (
  <Field name={`${validationPrefix}.richText`}>
    {({ field, form: { setFieldValue } }) => (
      <TextEditor
        contentHeight={CONTENT_HEIGHT}
        format={TEXT_EDITOR_FORMAT.MARKDOWN}
        {...field}
        onChange={(editorValue) => {
          setFieldValue(field.name, editorValue)
        }}
        areLinkButtonsVisible
        isBlockTypeDropdownVisible
        areHistoryButtonsVisible
      />
    )}
  </Field>
)

RichText.propTypes = {
  validationPrefix: PropTypes.string.isRequired,
}
