import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { CheckboxGroup } from 'components/Checkbox'
import { Input } from 'components/Input'
import { Text } from 'components/Typography'
import { SPACE, FONT_SIZE } from 'Theme'
import { AddWidgetItemButton } from '../../AddWidgetItemButton'
import { addNewItemToList } from '../utils'
import { ExpertsItem, getInitialExpertsItemData } from './ExpertsItem'

export const Experts = ({
  validationPrefix,
  cmsExpertsWidgetItems,
  setFieldValue,
}) => {
  const expertsField = `${validationPrefix}.cmsExpertsWidgetItems`
  return (
    <>
      <Field name={`${validationPrefix}.title`}>
        {({ field, meta }) => (
          <Input
            label="Title"
            isError={meta.error !== undefined}
            hint={meta.error ? meta.error : null}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.mobilePageSize`}>
        {({ field, meta }) => (
          <Input
            label="Mobile page size"
            isError={meta.error !== undefined}
            hint={meta.error ? meta.error : null}
            hasHint
            type="number"
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.desktopPageSize`}>
        {({ field, meta }) => (
          <Input
            label="Desktop page size"
            isError={meta.error !== undefined}
            hint={meta.error ? meta.error : null}
            hasHint
            type="number"
            {...field}
          />
        )}
      </Field>
      <Field type="checkbox" name={`${validationPrefix}.isButtonHidden`}>
        {({ field: { value, name }, form }) => (
          <CheckboxGroup>
            <CheckboxGroup.Item
              isChecked={value}
              onChange={({ target }) => {
                form.setFieldValue(name, target.checked)
              }}
            >
              Hide &apos;FIND AN EXPERT&apos; button
            </CheckboxGroup.Item>
          </CheckboxGroup>
        )}
      </Field>
      <Text mt={SPACE.PX_20} fontSize={FONT_SIZE.PX_14}>
        Experts
      </Text>
      <AddWidgetItemButton
        onClick={() => {
          setFieldValue(expertsField, [
            getInitialExpertsItemData(),
            ...(cmsExpertsWidgetItems ?? []),
          ])
        }}
      >
        Add an Expert
      </AddWidgetItemButton>
      {cmsExpertsWidgetItems &&
        cmsExpertsWidgetItems.map((cmsExpertsWidgetItem, index) => (
          <div key={cmsExpertsWidgetItem.clientId}>
            <ExpertsItem
              cmsExpertsWidgetItems={cmsExpertsWidgetItems}
              cmsExpertsWidgetItem={cmsExpertsWidgetItem}
              expertsField={expertsField}
              setFieldValue={setFieldValue}
              index={index}
            />
            <AddWidgetItemButton
              onClick={() => {
                addNewItemToList({
                  setFieldValue,
                  index,
                  array: cmsExpertsWidgetItems,
                  initialData: getInitialExpertsItemData(),
                  fieldLocation: expertsField,
                })
              }}
            >
              Add an Expert
            </AddWidgetItemButton>
          </div>
        ))}
    </>
  )
}

Experts.propTypes = {
  widgetIndex: PropTypes.number,
  setFieldValue: PropTypes.func.isRequired,
  validationPrefix: PropTypes.string.isRequired,
  cmsExpertsWidgetItems: PropTypes.arrayOf(
    PropTypes.shape({
      clientId: PropTypes.string.isRequired,
      expert: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
          id: PropTypes.number,
          profileName: PropTypes.string,
          businessName: PropTypes.string,
        }),
      }),
    })
  ),
}
