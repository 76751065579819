import { Field } from 'formik'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { WidgetWrapper } from '@admin/routes/Cms/components/WidgetWrapper'
import { Button, BUTTON_VARIANT } from 'components/Button'
import { Image } from 'components/Image'
import { Input } from 'components/Input'
import { Flex, Grid } from 'components/Layout'
import { Textarea } from 'components/Textarea'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { SPACE, FONT_SIZE, COLOR } from 'Theme'
import {
  deleteItemFromFormList,
  moveItemDownInFormList,
  moveItemUpInFormList,
} from '../../utils'

const AttachmentPickerModal = dynamic(
  () =>
    import('../../AttachmentPickerModal').then(
      (modules) => modules.AttachmentPickerModal
    ),
  {
    ssr: false,
  }
)

export const ProductsItem = ({
  cmsProductsWidgetItems,
  cmsProductsWidgetItem,
  productsItemsFieldName,
  index,
}) => {
  const [isImagePickerModalOpen, setIsImagePickerModalOpen] = useState(false)
  const galleryItemActionParams = {
    index,
    array: cmsProductsWidgetItems,
    formLocation: productsItemsFieldName,
  }
  const productsItemName = `${productsItemsFieldName}[${index}]`
  return (
    <Field>
      {({ form: { setFieldValue } }) => (
        <WidgetWrapper
          widgetName={`${index + 1}. Products item`}
          isMoveDownDisabled={index === cmsProductsWidgetItems.length - 1}
          isMoveUpDisabled={index === 0}
          onDeleteWidget={() => {
            deleteItemFromFormList({
              setFieldValue,
              ...galleryItemActionParams,
            })
          }}
          onMoveDownWidget={() => {
            moveItemDownInFormList({
              setFieldValue,
              ...galleryItemActionParams,
            })
          }}
          onMoveUpWidget={() => {
            moveItemUpInFormList({
              setFieldValue,
              ...galleryItemActionParams,
            })
          }}
        >
          <Grid gridGap={SPACE.PX_20} gridTemplateColumns="1fr 2fr">
            <Field name={`${productsItemName}.attachment.id`}>
              {({ meta }) => (
                <Flex flexDirection="column">
                  <Image
                    aspectRatio={ASPECT_RATIO.PRODUCTS}
                    alt="Product Image"
                    src={cmsProductsWidgetItem?.attachment?.url}
                  />
                  {meta?.error !== undefined && (
                    <Text
                      fontSize={FONT_SIZE.PX_12}
                      mt={SPACE.PX_5}
                      color={COLOR.SECONDARY_1}
                    >
                      {meta.error}
                    </Text>
                  )}
                </Flex>
              )}
            </Field>
            <Flex flexDirection="column" width="100%">
              <Field name={`${productsItemName}.pretitle`}>
                {({ field, meta }) => (
                  <Input
                    label="Pretitle"
                    isError={meta.error !== undefined}
                    hint={meta.error ?? ''}
                    hasHint
                    {...field}
                  />
                )}
              </Field>
              <Field name={`${productsItemName}.title`}>
                {({ field, meta }) => (
                  <Input
                    label="Title"
                    isError={meta.error !== undefined}
                    hint={meta.error ?? ''}
                    hasHint
                    {...field}
                  />
                )}
              </Field>
              <Field name={`${productsItemName}.posttitle`}>
                {({ field, meta }) => (
                  <Input
                    label="Posttitle"
                    isError={meta.error !== undefined}
                    hint={meta.error ?? ''}
                    hasHint
                    {...field}
                  />
                )}
              </Field>
              <Field name={`${productsItemName}.description`}>
                {({ field, meta }) => (
                  <Textarea
                    label="Description"
                    isError={meta.error !== undefined}
                    hint={meta.error ?? ''}
                    hasHint
                    rows="3"
                    onPaste={(value) => {
                      setFieldValue(field.name, value)
                    }}
                    {...field}
                  />
                )}
              </Field>
              <Field name={`${productsItemName}.href`}>
                {({ field, meta }) => (
                  <Input
                    label="URL"
                    isError={meta.error !== undefined}
                    hint={meta.error ?? ''}
                    hasHint
                    {...field}
                  />
                )}
              </Field>
              <Flex>
                <Button
                  width="initial"
                  variant={BUTTON_VARIANT.OUTLINE}
                  onClick={() => {
                    setIsImagePickerModalOpen(true)
                  }}
                >
                  Change image
                </Button>
              </Flex>
            </Flex>
          </Grid>
          <AttachmentPickerModal
            onSelect={(attachment) => {
              setFieldValue(`${productsItemName}.attachment`, attachment)
              setIsImagePickerModalOpen(false)
            }}
            isModalOpen={isImagePickerModalOpen}
            onModalClose={() => {
              setIsImagePickerModalOpen(false)
            }}
            aspectRatio={ASPECT_RATIO.PRODUCTS}
          />
        </WidgetWrapper>
      )}
    </Field>
  )
}

ProductsItem.propTypes = {
  cmsProductsWidgetItems: PropTypes.arrayOf(PropTypes.shape({})),
  cmsProductsWidgetItem: PropTypes.shape({
    attachment: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  productsItemsFieldName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}
