export const truncateStringWithEllipsisInTheBeginning = (
  input: string,
  maxLength: number = 40
): string => {
  if (input.length <= maxLength) {
    return input
  }

  // 1 is because character ellipsis is 1 character (but 3 dots)
  return `…${input.slice(input.length - (maxLength - 1))}`
}

export const truncateStringWithEllipsisInTheEnd = (
  string: string,
  maxLength: number
) => {
  if (string && string.length > maxLength) {
    return `${string.substring(0, maxLength - 1)}…`
  }
  if (!string) {
    return undefined
  }
  return string
}
