import { Field } from 'formik'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, BUTTON_VARIANT } from 'components/Button'
import { Image, FillImageWrapper, IMAGE_LAYOUT } from 'components/Image'
import { Hint } from 'components/Input'
import { Flex, Grid } from 'components/Layout'
import { TextEditor } from 'components/TextEditor'
import { Toast } from 'components/Toast'
import { ASPECT_RATIO } from 'components/UploadImage'
import { TEXT_EDITOR_FORMAT } from 'constants/common'
import { COLOR, SPACE } from 'Theme'
import { useCreateAttachmentMutation } from 'types/graphql-generated'

const UploadImageModal = dynamic(
  () =>
    import('components/UploadImage').then(
      (modules) => modules.UploadImageModal
    ),
  {
    ssr: false,
  }
)

export const SingleImage = ({ validationPrefix }) => {
  const [isUploadImageModalOpen, setIsUploadImageModalOpen] = useState(false)
  const [executeCreateAttachmentMutation] = useCreateAttachmentMutation()
  return (
    <Grid gridGap={SPACE.PX_20} gridTemplateColumns="1fr 2fr">
      <Flex alignItems="center" alignSelf="flex-start">
        <Field name={`${validationPrefix}.attachment`}>
          {({ field: { name, value }, form: { setFieldValue }, meta }) => {
            const hint = meta.error?.id ?? null
            return (
              <>
                <UploadImageModal
                  ariaLabel="Crop and upload image"
                  title="Crop and upload image"
                  aspectRatio={ASPECT_RATIO.FREE}
                  onConfirm={async ({ blob, crop }) => {
                    if (blob) {
                      try {
                        const {
                          data: { createAttachment },
                        } = await executeCreateAttachmentMutation({
                          variables: {
                            input: {
                              file: blob,
                              crop,
                            },
                          },
                        })
                        setFieldValue(name, createAttachment)
                        setIsUploadImageModalOpen(false)
                      } catch (error) {
                        Toast.error(
                          'Image could not get uploaded. Please try again later.'
                        )
                      }
                    }
                  }}
                  isOpen={isUploadImageModalOpen}
                  onModalClose={() => {
                    setIsUploadImageModalOpen(false)
                  }}
                />
                <Flex flexDirection="column" flex={1}>
                  <FillImageWrapper>
                    <Image
                      layout={IMAGE_LAYOUT.FILL}
                      objectFit="contain"
                      src={value?.url}
                      alt="Single image attachment"
                    />
                  </FillImageWrapper>
                  <Hint color={COLOR.SECONDARY_1}>{hint}</Hint>
                </Flex>
              </>
            )
          }}
        </Field>
      </Flex>
      <Flex flexDirection="column">
        <Field name={`${validationPrefix}.caption`}>
          {({ field, form: { setFieldValue } }) => (
            <TextEditor
              format={TEXT_EDITOR_FORMAT.MARKDOWN}
              {...field}
              onChange={(editorValue) => {
                setFieldValue(field.name, editorValue)
              }}
              areLinkButtonsVisible
              areHistoryButtonsVisible
            />
          )}
        </Field>
        <Button
          variant={BUTTON_VARIANT.OUTLINE}
          onClick={() => {
            setIsUploadImageModalOpen(true)
          }}
          width="fit-content"
          mt={SPACE.PX_15}
        >
          Upload image
        </Button>
      </Flex>
    </Grid>
  )
}

SingleImage.propTypes = {
  validationPrefix: PropTypes.string.isRequired,
}
