import { reduce } from 'lodash'
import { type CmsWidgetItemInput } from 'types/graphql-generated'
import { ALLOWED_WIDGET_ITEM_TYPES } from './constants'

const DEFAULT_WIDGET_ITEM_CONTENT_FORMATTERS = reduce(
  ALLOWED_WIDGET_ITEM_TYPES,
  (acc, widgetItemType) => ({
    ...acc,
    [widgetItemType]: (content: unknown) => ({
      [`cms${widgetItemType.slice(3)}`]: content,
    }),
  }),
  {}
)

const formatTwoColumnCardWidgetItemContent = ({
  cards,
  ...rest
}): Pick<CmsWidgetItemInput, 'cmsWidgetItemTwoColumnCard'> => {
  const [firstCard, secondCard] = cards
  return {
    cmsWidgetItemTwoColumnCard: {
      firstTitle: firstCard.title,
      firstLink: firstCard?.link ?? null,
      firstAttachmentId: parseInt(firstCard.attachment.id),
      secondTitle: secondCard.title,
      secondLink: secondCard?.link ?? null,
      secondAttachmentId: parseInt(secondCard.attachment.id),
      ...rest,
    },
  }
}

const formatThreeColumnCardWidgetItemContent = ({
  cards,
  ...rest
}): Pick<CmsWidgetItemInput, 'cmsWidgetItemThreeColumnCard'> => {
  const [firstCard, secondCard, thirdCard] = cards
  return {
    cmsWidgetItemThreeColumnCard: {
      firstTitle: firstCard.title,
      firstLink: firstCard?.link ?? null,
      firstAttachmentId: parseInt(firstCard.attachment.id),
      secondTitle: secondCard.title,
      secondLink: secondCard?.link ?? null,
      secondAttachmentId: parseInt(secondCard.attachment.id),
      thirdTitle: thirdCard.title,
      thirdLink: thirdCard?.link ?? null,
      thirdAttachmentId: parseInt(thirdCard.attachment.id),
      ...rest,
    },
  }
}

export const WIDGET_ITEMS_CONTENT_FORMATTERS_MAP = {
  ...DEFAULT_WIDGET_ITEM_CONTENT_FORMATTERS,
  [ALLOWED_WIDGET_ITEM_TYPES.TWO_COLUMN_CARD]:
    formatTwoColumnCardWidgetItemContent,
  [ALLOWED_WIDGET_ITEM_TYPES.THREE_COLUMN_CARD]:
    formatThreeColumnCardWidgetItemContent,
}

export const widgetItemsContentFormatter = (widgetItems) =>
  widgetItems.map(
    ({ content: widgetItemContent, id: widgetItemId }, positionIndexWeight) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { __typename, ...restContent } = widgetItemContent
      return {
        id: widgetItemId,
        positionIndexWeight,
        ...WIDGET_ITEMS_CONTENT_FORMATTERS_MAP[__typename](restContent),
      }
    }
  )
