import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Button, BUTTON_VARIANT } from 'components/Button'
import Icon from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { WrappedLoader } from 'components/WrappedLoader'
import { SPACE, BORDER_WIDTH, COLOR, RADIUS } from 'Theme'
import { VIDEO_VALID_TYPES } from './UploadVideoProvider'

const UPLOAD_AREA_MIN_HEIGHT = '200px'

const Wrapper = styled(Flex)`
  border: ${BORDER_WIDTH.PX_1} dashed ${COLOR.GRAYSCALE_5};
  background-color: ${COLOR.GRAYSCALE_6};
  border-radius: ${RADIUS.PX_10};
`

export const UploadVideoArea = ({
  handleDrop,
  isImageLoading,
  uploadedVideoPreview,
  handleFileChange,
  fileInput,
}) => (
  <Flex flex={1}>
    <Wrapper
      width="100%"
      minHeight={UPLOAD_AREA_MIN_HEIGHT}
      mt={SPACE.PX_20}
      justifyContent="center"
      alignItems="center"
      onDrop={handleDrop}
      onDragEnter={(event) => {
        event.preventDefault()
      }}
      onDragLeave={(event) => {
        event.preventDefault()
      }}
      onDragOver={(event) => {
        event.preventDefault()
      }}
    >
      {isImageLoading && <WrappedLoader />}
      {uploadedVideoPreview && !isImageLoading && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video height="100%" width="100%" controls>
          <source src={uploadedVideoPreview} type="video/ogg" />
        </video>
      )}
      {!uploadedVideoPreview && !isImageLoading && (
        <Flex flexDirection="column" alignItems="center" flex={1}>
          <Text color={COLOR.GRAYSCALE_2}>
            Drag and drop video here to upload
          </Text>
          <Box mt={SPACE.PX_15}>
            <Button
              icon={Icon.Upload}
              variant={BUTTON_VARIANT.OUTLINE}
              onClick={() => {
                fileInput.current.click()
              }}
            >
              Upload
            </Button>
            <Box display="none">
              <input
                type="file"
                ref={fileInput}
                accept={VIDEO_VALID_TYPES.join(',')}
                onChange={handleFileChange}
              />
            </Box>
          </Box>
        </Flex>
      )}
    </Wrapper>
  </Flex>
)

UploadVideoArea.propTypes = {
  handleDrop: PropTypes.func.isRequired,
  isImageLoading: PropTypes.bool.isRequired,
  uploadedVideoPreview: PropTypes.string,
  handleFileChange: PropTypes.func.isRequired,
  fileInput: PropTypes.shape({
    current: PropTypes.shape({ click: PropTypes.func }),
  }),
}
