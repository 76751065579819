import { rem } from 'polished'
import { COLOR_INTENT } from 'Theme'

export const SELECT_OPTION_HEIGHT = rem(30)
export const SELECT_DROPDOWN_HEIGHT = rem(200)

export const SELECT_SIZE = {
  S: rem(30),
  M: rem(36),
  L: rem(40),
} as const

export const SELECT_VARIANT = {
  STYLED: 'styled',
  DEFAULT: 'default',
  MINIMAL: 'minimal',
} as const

export const SELECT_INTENT = {
  PRIMARY: COLOR_INTENT.SELECT.PRIMARY_INTENT,
  NONE: COLOR_INTENT.GRAY_90,
} as const
