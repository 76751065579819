import { get, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Select } from 'components/Select'
import { Tooltip } from 'components/Tooltip'
import { useDebouncedInput } from 'hooks'
import { MODAL_SELECT_DROPDOWN_HEIGHT } from 'Theme'
import { useCmsShowroomPagePickerQuery } from 'types/graphql-generated'
import { truncateStringWithEllipsisInTheBeginning } from 'utils/truncateStringWithEllipsis'

const NONE_OPTION = {
  id: null,
  label: '-- none --',
}

const RESULTS_LIMIT = 6

const getOptions = ({ data, selectedShowroomPageId, selectedShowroomPage }) => {
  const showroomPages = get(data, ['cmsShowroomPages', 'data'], [])
  return uniqBy(
    [
      ...(selectedShowroomPage ? [selectedShowroomPage] : []),
      ...(selectedShowroomPageId ? [NONE_OPTION] : []),
      ...showroomPages,
    ],
    'id'
  ).map(({ id: value, label, cmsPage }) => ({
    value,
    label:
      label ?? truncateStringWithEllipsisInTheBeginning(cmsPage?.title ?? ''),
    payload: cmsPage,
  }))
}

export const ShowroomPagePicker = ({
  onSelect,
  selectedShowroomPageId,
  selectedShowroomPage,
  filter = {
    isPublished: true,
    isStatic: false,
  },
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState('')
  const { isBouncing, debouncedValue } = useDebouncedInput(searchValue)
  const { data, loading: isLoading } = useCmsShowroomPagePickerQuery({
    variables: {
      input: {
        filter: {
          ...filter,
          search: debouncedValue,
        },
        pagination: {
          page: 1,
          limit: RESULTS_LIMIT,
        },
      },
    },
  })
  return (
    <Select
      isSearchable
      isLoading={isLoading}
      height={MODAL_SELECT_DROPDOWN_HEIGHT}
      options={
        isBouncing
          ? []
          : getOptions({ data, selectedShowroomPageId, selectedShowroomPage })
      }
      onChange={({ value: id, payload: cmsPage }) => {
        if (id) {
          onSelect({ id, cmsPage })
        } else {
          onSelect()
        }
      }}
      tooltip={(children, payload) => (
        <Tooltip body={payload?.slug ?? '-'}>{children}</Tooltip>
      )}
      value={selectedShowroomPageId}
      onInputChange={setSearchValue}
      {...rest}
    />
  )
}

export const cmsShowroomPagePropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  cmsPage: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
})

export const cmsShowroomPageFilterPropTypes = PropTypes.shape({
  isPublished: PropTypes.bool,
  isStatic: PropTypes.bool,
})

ShowroomPagePicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedShowroomPageId: PropTypes.number,
  selectedShowroomPage: cmsShowroomPagePropTypes,
  filter: cmsShowroomPageFilterPropTypes,
}
