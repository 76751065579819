import * as Yup from 'yup'

export const EMPTY_EXPERT_PICKER_OPTION = {
  label: 'Select an expert',
  value: {
    id: null,
    profileName: null,
    businessName: null,
  },
}

export const getExpertPickerValidationSchema = ({
  isRequired = false,
  label = 'Expert',
}: {
  isRequired?: boolean
  label?: string
} = {}) =>
  isRequired
    ? Yup.object()
        .shape({
          value: Yup.object().shape({
            id: Yup.number().nullable().required().label(label),
          }),
        })
        .label(label)
        .nullable()
        .required()
    : Yup.object()
        .shape({
          value: Yup.object().shape({
            id: Yup.number().nullable().label(label),
          }),
        })
        .label(label)
        .nullable()

export const formatExpertPickerOption = ({
  id,
  profileName,
  businessName,
}: {
  id: number
  profileName?: string
  businessName?: string
}) => ({
  label: profileName || businessName || `Unknown Expert #${id}`,
  value: {
    id,
    profileName,
    businessName,
  },
})
