import { Field } from 'formik'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, BUTTON_VARIANT } from 'components/Button'
import { Image } from 'components/Image'
import { Hint } from 'components/Input'
import { Box, Flex } from 'components/Layout'
import { Toast } from 'components/Toast'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage'
import { COLOR, IMAGE_BASE_WIDTH, LINE_HEIGHT, SPACE } from 'Theme.ts'
import { useCreateAttachmentMutation } from 'types/graphql-generated'

const UploadImageModal = dynamic(
  () =>
    import('components/UploadImage').then(
      (modules) => modules.UploadImageModal
    ),
  {
    ssr: false,
  }
)

export const ImageAttachment = ({
  aspectRatio,
  fieldName,
  label,
  hasPaddingOnTop,
  overrideImageProps,
}) => {
  const [isUploadImageModalOpen, setIsUploadImageModalOpen] = useState(false)
  const [executeCreateAttachmentMutation] = useCreateAttachmentMutation()
  return (
    <Field name={fieldName}>
      {({ field: { name, value }, meta, form: { setFieldValue } }) => {
        const hint = meta.error?.id ?? null
        return (
          <>
            <UploadImageModal
              ariaLabel="Crop and upload image"
              title="Crop and upload image"
              aspectRatio={aspectRatio}
              onConfirm={async ({ blob, crop }) => {
                if (blob) {
                  try {
                    const {
                      data: { createAttachment },
                    } = await executeCreateAttachmentMutation({
                      variables: {
                        input: {
                          crop,
                          file: blob,
                        },
                      },
                    })
                    setFieldValue(name, createAttachment)
                    setIsUploadImageModalOpen(false)
                  } catch (error) {
                    Toast.error(
                      'Image could not get uploaded. Please try again later.'
                    )
                  }
                }
              }}
              isOpen={isUploadImageModalOpen}
              onModalClose={() => {
                setIsUploadImageModalOpen(false)
              }}
            />
            <Flex flex={1} height="100%" flexDirection="column">
              {label && (
                <Text mb={SPACE.PX_5} lineHeight={LINE_HEIGHT.XS}>
                  {label}
                </Text>
              )}
              <Box pt={hasPaddingOnTop ? SPACE.PX_80 : 0}>
                <Image
                  aspectRatio={aspectRatio}
                  baseWidth={IMAGE_BASE_WIDTH.PX_800}
                  alt={label || 'Collection image'}
                  src={value?.url}
                  {...overrideImageProps}
                />
              </Box>
              <Button
                variant={BUTTON_VARIANT.OUTLINE}
                onClick={() => {
                  setIsUploadImageModalOpen(true)
                }}
              >
                {`${value?.id ? 'Change' : 'Upload'} image`}
              </Button>
              <Hint color={COLOR.SECONDARY_1}>{hint}</Hint>
            </Flex>
          </>
        )
      }}
    </Field>
  )
}

ImageAttachment.defaultProps = {
  aspectRatio: ASPECT_RATIO.HERO,
  hasPaddingOnTop: true,
}

ImageAttachment.propTypes = {
  label: PropTypes.string,
  aspectRatio: PropTypes.oneOf(Object.values(ASPECT_RATIO)),
  fieldName: PropTypes.string.isRequired,
  hasPaddingOnTop: PropTypes.bool,
  overrideImageProps: PropTypes.object,
}
