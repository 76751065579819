import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled, { css } from 'styled-components'
import {
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  BUTTON_VARIANT,
} from 'components/Button'
import Icon from 'components/Icon'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { SPACE, RADIUS, FONT_SIZE, COLOR_INTENT } from 'Theme'

const TRANSITION_DURATION = 0.3

const ElementWrapper = styled(Flex)`
  border: 1px solid ${COLOR_INTENT.ADMIN_WIDGET_WRAPPER.BORDER};
  border-radius: ${RADIUS.PX_6};
`

const HeaderWrapper = styled(Flex)`
  ${({ isContentVisible }) =>
    isContentVisible &&
    css`
      border-bottom: 1px solid ${COLOR_INTENT.ADMIN_WIDGET_WRAPPER.BORDER};
    `};
`

interface WidgetWrapperProps {
  widgetName?: string
  onMoveUpWidget: () => void
  onMoveDownWidget: () => void
  onDeleteWidget: () => void
  isMoveDownDisabled: boolean
  isMoveUpDisabled: boolean
  isDeleteDisabled?: boolean
}

export const WidgetWrapper: React.FC<React.PWC<WidgetWrapperProps>> = ({
  children,
  widgetName,
  onMoveUpWidget,
  onMoveDownWidget,
  onDeleteWidget,
  isMoveDownDisabled,
  isMoveUpDisabled,
  isDeleteDisabled = false,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  return (
    <ElementWrapper flexDirection="column" my={SPACE.PX_20}>
      <HeaderWrapper
        justifyContent="space-between"
        p={SPACE.PX_5}
        isContentVisible={!isCollapsed}
      >
        <Flex alignItems="center">
          <Button
            icon={isCollapsed ? Icon.Plus : Icon.Minus}
            variant={BUTTON_VARIANT.MINIMAL}
            intent={BUTTON_INTENT.NONE}
            size={BUTTON_SIZE.S}
            ml={SPACE.PX_10}
            onClick={() => {
              setIsCollapsed(!isCollapsed)
            }}
          />
          <Text pl={SPACE.PX_10} fontSize={FONT_SIZE.PX_16}>
            {widgetName}
          </Text>
        </Flex>
        <Flex justifyContent="flex-end">
          <Button
            isDisabled={isMoveUpDisabled}
            variant={BUTTON_VARIANT.MINIMAL}
            icon={Icon.ChevronUpSmall}
            size={BUTTON_SIZE.S}
            onClick={onMoveUpWidget}
            data-test-id={DATA_TEST_ID.WIDGET_MOVE_UP_BUTTON}
          />
          <Button
            isDisabled={isMoveDownDisabled}
            variant={BUTTON_VARIANT.MINIMAL}
            icon={Icon.ChevronDownSmall}
            size={BUTTON_SIZE.S}
            onClick={onMoveDownWidget}
            data-test-id={DATA_TEST_ID.WIDGET_MOVE_DOWN_BUTTON}
          />
          {!isDeleteDisabled && (
            <Button
              icon={Icon.Cross}
              variant={BUTTON_VARIANT.MINIMAL}
              size={BUTTON_SIZE.S}
              onClick={onDeleteWidget}
              data-test-id={DATA_TEST_ID.WIDGET_DELETE_BUTTON}
            />
          )}
        </Flex>
      </HeaderWrapper>
      <AnimatePresence initial={false}>
        {!isCollapsed && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            style={{ overflow: 'hidden' }}
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: TRANSITION_DURATION }}
          >
            <Flex flexDirection="column" p={SPACE.PX_15}>
              {children}
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
    </ElementWrapper>
  )
}
