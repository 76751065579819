import * as Yup from 'yup'

export const FIELD_NAMES = {
  HEADLINE: 'headline',
  SUBHEADLINE: 'subheadline',
}

export const headlineWidgetItemValidationSchema = Yup.object().shape({
  content: Yup.object().shape({
    [FIELD_NAMES.HEADLINE]: Yup.string().max(120).label('Headline').required(),
    [FIELD_NAMES.SUBHEADLINE]: Yup.string()
      .max(180)
      .label('Subheadline')
      .nullable(),
  }),
})
