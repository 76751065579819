import { v4 as uuidv4 } from 'uuid'
import {
  type CmsWidgetItem,
  type CmsWidgetItemThreeColumnCard,
  type CmsWidgetItemTwoColumnCard,
} from 'types/graphql-generated'
import { ALLOWED_WIDGET_ITEM_TYPES } from './constants'

const formatTwoColumnCardWidgetItemFromDb = (
  content: CmsWidgetItemTwoColumnCard
) => {
  const {
    firstTitle,
    firstLink,
    firstAttachment,
    secondTitle,
    secondLink,
    secondAttachment,
    ...rest
  } = content

  return {
    cards: [
      {
        title: firstTitle,
        link: firstLink,
        attachment: firstAttachment,
      },
      {
        title: secondTitle,
        link: secondLink,
        attachment: secondAttachment,
      },
    ],
    ...rest,
  }
}

const formatThreeColumnCardWidgetItemFromDb = (
  content: CmsWidgetItemThreeColumnCard
) => {
  const {
    firstTitle,
    firstLink,
    firstAttachment,
    secondTitle,
    secondLink,
    secondAttachment,
    thirdTitle,
    thirdLink,
    thirdAttachment,
    ...rest
  } = content

  return {
    cards: [
      {
        title: firstTitle,
        link: firstLink,
        attachment: firstAttachment,
      },
      {
        title: secondTitle,
        link: secondLink,
        attachment: secondAttachment,
      },
      {
        title: thirdTitle,
        link: thirdLink,
        attachment: thirdAttachment,
      },
    ],
    ...rest,
  }
}

export const WIDGET_ITEMS_DB_FORMATTERS_MAP = {
  [ALLOWED_WIDGET_ITEM_TYPES.TWO_COLUMN_CARD]:
    formatTwoColumnCardWidgetItemFromDb,
  [ALLOWED_WIDGET_ITEM_TYPES.THREE_COLUMN_CARD]:
    formatThreeColumnCardWidgetItemFromDb,
}

export const widgetItemsDbFormatter = (widgetItems: CmsWidgetItem[]) =>
  widgetItems.map((widgetItem) => {
    const { content, ...rest } = widgetItem
    return {
      clientId: uuidv4(),
      content:
        WIDGET_ITEMS_DB_FORMATTERS_MAP[content.__typename]?.(content) ??
        content,
      ...rest,
    }
  })
