import * as Yup from 'yup'

export const singleImageSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    caption: Yup.lazy((value) =>
      typeof value === 'string' ? Yup.string() : Yup.object()
    ),
    attachment: Yup.object().shape({
      id: Yup.number().required().label('Image'),
    }),
  }),
})
