import * as Yup from 'yup'
import { WIDGET_PLACEMENT } from 'constants/common'

export const cardNavigationSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    title: Yup.string().trim().max(200).nullable().label('Title'),
    subtitle: Yup.string().trim().max(200).nullable().label('Subtitle'),
    placement: Yup.string()
      .oneOf(Object.values(WIDGET_PLACEMENT))
      .required('Select placement')
      .label('Placement')
      .default(WIDGET_PLACEMENT.HERO),
    hasButton: Yup.boolean().required().default(true),
    buttonText: Yup.string()
      .max(30)
      .label('Button text')
      .when('hasButton', {
        is: true,
        then: (text) => text.required(),
        otherwise: Yup.string().nullable(),
      }),
    buttonLink: Yup.string()
      .label('Button link')
      .when('hasButton', {
        is: true,
        then: (link) => link.required(),
        otherwise: Yup.string().nullable(),
      }),
    cmsCardNavigationWidgetItems: Yup.array()
      .of(
        Yup.object({
          title: Yup.string().trim().required().label('Title'),
          attachment: Yup.object().shape({
            id: Yup.number().required().label('Image'),
          }),
          cmsPage: Yup.object().shape({
            id: Yup.number().required().label('Page'),
          }),
        })
      )
      .label('Navigation item')
      .min(1)
      .required(),
  }),
})
