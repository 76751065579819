import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'components/Button'
import { Image } from 'components/Image'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { COLOR, SPACE } from 'Theme'
import { UploadAttachment } from '../UploadAttachment'

export const SpotlightImagePicker = ({ setFieldValue, validationPrefix }) => (
  <Field name={`${validationPrefix}.attachment`}>
    {({ field, meta }) => {
      let content = (
        <UploadAttachment
          aspectRatio={ASPECT_RATIO.SPOTLIGHT}
          onSelect={(attachment) => {
            setFieldValue(`${validationPrefix}.attachment`, attachment)
          }}
        />
      )
      if (field?.value?.url) {
        content = (
          <Flex flexDirection="column">
            <Image
              aspectRatio={ASPECT_RATIO.SPOTLIGHT}
              alt="Spotlight image attachment"
              src={field.value.url}
            />
            <Button
              mt={SPACE.PX_15}
              onClick={() => {
                setFieldValue(`${validationPrefix}.attachment`, {})
              }}
            >
              Change image
            </Button>
          </Flex>
        )
      }
      return (
        <Flex flexDirection="column" width="100%">
          <Text mb={meta.error === undefined ? SPACE.PX_20 : 0}>Image</Text>
          {meta.error && <Text color={COLOR.SECONDARY_1}>{meta.error.id}</Text>}
          {content}
        </Flex>
      )
    }}
  </Field>
)

SpotlightImagePicker.propTypes = {
  validationPrefix: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}
