import { transparentize } from 'polished'
// import { type GroupBase, type StylesConfig } from 'react-select'
import {
  BORDER_WIDTH,
  BOX_SHADOW,
  COLOR_INTENT,
  FONT_SIZE,
  FONT_WEIGHT,
  getBorderColor,
  GRADIENT,
  LINE_HEIGHT,
  RADIUS,
  SPACE,
  Z_INDEX,
} from 'Theme'
import { SELECT_OPTION_HEIGHT, SELECT_VARIANT } from './constants'

const getInputBackgroundColor = ({
  isDisabled,
  isSearchable,
}: {
  isDisabled?: boolean
  isSearchable?: boolean
}) => {
  if (isDisabled) {
    return COLOR_INTENT.GRAY_3
  }

  if (isSearchable) {
    return COLOR_INTENT.SELECT.SEARCHABLE_BACKGROUND
  }
  return `linear-gradient(${GRADIENT.GRADIENT_1}), ${COLOR_INTENT.SELECT.BACKGROUND}`
}

const getInputCursor = ({
  isDisabled,
  isSearchable,
}: {
  isDisabled?: boolean
  isSearchable?: boolean
}) => {
  if (isDisabled) {
    return 'not-allowed'
  }
  if (isSearchable) {
    return 'text'
  }
  return 'pointer'
}

// * This is a workaround for the issue with the types of react-select
// * There is a potential fix but it would break types in other packages -> https://github.com/JedWatson/react-select/issues/5825#issuecomment-1850472549
export const selectStyles /* : StylesConfig<
  unknown,
  boolean,
  GroupBase<unknown>
> */ = {
  control: (
    styles,
    {
      isDisabled,
      isFocused,
      selectProps: { size, isWarning, isError, variant, intent, isSearchable },
    }
  ) => ({
    ...styles,
    minHeight: size,
    borderRadius: RADIUS.PX_6,
    boxShadow: 'none',
    fontSize: FONT_SIZE.PX_14,
    border: isDisabled
      ? `${BORDER_WIDTH.PX_1} solid ${COLOR_INTENT.GRAY_3}`
      : `${BORDER_WIDTH.PX_1} solid ${getBorderColor({
          isFocusedOrChecked: isFocused,
          isWarning,
          isError,
        })}`,
    background: getInputBackgroundColor({ isDisabled, isSearchable }),
    cursor: getInputCursor({ isDisabled, isSearchable }),
    padding: `0 ${SPACE.PX_10} 0 ${SPACE.PX_5}`,
    ...(variant === SELECT_VARIANT.STYLED && {
      background: COLOR_INTENT.SELECT.BACKGROUND,
      border: `${BORDER_WIDTH.PX_2} solid ${transparentize(0.9, intent)}`,
      '&:hover': {
        border: `${BORDER_WIDTH.PX_2} solid ${transparentize(0.8, intent)}`,
      },
      ...(isFocused && {
        border: `${BORDER_WIDTH.PX_2} solid ${intent}`,
        '&:hover': {
          border: `${BORDER_WIDTH.PX_2} solid ${intent}`,
        },
      }),
    }),
    ...(variant === SELECT_VARIANT.MINIMAL && {
      background: COLOR_INTENT.SELECT.BACKGROUND,
      border: `${BORDER_WIDTH.PX_1} solid ${transparentize(0.9, intent)}`,
      '&:hover': {
        border: `${BORDER_WIDTH.PX_1} solid ${transparentize(0.8, intent)}`,
      },
      ...(isFocused && {
        border: `${BORDER_WIDTH.PX_1} solid ${transparentize(0.8, intent)}`,
      }),
      ...((isError || isWarning) && {
        border: `${BORDER_WIDTH.PX_1} solid ${getBorderColor({
          isFocusedOrChecked: false,
          isWarning,
          isError,
        })}`,
        '&:hover': {
          border: `${BORDER_WIDTH.PX_1} solid ${getBorderColor({
            isFocusedOrChecked: false,
            isWarning,
            isError,
          })}`,
        },
      }),
    }),
    ...(variant === SELECT_VARIANT.DEFAULT && {
      '&:hover': {
        background: !isSearchable
          ? `linear-gradient(${GRADIENT.GRADIENT_1_HOVER}), ${COLOR_INTENT.SELECT.BACKGROUND}`
          : COLOR_INTENT.SELECT.SEARCHABLE_BACKGROUND,
      },
    }),
  }),
  container: (styles) => ({
    ...styles,
    fontSize: FONT_SIZE.PX_14,
  }),
  valueContainer: (styles, { selectProps: { inputFontWeight } }) => ({
    ...styles,
    fontSize: FONT_SIZE.PX_14,
    fontWeight: inputFontWeight,
    flexWrap: 'nowrap',
  }),
  placeholder: (styles, { isDisabled, selectProps: { intent } }) => ({
    ...styles,
    fontSize: FONT_SIZE.PX_14,
    color: intent,
    opacity: isDisabled ? 0.4 : 1,
    margin: 0,
    lineHeight: LINE_HEIGHT.L,
  }),
  menu: (styles, { selectProps }) => ({
    ...styles,
    border: `${BORDER_WIDTH.PX_1} solid ${COLOR_INTENT.GRAY_10}`,
    borderRadius: RADIUS.PX_6,
    color: COLOR_INTENT.GRAY_90,
    boxShadow: BOX_SHADOW.ELEVATION_5,
    background: `linear-gradient(${GRADIENT.GRADIENT_1}), ${COLOR_INTENT.SELECT.MENU_BACKGROUND}`,
    padding: SPACE.PX_5,
    ...(selectProps.height && {
      maxHeight: `calc(${selectProps.height} + 10px)`,
    }),
    zIndex: Z_INDEX.SELECT,
  }),
  menuList: (_, { selectProps }) => ({
    ...(selectProps.height && {
      maxHeight: selectProps.height,
    }),
    overflow: 'auto',
    padding: '0',
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: Z_INDEX.OVERLAY,
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    display: 'flex',
    alignItems: 'center',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    height: SELECT_OPTION_HEIGHT,
    ...(isSelected && {
      color: COLOR_INTENT.GRAY_90,
    }),
    ...(isDisabled && {
      color: COLOR_INTENT.SELECT.DISABLED_OPTION_LABEL,
    }),
    background: isFocused
      ? COLOR_INTENT.SELECT.MULTI_VALUE_OPTION
      : `transparent`,
    borderRadius: RADIUS.PX_3,
    fontSize: FONT_SIZE.PX_14,
    fontWeight: FONT_WEIGHT.NORMAL,
    padding: `0 ${SPACE.PX_10}`,
    marginBottom: SPACE.PX_5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:last-child': {
      marginBottom: 0,
    },
    lineHeight: LINE_HEIGHT.L,
    '&:hover': {
      background: COLOR_INTENT.SELECT.OPTION_BACKGROUND_HOVER,
      color: COLOR_INTENT.SELECT.OPTION_LABEL_HOVER,
    },
    ':active': {
      ...styles[':active'],
      backgroundColor:
        !isDisabled && COLOR_INTENT.SELECT.OPTION_BACKGROUND_ACTIVE,
    },
  }),
  singleValue: (styles, { isDisabled, selectProps: { intent } }) => ({
    ...styles,
    width: '100%',
    fontSize: FONT_SIZE.PX_14,
    color: isDisabled ? COLOR_INTENT.GRAY_70 : intent,
  }),
  indicatorSeparator: () => null,
  multiValue: (styles) => ({
    ...styles,
    border: `${BORDER_WIDTH.PX_1} solid ${COLOR_INTENT.GRAY_10}`,
    borderRadius: RADIUS.PX_6,
    backgroundColor: 'none',
  }),
  multiValueLabel: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled
      ? COLOR_INTENT.GRAY_70
      : COLOR_INTENT.SELECT.MULTI_VALUE_LABEL,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: COLOR_INTENT.GRAY_20,
    ':hover': {
      color: COLOR_INTENT.GRAY_50,
    },
  }),
}
