import { Field } from 'formik'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  cmsShowroomPagePropTypes,
  ShowroomPagePickerModal,
} from '@admin/components/ShowroomPagePicker'
import { ImageAttachment } from '@admin/routes/Cms/components/ImageAttachment'
import { WidgetWrapper } from '@admin/routes/Cms/components/WidgetWrapper'
import { ClickableReadonlyInput, Input } from 'components/Input'
import { Box, Flex } from 'components/Layout'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { SPACE } from 'Theme'
import {
  deleteItemFromFormList,
  moveItemDownInFormList,
  moveItemUpInFormList,
} from '../../utils'

export const CollectionsCarouselItem = ({
  cmsCollectionsCarouselWidgetItems,
  collectionsCarouselItemsFieldName,
  index,
}) => {
  const [isShowroomPagePickerModalOpen, setIsShowroomPagePickerModalOpen] =
    useState(false)
  const collectionActionParams = {
    index,
    array: cmsCollectionsCarouselWidgetItems,
    formLocation: collectionsCarouselItemsFieldName,
  }
  const collectionsCarouselItemPrefix = `${collectionsCarouselItemsFieldName}[${index}]`
  return (
    <Field>
      {({ form: { setFieldValue } }) => (
        <WidgetWrapper
          widgetName={`${index + 1}. Collection`}
          isMoveDownDisabled={
            index === cmsCollectionsCarouselWidgetItems.length - 1
          }
          isMoveUpDisabled={index === 0}
          onDeleteWidget={() => {
            deleteItemFromFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
          onMoveDownWidget={() => {
            moveItemDownInFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
          onMoveUpWidget={() => {
            moveItemUpInFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
        >
          <Flex flexDirection="column" width="100%">
            <Flex flexDirection="row" mb={SPACE.PX_30}>
              <Flex alignSelf="start">
                <ImageAttachment
                  label="Preview image"
                  aspectRatio={ASPECT_RATIO.PRODUCTS}
                  fieldName={`${collectionsCarouselItemPrefix}.previewImageAttachment`}
                  hasPaddingOnTop={false}
                />
              </Flex>
              <Flex alignSelf="start" ml={SPACE.PX_15}>
                <ImageAttachment
                  label="Profile image"
                  aspectRatio={ASPECT_RATIO.PROFILE}
                  fieldName={`${collectionsCarouselItemPrefix}.profileImageAttachment`}
                  hasPaddingOnTop={false}
                />
              </Flex>
            </Flex>
            <Field name={`${collectionsCarouselItemPrefix}.cmsShowroomPage`}>
              {({ field: { name }, meta }) => {
                const { cmsShowroomPage } =
                  cmsCollectionsCarouselWidgetItems[index]
                return (
                  <Box mb={SPACE.PX_15}>
                    <ShowroomPagePickerModal
                      selectedShowroomPage={cmsShowroomPage}
                      selectedShowroomPageId={get(cmsShowroomPage, 'id')}
                      onSelect={(selectedShowroomPage) => {
                        setFieldValue(name, selectedShowroomPage)
                        setIsShowroomPagePickerModalOpen(false)
                      }}
                      isModalOpen={isShowroomPagePickerModalOpen}
                      onModalClose={() => {
                        setIsShowroomPagePickerModalOpen(false)
                      }}
                    />
                    <ClickableReadonlyInput
                      onClick={() => {
                        setIsShowroomPagePickerModalOpen(true)
                      }}
                      label="Showroom Page"
                      placeholder="Click to select a Showroom Page..."
                      value={get(cmsShowroomPage, ['cmsPage', 'title'], '')}
                      hint={get(meta, ['error', 'id'], '')}
                      isError={meta.error !== undefined}
                    />
                  </Box>
                )
              }}
            </Field>
            <Field name={`${collectionsCarouselItemPrefix}.caption`}>
              {({ field, meta }) => (
                <Input
                  label="Caption"
                  isError={meta.error !== undefined}
                  hint={meta.error ?? ''}
                  hasHint
                  {...field}
                />
              )}
            </Field>
          </Flex>
        </WidgetWrapper>
      )}
    </Field>
  )
}

export const cmsCollectionsCarouselWidgetItemPropTypes = PropTypes.shape({
  caption: PropTypes.string,
  cmsShowroomPage: cmsShowroomPagePropTypes.isRequired,
  previewImageAttachment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  profileImageAttachment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
})

CollectionsCarouselItem.propTypes = {
  cmsCollectionsCarouselWidgetItems: PropTypes.arrayOf(
    cmsCollectionsCarouselWidgetItemPropTypes
  ),
  collectionsCarouselItemsFieldName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}
