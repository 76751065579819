import * as Yup from 'yup'
import { cardNavigationSchemaValidation } from '@admin/routes/Cms/components/Widgets/CardNavigation'
import { alphabeticalIndexSchemaValidation } from './AlphabeticalIndex'
import { articleHeadingSchemaValidation } from './ArticleHeading'
import { articlesSchemaValidation } from './Articles'
import { bannerSchemaValidation } from './Banner'
import { categoryHeaderSchemaValidation } from './CategoryHeader'
import { collectionHeaderSchemaValidation } from './CollectionHeader'
import { collectionsCarouselSchemaValidation } from './CollectionsCarousel'
import { ALLOWED_WIDGET_TYPES } from './constants'
import { doubleImageSchemaValidation } from './DoubleImage'
import { exportCollectionsSchemaValidation } from './ExpertCollections'
import { expertsSchemaValidation } from './Experts'
import { expertsListingSchemaValidation } from './ExpertsListing'
import { gallerySchemaValidation } from './Gallery'
import { giftCardSchemaValidation } from './GiftCard'
import { heroSchemaValidation } from './Hero'
import { heroCarouselSchemaValidation } from './HeroCarousel'
import { heroMainSchemaValidation } from './HeroMain'
import { introductionSchemaValidation } from './Introduction'
import { multiCollectionSchemaValidation } from './MultiCollection'
import { productsSchemaValidation } from './Products'
import { qaSchemaValidation } from './QA'
import { richTextValidationSchema } from './RichText'
import { sectionSchemaValidation } from './Section'
import { singleImageSchemaValidation } from './SingleImage'
import { spotlightValidationSchema } from './Spotlight'
import { testimonialsValidationSchema } from './Testimonials'
import { videoSchemaValidation } from './Video'
import { videoSectionSchemaValidation } from './VideoSection'

const WIDGET_SCHEMA_VALIDATION_MAP = {
  [ALLOWED_WIDGET_TYPES.GALLERY]: gallerySchemaValidation,
  [ALLOWED_WIDGET_TYPES.HERO_LEGACY]: heroSchemaValidation,
  [ALLOWED_WIDGET_TYPES.HERO_MAIN]: heroMainSchemaValidation,
  [ALLOWED_WIDGET_TYPES.TESTIMONIALS]: testimonialsValidationSchema,
  [ALLOWED_WIDGET_TYPES.VIDEO]: videoSchemaValidation,
  [ALLOWED_WIDGET_TYPES.VIDEO_SECTION]: videoSectionSchemaValidation,
  [ALLOWED_WIDGET_TYPES.EXPERTS]: expertsSchemaValidation,
  [ALLOWED_WIDGET_TYPES.ARTICLES]: articlesSchemaValidation,
  [ALLOWED_WIDGET_TYPES.SPOTLIGHT]: spotlightValidationSchema,
  [ALLOWED_WIDGET_TYPES.RICH_TEXT]: richTextValidationSchema,
  [ALLOWED_WIDGET_TYPES.ARTICLE_HEADING]: articleHeadingSchemaValidation,
  [ALLOWED_WIDGET_TYPES.CATEGORY_HEADER]: categoryHeaderSchemaValidation,
  [ALLOWED_WIDGET_TYPES.COLLECTION_HEADER]: collectionHeaderSchemaValidation,
  [ALLOWED_WIDGET_TYPES.BANNER]: bannerSchemaValidation,
  [ALLOWED_WIDGET_TYPES.SINGLE_IMAGE]: singleImageSchemaValidation,
  [ALLOWED_WIDGET_TYPES.PRODUCTS]: productsSchemaValidation,
  [ALLOWED_WIDGET_TYPES.DOUBLE_IMAGE]: doubleImageSchemaValidation,
  [ALLOWED_WIDGET_TYPES.QA]: qaSchemaValidation,
  [ALLOWED_WIDGET_TYPES.GIFT_CARD]: giftCardSchemaValidation,
  [ALLOWED_WIDGET_TYPES.MULTI_COLLECTION]: multiCollectionSchemaValidation,
  [ALLOWED_WIDGET_TYPES.EXPERT_COLLECTIONS]: exportCollectionsSchemaValidation,
  [ALLOWED_WIDGET_TYPES.COLLECTIONS_CAROUSEL]:
    collectionsCarouselSchemaValidation,
  [ALLOWED_WIDGET_TYPES.INTRODUCTION]: introductionSchemaValidation,
  [ALLOWED_WIDGET_TYPES.ALPHABETICAL_INDEX]: alphabeticalIndexSchemaValidation,
  [ALLOWED_WIDGET_TYPES.EXPERTS_LISTING]: expertsListingSchemaValidation,
  [ALLOWED_WIDGET_TYPES.HERO_CAROUSEL]: heroCarouselSchemaValidation,
  [ALLOWED_WIDGET_TYPES.CARD_NAVIGATION]: cardNavigationSchemaValidation,
  [ALLOWED_WIDGET_TYPES.SECTION]: sectionSchemaValidation,
}

export const widgetsValidationSchema = Yup.array().of(
  Yup.lazy((item) => {
    const {
      content: { __typename: typename },
    } = item
    return WIDGET_SCHEMA_VALIDATION_MAP[typename] ?? Yup.object()
  })
)
