import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Input } from 'components/Input'
import { Text } from 'components/Typography'
import { SPACE, FONT_SIZE } from 'Theme'
import { AddWidgetItemButton } from '../../AddWidgetItemButton'
import { addNewItemToList } from '../utils'
import { TestimonialsItem } from './TestimonialsItem'
import { getInitialTestimonialsItemData } from './utils'

export const Testimonials = ({
  validationPrefix,
  cmsTestimonialsWidgetItems,
  setFieldValue,
}) => {
  const testimonialsField = `${validationPrefix}.cmsTestimonialsWidgetItems`
  return (
    <>
      <Field name={`${validationPrefix}.title`}>
        {({ field, meta }) => (
          <Input
            label="Title"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Text mt={SPACE.PX_20} fontSize={FONT_SIZE.PX_16}>
        Testimonials:
      </Text>
      <AddWidgetItemButton
        onClick={() => {
          setFieldValue(testimonialsField, [
            getInitialTestimonialsItemData(),
            ...cmsTestimonialsWidgetItems,
          ])
        }}
      >
        Add testimonial
      </AddWidgetItemButton>
      {cmsTestimonialsWidgetItems.map((cmsTestimonialsWidgetItem, index) => (
        <div key={index}>
          <TestimonialsItem
            cmsTestimonialsWidgetItems={cmsTestimonialsWidgetItems}
            cmsTestimonialsyWidgetItem={cmsTestimonialsWidgetItem}
            testimonialsField={testimonialsField}
            setFieldValue={setFieldValue}
            index={index}
          />
          <AddWidgetItemButton
            onClick={() => {
              addNewItemToList({
                setFieldValue,
                index,
                array: cmsTestimonialsWidgetItems,
                initialData: getInitialTestimonialsItemData(),
                fieldLocation: testimonialsField,
              })
            }}
          >
            Add testimonial
          </AddWidgetItemButton>
        </div>
      ))}
    </>
  )
}

Testimonials.defaultProps = {
  cmsTestimonialsWidgetItems: [],
}

Testimonials.propTypes = {
  widgetIndex: PropTypes.number,
  setFieldValue: PropTypes.func.isRequired,
  validationPrefix: PropTypes.string.isRequired,
  cmsTestimonialsWidgetItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
}
