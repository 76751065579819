import * as Yup from 'yup'

export const FIELD_NAMES = {
  TITLE: 'title',
  LINK: 'link',
  ATTACHMENT: 'attachment',
}

export const getInitialCardWidgetItemComponentValues = () => ({
  [FIELD_NAMES.TITLE]: '',
  [FIELD_NAMES.LINK]: '',
  [FIELD_NAMES.ATTACHMENT]: {},
})

export const cardWidgetItemComponentValidationSchema = Yup.object().shape({
  [FIELD_NAMES.TITLE]: Yup.string().max(60).label('Title').required(),
  [FIELD_NAMES.LINK]: Yup.string().max(255).label('Link').nullable(),
  [FIELD_NAMES.ATTACHMENT]: Yup.object().shape({
    id: Yup.number().required().label('Image'),
  }),
})
