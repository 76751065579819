import * as Yup from 'yup'

export const doubleImageSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    firstCaption: Yup.lazy((value) =>
      typeof value === 'string' ? Yup.string() : Yup.object()
    ),
    secondCaption: Yup.lazy((value) =>
      typeof value === 'string' ? Yup.string() : Yup.object()
    ),
    firstAttachment: Yup.object().shape({
      id: Yup.number().required().label('Image 1'),
    }),
    secondAttachment: Yup.object().shape({
      id: Yup.number().required().label('Image 2'),
    }),
  }),
})
