import { Field } from 'formik'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Flex, Box, Grid } from 'components/Layout'
import { TextEditor } from 'components/TextEditor'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { TEXT_EDITOR_FORMAT } from 'constants/common'
import { SPACE, FONT_SIZE, ARTICLE_MAX_WIDTH } from 'Theme'
import { useCreateAttachmentMutation } from 'types/graphql-generated'
import { UploadField } from './UploadField'

const DOUBLE_IMAGE_ATTACHMENT_MAP = {
  first: 'First',
  second: 'Second',
}

export const DoubleImage = ({ validationPrefix }) => {
  const [uploadModalOpenImageId, setUploadModalOpenImageId] = useState(null)
  const [executeCreateAttachmentMutation] = useCreateAttachmentMutation()
  return (
    <Grid
      maxWidth={ARTICLE_MAX_WIDTH.IMAGE}
      gridGap={SPACE.PX_20}
      gridTemplateColumns={{ MOBILE: '1fr', TABLET: '1fr 1fr' }}
    >
      {Object.entries(DOUBLE_IMAGE_ATTACHMENT_MAP).map(([fieldName, label]) => (
        <Flex flexDirection="column" key={label}>
          <Box mb={SPACE.PX_20}>
            <UploadField
              aspectRatio={ASPECT_RATIO.DOUBLE_IMAGE}
              fieldName={`${fieldName}Attachment`}
              validationPrefix={validationPrefix}
              executeCreateAttachmentMutation={executeCreateAttachmentMutation}
              setUploadModalOpenImageId={setUploadModalOpenImageId}
              uploadModalOpenImageId={uploadModalOpenImageId}
              label={`${label} image`}
            />
          </Box>
          <Field name={`${validationPrefix}.${fieldName}Caption`}>
            {({ field, form: { setFieldValue } }) => (
              <TextEditor
                label={`${label} image description`}
                format={TEXT_EDITOR_FORMAT.MARKDOWN}
                {...field}
                onChange={(editorValue) => {
                  setFieldValue(field.name, editorValue)
                }}
                areLinkButtonsVisible
                areHistoryButtonsVisible
                isBlockTypeDropdownVisible
              />
            )}
          </Field>
        </Flex>
      ))}
      <Text fontSize={FONT_SIZE.PX_12}>
        When only one caption is defined, it will be displayed under both
        images.
      </Text>
    </Grid>
  )
}

DoubleImage.propTypes = {
  validationPrefix: PropTypes.string.isRequired,
}
