import { Field } from 'formik'
import { get } from 'lodash'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, BUTTON_VARIANT } from 'components/Button'
import { CheckboxGroup } from 'components/Checkbox'
import { Image } from 'components/Image'
import { Hint, Input } from 'components/Input'
import { Flex, Grid } from 'components/Layout'
import { Toast } from 'components/Toast'
import { ASPECT_RATIO } from 'components/UploadImage'
import { COLOR, SPACE, IMAGE_BASE_WIDTH } from 'Theme'
import { useCreateAttachmentMutation } from 'types/graphql-generated'

const UploadImageModal = dynamic(
  () =>
    import('components/UploadImage').then(
      (modules) => modules.UploadImageModal
    ),
  {
    ssr: false,
  }
)

export const Hero = ({ validationPrefix }) => {
  const [isUploadImageModalOpen, setIsUploadImageModalOpen] = useState(false)
  const [executeCreateAttachmentMutation] = useCreateAttachmentMutation()
  return (
    <Grid gridGap={SPACE.PX_20} gridTemplateColumns="1fr 2fr">
      <Flex alignItems="center">
        <Field name={`${validationPrefix}.attachment`}>
          {({ field: { name, value }, meta, form: { setFieldValue } }) => {
            const hint = meta.error?.id ?? null
            return (
              <>
                <UploadImageModal
                  ariaLabel="Crop and upload image"
                  title="Crop and upload image"
                  aspectRatio={ASPECT_RATIO.HERO}
                  onConfirm={async ({ blob, crop }) => {
                    if (blob) {
                      try {
                        const {
                          data: { createAttachment },
                        } = await executeCreateAttachmentMutation({
                          variables: {
                            input: {
                              crop,
                              file: blob,
                            },
                          },
                        })
                        setFieldValue(name, createAttachment)
                        setIsUploadImageModalOpen(false)
                      } catch (error) {
                        Toast.error(
                          'Image could not get uploaded. Please try again later.'
                        )
                      }
                    }
                  }}
                  isOpen={isUploadImageModalOpen}
                  onModalClose={() => {
                    setIsUploadImageModalOpen(false)
                  }}
                />
                <Flex flexDirection="column" flex={1}>
                  <Image
                    aspectRatio={ASPECT_RATIO.HERO}
                    baseWidth={IMAGE_BASE_WIDTH.PX_800}
                    alt="Hero"
                    src={value?.url}
                  />
                  <Hint color={COLOR.SECONDARY_1}>{hint}</Hint>
                </Flex>
              </>
            )
          }}
        </Field>
      </Flex>
      <Flex flexDirection="column">
        <CheckboxGroup>
          <Field
            type="checkbox"
            name={`${validationPrefix}.hasLandingPageButtons`}
          >
            {({ field: { checked, ...restField } }) => (
              <CheckboxGroup.Item isChecked={checked} {...restField}>
                Title &amp; Landing Page Buttons
              </CheckboxGroup.Item>
            )}
          </Field>
        </CheckboxGroup>
        <Field name={`${validationPrefix}.title`}>
          {({ field, meta, form: { values } }) => (
            <Input
              disabled={
                !get(values, `${validationPrefix}.hasLandingPageButtons`, false)
              }
              isError={meta.error !== undefined}
              hint={meta.error ?? ''}
              hasHint
              aria-label="Title input"
              {...field}
            />
          )}
        </Field>
        <Button
          variant={BUTTON_VARIANT.OUTLINE}
          onClick={() => {
            setIsUploadImageModalOpen(true)
          }}
          width="fit-content"
        >
          Upload image
        </Button>
      </Flex>
    </Grid>
  )
}

Hero.propTypes = {
  validationPrefix: PropTypes.string.isRequired,
}
