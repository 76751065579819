import { times } from 'lodash'
import * as Yup from 'yup'
import {
  cardWidgetItemComponentValidationSchema,
  getInitialCardWidgetItemComponentValues,
} from '../components/Card'

const CARD_COUNT = 3

export const FIELD_NAMES = {
  CARDS: 'cards',
}

export const getInitialThreeColumnCardWidgetItemValues = () => ({
  [FIELD_NAMES.CARDS]: times(
    CARD_COUNT,
    getInitialCardWidgetItemComponentValues
  ),
})

export const threeColumnCardWidgetItemValidationSchema = Yup.object().shape({
  content: Yup.object().shape({
    [FIELD_NAMES.CARDS]: Yup.array()
      .of(cardWidgetItemComponentValidationSchema)
      .min(CARD_COUNT)
      .max(CARD_COUNT)
      .required(),
  }),
})
