import * as Yup from 'yup'

const testimonialsItemValidationSchema = Yup.object().shape({
  feedback: Yup.string().required().label('Feedback'),
  clientInfo: Yup.string().required().label('Client info'),
})

export const testimonialsValidationSchema = Yup.object().shape({
  content: Yup.object().shape({
    title: Yup.string().required().label('Title'),
    cmsTestimonialsWidgetItems: Yup.array().of(
      testimonialsItemValidationSchema
    ),
  }),
})

export const getInitialTestimonialsItemData = () => ({
  feedback: '',
  clientInfo: '',
})
