import * as Yup from 'yup'
import { widgetItemsValidationSchema } from '../WidgetItems/widgetItemsValidationSchema'

export const sectionSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    widgetItems: widgetItemsValidationSchema
      .min(1, 'At least one widget item is required')
      .required('At least one widget item is required'),
  }),
})
