import * as Yup from 'yup'

export const heroMainSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    headline: Yup.string().max(80).label('Headline').required(),
    subheadline: Yup.string().max(180).label('Subheadline').nullable(),
    mainButtonText: Yup.string().max(30).label('Main button text').required(),
    mainButtonLink: Yup.string().label('Main button link').required(),
    hasSecondaryButton: Yup.boolean().required(),
    secondaryButtonText: Yup.string()
      .max(30)
      .label('Secondary button text')
      .when('hasSecondaryButton', {
        is: true,
        then: (secondaryButtonText) => secondaryButtonText.required(),
      }),
    secondaryButtonLink: Yup.string()
      .label('Secondary button link')
      .when('hasSecondaryButton', {
        is: true,
        then: (secondaryButtonLink) => secondaryButtonLink.required(),
      }),
    attachment: Yup.object().shape({
      id: Yup.number().required().label('Image'),
    }),
  }),
})
