import * as Yup from 'yup'

export const heroCarouselSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    isAutoRotationEnabled: Yup.boolean().required().default(false),
    cmsHeroCarouselWidgetItems: Yup.array()
      .of(
        Yup.object().shape({
          preTitle: Yup.string().max(100).label('Pre-title').nullable(),
          headline: Yup.string().max(120).label('Headline').nullable(),
          subheadline: Yup.string().max(180).label('Subheadline').nullable(),
          hasButton: Yup.boolean().required().default(false),
          buttonText: Yup.string()
            .max(30)
            .label('Button text')
            .when('hasButton', {
              is: true,
              then: (text) => text.required(),
              otherwise: Yup.string().nullable(),
            }),
          buttonLink: Yup.string()
            .label('Button link')
            .when('hasButton', {
              is: true,
              then: (link) => link.required(),
              otherwise: Yup.string().nullable(),
            }),
          attachment: Yup.object().shape({
            id: Yup.number().required().label('Image'),
          }),
        })
      )
      .required('At least one slide is required. Please add a slide.'),
  }),
})
