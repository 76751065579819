import * as Yup from 'yup'

export const cmsMultiCollectionWidgetItemValidationSchema = Yup.array()
  .of(
    Yup.object({
      collection: Yup.object().shape({
        id: Yup.number().required().label('Collection'),
      }),
      caption: Yup.string().nullable(),
    })
  )
  .required('At least one collection is required')
  .min(1)
