import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { SPACE, FONT_SIZE, COLOR } from 'Theme'
import { AddWidgetItemButton } from '../../AddWidgetItemButton'
import { addNewItemToList } from '../utils'
import { ProductsItem, getInitialProductsItemData } from './ProductsItem'

export const Products = ({
  validationPrefix,
  cmsProductsWidgetItems,
  widgetIndex,
}) => {
  const productsItemsFieldName = `${validationPrefix}.cmsProductsWidgetItems`
  return (
    <>
      <Field name={productsItemsFieldName}>
        {({ meta }) => (
          <Flex flexDirection="column">
            <Text mt={SPACE.PX_20} fontSize={FONT_SIZE.PX_16}>
              Products items:
            </Text>
            {meta?.error !== undefined && typeof meta?.error === 'string' && (
              <Text
                fontSize={FONT_SIZE.PX_12}
                mt={SPACE.PX_5}
                color={COLOR.SECONDARY_1}
              >
                {meta.error}
              </Text>
            )}
          </Flex>
        )}
      </Field>
      <Field>
        {({ form: { setFieldValue } }) => (
          <AddWidgetItemButton
            onClick={() => {
              setFieldValue(productsItemsFieldName, [
                getInitialProductsItemData(),
                ...(cmsProductsWidgetItems ?? []),
              ])
            }}
          >
            Add products item
          </AddWidgetItemButton>
        )}
      </Field>
      {cmsProductsWidgetItems &&
        cmsProductsWidgetItems.map((cmsProductsWidgetItem, index) => (
          <div key={cmsProductsWidgetItem.clientId}>
            <ProductsItem
              widgetIndex={widgetIndex}
              cmsProductsWidgetItems={cmsProductsWidgetItems}
              cmsProductsWidgetItem={cmsProductsWidgetItem}
              productsItemsFieldName={productsItemsFieldName}
              index={index}
            />
            <Field>
              {({ form: { setFieldValue } }) => (
                <AddWidgetItemButton
                  onClick={() => {
                    addNewItemToList({
                      setFieldValue,
                      index,
                      array: cmsProductsWidgetItems,
                      fieldLocation: productsItemsFieldName,
                      initialData: getInitialProductsItemData(),
                    })
                  }}
                >
                  Add products item
                </AddWidgetItemButton>
              )}
            </Field>
          </div>
        ))}
    </>
  )
}

Products.propTypes = {
  widgetIndex: PropTypes.number,
  validationPrefix: PropTypes.string.isRequired,
  cmsProductsWidgetItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
}
