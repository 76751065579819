import { Field } from 'formik'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { EXPERT_USER_CAPABILITIES } from 'shared-constants'
import { ClickableReadonlyInput, Input } from 'components/Input'
import { Flex, Grid } from 'components/Layout'
import { SPACE } from 'Theme'
import { getNestedStringProperty } from 'utils/formatters'
import { getHrefOrExpertError } from '../utils'

const ExpertPickerModal = dynamic(
  () =>
    import('@admin/components/ExpertPicker').then(
      (module) => module.ExpertPickerModal
    ),
  {
    ssr: false,
  }
)

export const Banner = ({ validationPrefix, widgetIndex }) => {
  const [isExpertPickerModalOpen, setIsExpertPickerModalOpen] = useState(false)

  return (
    <Flex flexDirection="column">
      <Field name={`${validationPrefix}.title`}>
        {({ field, meta }) => (
          <Input
            label="Title"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.buttonText`}>
        {({ field, meta }) => (
          <Input
            label="Button text"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Grid gridTemplateColumns="1fr 1fr" gridGap={SPACE.PX_20}>
        <Field name={`${validationPrefix}.href`}>
          {({ field, meta, form }) => {
            const error = getHrefOrExpertError({ form, widgetIndex })
            return (
              <Input
                label="Button link"
                isError={meta.error !== undefined || error}
                hint={(meta.error || error) ?? ''}
                hasHint
                {...field}
              />
            )
          }}
        </Field>
        <Field name={`${validationPrefix}.expert`}>
          {({ field, meta, form }) => {
            const error = getHrefOrExpertError({ form, widgetIndex })
            return (
              <>
                <ExpertPickerModal
                  name={field.name}
                  value={field.value}
                  onChange={(expert) => {
                    form.setFieldValue(field.name, expert)
                    form.setFieldTouched(field.name, true)
                    setIsExpertPickerModalOpen(false)
                  }}
                  isModalOpen={isExpertPickerModalOpen}
                  onModalClose={() => {
                    setIsExpertPickerModalOpen(false)
                  }}
                  capabilitiesIncluded={[EXPERT_USER_CAPABILITIES.CONSULTATION]}
                />
                <ClickableReadonlyInput
                  onClick={() => {
                    setIsExpertPickerModalOpen(true)
                  }}
                  label="Expert"
                  value={field.value?.label}
                  placeholder="Click to select an expert..."
                  isError={meta.error !== undefined || error}
                  hint={
                    error || meta.error
                      ? error || getNestedStringProperty(meta.error)
                      : null
                  }
                  hasHint
                />
              </>
            )
          }}
        </Field>
      </Grid>
    </Flex>
  )
}

Banner.propTypes = {
  validationPrefix: PropTypes.string.isRequired,
  widgetIndex: PropTypes.number.isRequired,
}
