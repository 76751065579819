import { Field } from 'formik'
import React from 'react'
import { Button } from 'components/Button'
import { Image } from 'components/Image'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { COLOR, SPACE } from 'Theme'
import { UploadAttachment } from '../Cms/components/Widgets/UploadAttachment'

export interface AttachmentPickerFieldProps {
  aspectRatio?: (typeof ASPECT_RATIO)[keyof typeof ASPECT_RATIO]
  name: string
  label?: string
}

export const AttachmentPickerField: React.FC<
  React.PWC<AttachmentPickerFieldProps>
> = ({ aspectRatio = ASPECT_RATIO.FREE, name, label }) => (
  <Field name={name}>
    {({ field: { value }, form: { setFieldValue }, meta }) => (
      <Flex flexDirection="column" width="100%">
        {label && (
          <Text mb={meta.error === undefined ? SPACE.PX_20 : 0}>{label}</Text>
        )}
        {value?.url ? (
          <Flex flexDirection="column">
            <Image
              aspectRatio={aspectRatio}
              alt="Spotlight image attachment"
              src={value.url}
            />
            <Button
              mt={SPACE.PX_15}
              onClick={() => {
                setFieldValue(name, {})
              }}
            >
              Change image
            </Button>
          </Flex>
        ) : (
          <UploadAttachment
            aspectRatio={aspectRatio}
            onSelect={(attachment) => {
              setFieldValue(name, attachment, true)
            }}
          />
        )}
        {meta.error && <Text color={COLOR.SECONDARY_1}>{meta.error.id}</Text>}
      </Flex>
    )}
  </Field>
)
