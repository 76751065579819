import { useMutation, gql } from '@apollo/client'

const CHANGE_VIDEO_THUMNAIL_MUTATION = gql`
  mutation changeVideoThumbnail($input: ChangeVideoThumbnailInput!) {
    changeVideoThumbnail(input: $input)
  }
`

export const useChangeVideoThumbnail = (options = {}) =>
  useMutation(CHANGE_VIDEO_THUMNAIL_MUTATION, options)
