import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { WidgetWrapper } from '@admin/routes/Cms/components/WidgetWrapper'
import { Input } from 'components/Input'
import { Flex } from 'components/Layout'
import { Textarea } from 'components/Textarea'
import {
  deleteItemFromFormList,
  moveItemDownInFormList,
  moveItemUpInFormList,
} from '../utils'

const MAX_REVIEW_TEXT_LENGTH = 500

export const TestimonialsItem = ({
  cmsTestimonialsWidgetItems,
  testimonialsField,
  setFieldValue,
  index,
}) => {
  const testimonialItemActionParams = {
    setFieldValue,
    index,
    array: cmsTestimonialsWidgetItems,
    formLocation: testimonialsField,
  }
  return (
    <WidgetWrapper
      widgetName={`${index + 1}. Testimonial`}
      isMoveDownDisabled={index === cmsTestimonialsWidgetItems.length - 1}
      isMoveUpDisabled={index === 0}
      onDeleteWidget={() => {
        deleteItemFromFormList(testimonialItemActionParams)
      }}
      onMoveDownWidget={() => {
        moveItemDownInFormList(testimonialItemActionParams)
      }}
      onMoveUpWidget={() => {
        moveItemUpInFormList(testimonialItemActionParams)
      }}
    >
      <Flex flexDirection="column" width="100%">
        <Field name={`${testimonialsField}[${index}].clientInfo`}>
          {({ field, meta }) => (
            <Input
              label="Client info"
              isError={meta.touched && meta.error !== undefined}
              hint={meta.touched ? meta.error : ''}
              hasHint
              {...field}
            />
          )}
        </Field>
        <Field name={`${testimonialsField}[${index}].feedback`}>
          {({ field, meta }) => (
            <Textarea
              label="Feedback"
              {...field}
              rows="5"
              onPaste={(value) => {
                setFieldValue(field.name, value)
              }}
              maxLength={MAX_REVIEW_TEXT_LENGTH}
              isError={meta.touched && meta.error !== undefined}
              hint={meta.touched ? meta.error : ''}
              hasHint
            />
          )}
        </Field>
      </Flex>
    </WidgetWrapper>
  )
}

TestimonialsItem.propTypes = {
  widgetIndex: PropTypes.number,
  cmsTestimonialsWidgetItems: PropTypes.arrayOf(PropTypes.shape({})),
  testimonialsField: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  index: PropTypes.number,
}
