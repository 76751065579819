import { Field } from 'formik'
import dynamic from 'next/dynamic'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { EXPERT_USER_CAPABILITIES } from 'shared-constants'
import { WidgetWrapper } from '@admin/routes/Cms/components/WidgetWrapper'
import { Button, BUTTON_VARIANT } from 'components/Button'
import { Image } from 'components/Image'
import { ClickableReadonlyInput } from 'components/Input'
import { Flex, Grid, Box } from 'components/Layout'
import { TextEditor } from 'components/TextEditor'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { TEXT_EDITOR_FORMAT } from 'constants/common'
import { SPACE, FONT_SIZE, COLOR } from 'Theme'
import { getNestedStringProperty } from 'utils/formatters'
import {
  deleteItemFromFormList,
  moveItemDownInFormList,
  moveItemUpInFormList,
} from '../../utils'

const AttachmentPickerModal = dynamic(
  () =>
    import('../../AttachmentPickerModal').then(
      (modules) => modules.AttachmentPickerModal
    ),
  {
    ssr: false,
  }
)

const ExpertPickerModal = dynamic(
  () =>
    import('@admin/components/ExpertPicker').then(
      (modules) => modules.ExpertPickerModal
    ),
  {
    ssr: false,
  }
)

export const GalleryItem = ({
  cmsGalleryWidgetItems,
  cmsGalleryWidgetItem,
  setFieldValue,
  galleryImageField,
  index,
}) => {
  const [isExpertPickerModalOpen, setIsExpertPickerModalOpen] = useState(false)
  const [isImagePickerModalOpen, setIsImagePickerModalOpen] = useState(false)
  const galleryItemActionParams = {
    setFieldValue,
    index,
    array: cmsGalleryWidgetItems,
    formLocation: galleryImageField,
  }
  const formFieldLocation = `${galleryImageField}[${index}]`
  return (
    <WidgetWrapper
      widgetName={`${index + 1}. Gallery item`}
      isMoveDownDisabled={index === cmsGalleryWidgetItems.length - 1}
      isMoveUpDisabled={index === 0}
      onDeleteWidget={() => {
        deleteItemFromFormList(galleryItemActionParams)
      }}
      onMoveDownWidget={() => {
        moveItemDownInFormList(galleryItemActionParams)
      }}
      onMoveUpWidget={() => {
        moveItemUpInFormList(galleryItemActionParams)
      }}
    >
      <Grid gridGap={SPACE.PX_20} gridTemplateColumns="1fr 2fr">
        <Field name={`${formFieldLocation}.attachment.id`}>
          {({ meta }) => (
            <Flex flexDirection="column">
              <Image
                aspectRatio={ASPECT_RATIO.GALLERY}
                alt={cmsGalleryWidgetItem?.attachment?.alt || 'Gallery image'}
                src={cmsGalleryWidgetItem?.attachment?.url}
              />
              {meta?.error !== undefined && (
                <Text
                  fontSize={FONT_SIZE.PX_12}
                  mt={SPACE.PX_5}
                  color={COLOR.SECONDARY_1}
                >
                  {meta.error}
                </Text>
              )}
            </Flex>
          )}
        </Field>
        <Flex flexDirection="column" mb={SPACE.PX_20}>
          <Field name={`${formFieldLocation}.expert`}>
            {({ field, meta, form }) => (
              <>
                <ExpertPickerModal
                  name={field.name}
                  value={field.value}
                  onChange={(expert) => {
                    setFieldValue(field.name, expert)
                    form.setFieldTouched(field.name, true)
                    setIsExpertPickerModalOpen(false)
                  }}
                  isModalOpen={isExpertPickerModalOpen}
                  onModalClose={() => {
                    setIsExpertPickerModalOpen(false)
                  }}
                  capabilitiesIncluded={[EXPERT_USER_CAPABILITIES.CONSULTATION]}
                />
                <ClickableReadonlyInput
                  onClick={() => {
                    setIsExpertPickerModalOpen(true)
                  }}
                  label="Expert"
                  value={field.value?.label}
                  placeholder="Click to select an expert..."
                  isError={meta.error !== undefined}
                  hint={meta.error ? getNestedStringProperty(meta.error) : null}
                  hasHint
                />
              </>
            )}
          </Field>
          <Box my={SPACE.PX_20}>
            <Field name={`${formFieldLocation}.caption`}>
              {({ field: { value: fieldValue, name }, meta }) => (
                <TextEditor
                  format={TEXT_EDITOR_FORMAT.MARKDOWN}
                  label="Caption"
                  contentHeight={rem(100)}
                  onChange={(caption) => {
                    setFieldValue(name, caption)
                  }}
                  value={fieldValue}
                  hasHint
                  isError={meta.touched && meta.error !== undefined}
                  hint={meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Box>
          <Box>
            <Button
              mr={SPACE.PX_10}
              width="initial"
              variant={BUTTON_VARIANT.OUTLINE}
              onClick={() => {
                setIsExpertPickerModalOpen(true)
              }}
            >
              Change expert
            </Button>
            <Button
              width="initial"
              variant={BUTTON_VARIANT.OUTLINE}
              onClick={() => {
                setIsImagePickerModalOpen(true)
              }}
            >
              Change image
            </Button>
          </Box>
        </Flex>
      </Grid>
      <AttachmentPickerModal
        selectedExpertId={cmsGalleryWidgetItem.expert?.id}
        onSelect={(attachment) => {
          setFieldValue(`${formFieldLocation}.attachment`, attachment)
          setIsImagePickerModalOpen(false)
        }}
        isModalOpen={isImagePickerModalOpen}
        onModalClose={() => {
          setIsImagePickerModalOpen(false)
        }}
      />
    </WidgetWrapper>
  )
}

GalleryItem.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  widgetIndex: PropTypes.number,
  cmsGalleryWidgetItems: PropTypes.arrayOf(PropTypes.shape({})),
  cmsGalleryWidgetItem: PropTypes.shape({
    expert: PropTypes.shape({
      profileName: PropTypes.string,
      id: PropTypes.number,
    }),
    attachment: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      alt: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  setFieldValue: PropTypes.func.isRequired,
  galleryImageField: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}
