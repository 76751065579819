import { get } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { WIDGET_CONTENT_FORMATTERS_MAP } from './contentFormatters'
import { WIDGET_DB_FORMATTERS_MAP } from './dbFormatters'

export const getWidgetsValidationPrefix = (index) => `widgets[${index}].content`
export const getWidgetItemsValidationPrefix = (widgetItemsFieldName, index) =>
  `${widgetItemsFieldName}[${index}].content`

export const formatWidgetContentToMutation = ({
  __typename: typename,
  ...content
}) => WIDGET_CONTENT_FORMATTERS_MAP[typename]?.(content) ?? {}

export const initialWidgetData = (__typename, initialWidgetValues = {}) => ({
  clientId: uuidv4(),
  content: { __typename, ...initialWidgetValues },
})

export const formatWidgetsFromDb = (widgets = []) =>
  widgets.map((widget) => {
    const shared = {
      clientId: uuidv4(),
      ...widget,
    }
    const { content } = widget
    const { __typename: typename } = content
    return WIDGET_DB_FORMATTERS_MAP[typename]?.({ content, shared }) ?? shared
  })

export const formatWidgetToMutation = ({
  clientId,
  content,
  __typename,
  positionIndexWeight,
  ...widget
}) => ({
  ...widget,
  ...formatWidgetContentToMutation(content),
})

export const deleteItemFromFormList = ({
  setFieldValue,
  array,
  index,
  formLocation,
}) => {
  setFieldValue(formLocation, [
    ...array.slice(0, index),
    ...array.slice(index + 1),
  ])
}
export const moveItemDownInFormList = ({
  setFieldValue,
  array,
  index,
  formLocation,
}) => {
  setFieldValue(formLocation, [
    ...array.slice(0, index),
    ...array.slice(index, index + 2).reverse(),
    ...array.slice(index + 2),
  ])
}

export const moveItemUpInFormList = ({
  setFieldValue,
  array,
  index,
  formLocation,
}) => {
  setFieldValue(formLocation, [
    ...array.slice(0, index - 1),
    ...array.slice(index - 1, index + 1).reverse(),
    ...array.slice(index + 1),
  ])
}

export const addNewItemToList = ({
  setFieldValue,
  array,
  index,
  fieldLocation,
  initialData,
}) => {
  setFieldValue(fieldLocation, [
    ...array.slice(0, index + 1),
    initialData,
    ...array.slice(index + 1),
  ])
}

export const getHrefOrExpertError = ({ form, widgetIndex }) =>
  get(form, ['errors', 'widgets', widgetIndex, 'content', 'atLeastOneRequired'])
