import { v4 as uuidv4 } from 'uuid'
import { EMPTY_EXPERT_PICKER_OPTION } from '@admin/components/ExpertPicker'

export const getInitialGalleryItemData = () => ({
  expert: EMPTY_EXPERT_PICKER_OPTION,
  caption: '',
  attachment: {},
  clientId: uuidv4(),
})

// honestly, I can't figure out better error handler for gallery item :sad:. Any ideas?
export const getGalleryItemErrorsFromForm = ({
  form,
  widgetIndex,
  galleryItemIndex,
}) => {
  const { widgets = [] } = form.errors
  const attachmentError =
    widgets &&
    widgets[widgetIndex]?.content?.cmsGalleryWidgetItems &&
    widgets[widgetIndex]?.content?.cmsGalleryWidgetItems[galleryItemIndex]
      ?.attachment?.id
  const expertError =
    widgets &&
    widgets[widgetIndex]?.content?.cmsGalleryWidgetItems &&
    widgets[widgetIndex]?.content?.cmsGalleryWidgetItems[galleryItemIndex]
      ?.expert?.value?.id
  return [
    ...(attachmentError ? [attachmentError] : []),
    ...(expertError ? [expertError] : []),
  ]
}
