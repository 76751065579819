import * as Yup from 'yup'

export const videoSectionSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    title: Yup.string().max(200).required().label('Headline'),
    subtitle: Yup.string().label('Subheadline').max(200).nullable(),
    hasCtaButton: Yup.boolean(),
    thumbnailAttachment: Yup.object().shape({
      id: Yup.number().required().label('Thumbnail'),
    }),
    videoAttachment: Yup.object().shape({
      id: Yup.number().required().label('Video'),
    }),
    ctaCopy: Yup.string()
      .label('Button text')
      .max(30)
      .when('hasCtaButton', {
        is: true,
        then: (ctaCopy) => ctaCopy.required(),
      }),
    ctaCopyUrl: Yup.string()
      .label('Button link')
      .max(200)
      .when('hasCtaButton', {
        is: true,
        then: (ctaCopyUrl) => ctaCopyUrl.required(),
      }),
  }),
})
