import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Input } from 'components/Input'
import { Flex, Grid } from 'components/Layout'
import { SPACE } from 'Theme'
import { VideoAttachmentField } from '../VideoAttachmentField'
import { VideoThumbnailField } from './VideoThumbnailField'

export const Video = ({ validationPrefix, widgetIndex }) => (
  <Flex flexDirection="column">
    <Field name={`${validationPrefix}.title`}>
      {({ field, meta }) => (
        <Input
          label="Title"
          isError={meta.error !== undefined}
          hint={meta.error ?? ''}
          hasHint
          {...field}
        />
      )}
    </Field>
    <Grid gridTemplateColumns="1fr 1fr" mt={SPACE.PX_20} gridGap={SPACE.PX_20}>
      <VideoThumbnailField
        validationPrefix={validationPrefix}
        widgetIndex={widgetIndex}
      />
      <VideoAttachmentField
        validationPrefix={validationPrefix}
        widgetIndex={widgetIndex}
      />
    </Grid>
  </Flex>
)

Video.propTypes = {
  validationPrefix: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  widgetIndex: PropTypes.number.isRequired,
}
