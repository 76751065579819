import { Field } from 'formik'
import React from 'react'
import { Input } from 'components/Input'
import { Flex } from 'components/Layout'
import { FIELD_NAMES } from './validationSchema'

interface HeadlineProps {
  validationPrefix: string
}

export const Headline: React.FC<React.PWC<HeadlineProps>> = ({
  validationPrefix,
}) => (
  <Flex flexDirection="column">
    <Field name={`${validationPrefix}.${FIELD_NAMES.HEADLINE}`}>
      {({ field, meta }) => (
        <Input
          label="Headline*"
          isError={meta.error !== undefined}
          hint={meta.error ?? ''}
          hasHint
          {...field}
        />
      )}
    </Field>
    <Field name={`${validationPrefix}.${FIELD_NAMES.SUBHEADLINE}`}>
      {({ field, meta }) => (
        <Input
          label="Subheadline"
          isError={meta.error !== undefined}
          hint={meta.error ?? ''}
          hasHint
          {...field}
        />
      )}
    </Field>
  </Flex>
)
