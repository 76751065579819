import * as Yup from 'yup'
import { getExpertPickerValidationSchema } from '@admin/components/ExpertPicker'

export const cmsGalleryItemWidgetSchemaValidation = Yup.array().of(
  Yup.object().shape({
    expert: getExpertPickerValidationSchema(),
    attachment: Yup.object({
      id: Yup.number().required().label('Image'),
    }),
    caption: Yup.object().nullable(),
  })
)
