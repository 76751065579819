import { Field } from 'formik'
import { get } from 'lodash'
import React from 'react'
import { WidgetWrapper } from '@admin/routes/Cms/components/WidgetWrapper'
import { AttachmentPickerField } from '@admin/routes/Navigation/AttachmentPickerField'
import { CHECKBOX_SIZE, CheckboxGroup } from 'components/Checkbox'
import { Input } from 'components/Input'
import { Flex, Grid } from 'components/Layout'
import { ASPECT_RATIO } from 'components/UploadImage'
import { SPACE } from 'Theme'
import { type CmsHeroCarouselWidgetFieldsFragment } from 'types/graphql-generated'
import {
  deleteItemFromFormList,
  moveItemDownInFormList,
  moveItemUpInFormList,
} from '../utils'

interface HeroCarouselItemProps {
  heroCarouselItemsFieldName: string
  index: number
  items: CmsHeroCarouselWidgetFieldsFragment['cmsHeroCarouselWidgetItems']
}

export const HeroCarouselItem: React.FC<React.PWC<HeroCarouselItemProps>> = ({
  heroCarouselItemsFieldName,
  index,
  items,
}) => {
  const heroCarouselItemFieldName = `${heroCarouselItemsFieldName}[${index}]`
  const actionParams = {
    index,
    array: items,
    formLocation: heroCarouselItemsFieldName,
  }

  return (
    <Field>
      {({ form: { setFieldValue, setFieldError, values } }) => {
        const hasButton = get(values, `${heroCarouselItemFieldName}.hasButton`)
        return (
          <WidgetWrapper
            widgetName={`Slide ${index + 1}`}
            isMoveDownDisabled={index === items.length - 1}
            isMoveUpDisabled={index === 0}
            onDeleteWidget={() => {
              const isLastItem = items.length === 1
              if (isLastItem) {
                // If there is only one item left, we need to clear the whole array, otherwise errors persist
                setFieldError(heroCarouselItemsFieldName, undefined)
              }
              deleteItemFromFormList({
                setFieldValue: (formLocation, array) =>
                  // The third argument is required to trigger validation to display the 'At least one slide is required' error.
                  setFieldValue(formLocation, array, isLastItem),
                ...actionParams,
              })
            }}
            onMoveDownWidget={() => {
              moveItemDownInFormList({
                setFieldValue,
                ...actionParams,
              })
            }}
            onMoveUpWidget={() => {
              moveItemUpInFormList({
                setFieldValue,
                ...actionParams,
              })
            }}
          >
            <Grid gridGap={SPACE.PX_20} gridTemplateColumns="1fr 2fr">
              <Flex alignItems="start">
                <AttachmentPickerField
                  name={`${heroCarouselItemFieldName}.attachment`}
                  aspectRatio={ASPECT_RATIO.HERO}
                />
              </Flex>
              <Flex flexDirection="column" width="100%">
                <Field name={`${heroCarouselItemFieldName}.preTitle`}>
                  {({ field, meta }) => (
                    <Input
                      label="Pre-title"
                      isError={meta.error !== undefined}
                      hint={meta.error ?? ''}
                      hasHint
                      {...field}
                    />
                  )}
                </Field>
                <Field name={`${heroCarouselItemFieldName}.headline`}>
                  {({ field, meta }) => (
                    <Input
                      label="Headline"
                      isError={meta.error !== undefined}
                      hint={meta.error ?? ''}
                      hasHint
                      {...field}
                    />
                  )}
                </Field>
                <Field name={`${heroCarouselItemFieldName}.subheadline`}>
                  {({ field, meta }) => (
                    <Input
                      label="Subheadline"
                      isError={meta.error !== undefined}
                      hint={meta.error ?? ''}
                      hasHint
                      {...field}
                    />
                  )}
                </Field>
                <CheckboxGroup
                  size={CHECKBOX_SIZE.M}
                  label=""
                  hint=""
                  mb={SPACE.PX_20}
                >
                  <Field
                    type="checkbox"
                    name={`${heroCarouselItemFieldName}.hasButton`}
                  >
                    {({ form, field: { checked, ...restField } }) => {
                      const checkboxValue =
                        get(
                          form.values,
                          `${heroCarouselItemFieldName}.hasButton`
                        ) === undefined
                          ? form.setFieldValue(
                              `${heroCarouselItemFieldName}.hasButton`,
                              true
                            )
                          : checked

                      return (
                        <CheckboxGroup.Item
                          isChecked={checkboxValue}
                          {...restField}
                          onChange={({ target }) => {
                            form.setFieldValue(
                              `${heroCarouselItemFieldName}.hasButton`,
                              target.checked,
                              true
                            )
                            if (!target.checked) {
                              form.setFieldValue(
                                `${heroCarouselItemFieldName}.buttonText`,
                                ''
                              )
                              form.setFieldValue(
                                `${heroCarouselItemFieldName}.buttonLink`,
                                ''
                              )
                            }
                          }}
                        >
                          Enable button
                        </CheckboxGroup.Item>
                      )
                    }}
                  </Field>
                </CheckboxGroup>
                <Field name={`${heroCarouselItemFieldName}.buttonText`}>
                  {({ field, meta }) => (
                    <Input
                      label={`Button text${hasButton ? '*' : ''}`}
                      isDisabled={!hasButton}
                      isError={meta.error !== undefined}
                      hint={meta.error ?? ''}
                      hasHint
                      {...field}
                    />
                  )}
                </Field>
                <Field name={`${heroCarouselItemFieldName}.buttonLink`}>
                  {({ field, meta }) => (
                    <Input
                      label={`Button link${hasButton ? '*' : ''}`}
                      isDisabled={!hasButton}
                      isError={meta.error !== undefined}
                      hint={meta.error ?? ''}
                      hasHint
                      {...field}
                    />
                  )}
                </Field>
              </Flex>
            </Grid>
          </WidgetWrapper>
        )
      }}
    </Field>
  )
}
