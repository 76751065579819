import { Field } from 'formik'
import React, { useState } from 'react'
import { PagePickerModal } from '@admin/components/PagePicker'
import { WidgetWrapper } from '@admin/routes/Cms/components/WidgetWrapper'
import { AttachmentPickerField } from '@admin/routes/Navigation/AttachmentPickerField'
import { ClickableReadonlyInput, Input } from 'components/Input'
import { Box, Flex, Grid } from 'components/Layout'
import { ASPECT_RATIO } from 'components/UploadImage'
import { SPACE } from 'Theme'
import { type CmsCardNavigationWidgetFieldsFragment } from 'types/graphql-generated'
import { truncateStringWithEllipsisInTheBeginning } from 'utils/truncateStringWithEllipsis'
import {
  deleteItemFromFormList,
  moveItemDownInFormList,
  moveItemUpInFormList,
} from '../utils'

interface CardNavigationItemProps {
  index: number
  cmsCardNavigationWidgetItems: CmsCardNavigationWidgetFieldsFragment['cmsCardNavigationWidgetItems']
  cardNavigationItemsFieldName: string
}

export const CardNavigationItem: React.FC<
  React.PWC<CardNavigationItemProps>
> = ({ index, cmsCardNavigationWidgetItems, cardNavigationItemsFieldName }) => {
  const [isPagePickerModalOpen, setIsPagePickerModalOpen] = useState(false)
  const collectionActionParams = {
    index,
    array: cmsCardNavigationWidgetItems,
    formLocation: cardNavigationItemsFieldName,
  }
  const cardNavigationItemPrefix = `${cardNavigationItemsFieldName}[${index}]`
  return (
    <Field>
      {({ form: { setFieldValue } }) => (
        <WidgetWrapper
          widgetName={`${index + 1}. Navigation item`}
          isMoveDownDisabled={index === cmsCardNavigationWidgetItems.length - 1}
          isMoveUpDisabled={index === 0}
          onDeleteWidget={() => {
            deleteItemFromFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
          onMoveDownWidget={() => {
            moveItemDownInFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
          onMoveUpWidget={() => {
            moveItemUpInFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
        >
          <Grid gridTemplateColumns="1fr 2fr" gridGap={SPACE.PX_20}>
            <Flex alignItems="start">
              <AttachmentPickerField
                name={`${cardNavigationItemPrefix}.attachment`}
                aspectRatio={ASPECT_RATIO.PRODUCTS}
              />
            </Flex>
            <Flex flexDirection="column" width="100%">
              <Field name={`${cardNavigationItemPrefix}.title`}>
                {({ field, meta }) => (
                  <Input
                    label="Item title*"
                    isError={meta.error !== undefined}
                    hint={meta.error ?? ''}
                    hasHint
                    {...field}
                  />
                )}
              </Field>
              <Field name={`${cardNavigationItemPrefix}.cmsPage`}>
                {({ field: { name }, meta }) => {
                  const { cmsPage } = cmsCardNavigationWidgetItems[index]
                  return (
                    <Box mb={SPACE.PX_15}>
                      <PagePickerModal
                        selectedPageId={cmsPage?.id}
                        onSelect={(selectedPage) => {
                          setFieldValue(name, selectedPage)
                          setIsPagePickerModalOpen(false)
                        }}
                        isModalOpen={isPagePickerModalOpen}
                        onModalClose={() => {
                          setIsPagePickerModalOpen(false)
                        }}
                      />
                      <ClickableReadonlyInput
                        onClick={() => {
                          setIsPagePickerModalOpen(true)
                        }}
                        label="Page*"
                        placeholder="Click to select a Page..."
                        value={
                          cmsPage?.slug
                            ? truncateStringWithEllipsisInTheBeginning(
                                cmsPage.slug
                              )
                            : ''
                        }
                        isError={meta.error !== undefined}
                        hint={meta.error?.id || ''}
                      />
                    </Box>
                  )
                }}
              </Field>
            </Flex>
          </Grid>
        </WidgetWrapper>
      )}
    </Field>
  )
}
