import * as Yup from 'yup'

export const cmsQAWidgetItemValidationSchema = Yup.array()
  .of(
    Yup.object({
      question: Yup.string().label('Question').trim().required(),
      answer: Yup.mixed().label('Answer').required(),
    })
  )
  .label('QA items')
  .min(1)
