import { rem } from 'polished'
import React, { useState } from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Flex } from 'components/Layout'
import { disabledHover } from 'components/mixins'
import { Text } from 'components/Typography'
import { useValidationErrorTrackingForGA4Provider } from 'providers/googleAnalytics/utils/ErrorTracking/useValidationErrorTrackingForGA4'
import {
  FONT_SIZE,
  SPACE,
  RADIUS,
  BORDER_WIDTH,
  getBorderColor,
  getTextColor,
  HINT_HEIGHT,
  LINE_HEIGHT,
  COLOR_INTENT,
} from 'Theme'

export const TEXTAREA_PADDING = SPACE.PX_15

const Label = styled('label')`
  display: inline-flex;
  width: 100%;
  cursor: pointer;
  font-size: ${FONT_SIZE.PX_14};
  flex-direction: column;
  ${({ isDisabled }) =>
    isDisabled &&
    `
    ${disabledHover}
  `}
`

const Wrapper = styled('div')`
  display: inline-flex;
  border: ${BORDER_WIDTH.PX_1} solid ${({ borderColor }) => borderColor};
  background-color: ${COLOR_INTENT.TEXTAREA.BACKGROUND};
  width: 100%;
  border-radius: ${RADIUS.PX_6};
  padding: ${TEXTAREA_PADDING};
  line-height: ${LINE_HEIGHT.XXS};
  align-items: center;
  cursor: text;
  ${({ isFocused, isError, isWarning, isDisabled }) =>
    !isFocused &&
    !isError &&
    !isWarning &&
    !isDisabled &&
    `
    &:hover {
      border-color: ${COLOR_INTENT.TEXTAREA.BORDER};
    }
  `}
  ${({ isDisabled }) =>
    isDisabled &&
    `
    background-color: ${COLOR_INTENT.TEXTAREA.DISABLED_BACKGROUND};
    border: none;
    ${disabledHover}
  `}
`

const InputArea = styled.textarea`
  line-height: ${LINE_HEIGHT.XXS};
  width: 100%;
  border: none;
  height: auto;
  background: transparent;
  padding: 0;
  &::placeholder {
    color: ${COLOR_INTENT.TEXTAREA.PLACEHOLDER};
  }
  ${({ disabled }) =>
    disabled &&
    `
    color: ${COLOR_INTENT.TEXTAREA.DISABLED_TEXT};
  `}
`

const StyledText = styled(Text)`
  ${({ hasHint }) => hasHint && `height: ${HINT_HEIGHT};`}
`

const MaxLengthText = styled(Text)`
  flex-shrink: 0;
`

interface TextareaProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onPaste'> {
  label?: string
  name?: string
  value?: string
  maxLength?: number
  hint?: string
  hasHint?: boolean
  placeholder?: string
  isDisabled?: boolean
  isError?: boolean
  isWarning?: boolean
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement, Element>) => void
  onPaste?: (value: string) => void
}

export const Textarea: React.FC<React.PWC<TextareaProps>> = ({
  label,
  value,
  maxLength,
  hint,
  hasHint,
  isDisabled = false,
  isError = false,
  isWarning = false,
  onChange,
  onBlur: onBlurHandler,
  onPaste,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false)
  useValidationErrorTrackingForGA4Provider({
    hint,
    isError,
  })

  return (
    <Label isDisabled={isDisabled} name={props?.name}>
      {label && (
        <Flex alignItems="flex-end" justifyContent="space-between">
          <Text mb={SPACE.PX_5} lineHeight={LINE_HEIGHT.XS}>
            {label}
          </Text>
          {maxLength && (
            <Flex width={rem(80)} justifyContent="flex-end">
              <MaxLengthText
                flexShrink={0}
                color={COLOR_INTENT.TEXTAREA.MAX_LENGTH_TEXT}
                data-test-class={DATA_TEST_CLASS.TEXTAREA_MAXLENGTH}
              >
                {value?.length || 0}/{maxLength}
              </MaxLengthText>
            </Flex>
          )}
        </Flex>
      )}
      <Flex display="inline-flex" flexDirection="column">
        <Wrapper
          isFocused={isFocused}
          isWarning={isWarning}
          isError={isError}
          isDisabled={isDisabled}
          borderColor={getBorderColor({
            isFocusedOrChecked: isFocused,
            isError,
            isWarning,
          })}
        >
          <InputArea
            onFocus={() => {
              setIsFocused(true)
            }}
            onBlur={(event) => {
              setIsFocused(false)
              if (onBlurHandler) {
                onBlurHandler(event)
              }
            }}
            disabled={isDisabled}
            onChange={(event) => {
              if (event.target.value.length > maxLength) {
                return
              }
              onChange(event)
            }}
            value={value}
            onPaste={(event) => {
              if (onPaste) {
                const start = event.target.selectionStart
                event.clipboardData.items[0].getAsString((text) => {
                  const valueWithPastedText = `${value.slice(
                    0,
                    start
                  )}${text}${value.slice(start)}`
                  if (maxLength) {
                    const data = valueWithPastedText.substring(0, maxLength)
                    onPaste(data)
                  } else {
                    onPaste(valueWithPastedText)
                  }
                })
              }
            }}
            {...props}
          />
        </Wrapper>
        {(hint || hasHint) && (
          <StyledText
            color={getTextColor({ isWarning, isError })}
            fontSize={FONT_SIZE.PX_12}
            mt={SPACE.PX_5}
            isWarning={isWarning}
            isError={isError}
            hasHint={hasHint}
            data-test-name={props?.name}
            data-test-class={DATA_TEST_CLASS.TEXTAREA_HINT}
          >
            {hint}
          </StyledText>
        )}
      </Flex>
    </Label>
  )
}
