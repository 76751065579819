import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Input } from 'components/Input'
import { Flex } from 'components/Layout'

export const GiftCard = ({ validationPrefix }) => (
  <Flex flexDirection="column">
    <Field name={`${validationPrefix}.title`}>
      {({ field, meta }) => (
        <Input
          label="Title"
          isError={meta.error !== undefined}
          hint={meta.error ?? ''}
          hasHint
          {...field}
        />
      )}
    </Field>
    <Field name={`${validationPrefix}.description`}>
      {({ field, meta }) => (
        <Input
          label="Description"
          isError={meta.error !== undefined}
          hint={meta.error ?? ''}
          hasHint
          {...field}
        />
      )}
    </Field>
  </Flex>
)

GiftCard.propTypes = {
  validationPrefix: PropTypes.string.isRequired,
}
