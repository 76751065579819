import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { COLOR, FONT_SIZE, SPACE } from 'Theme'
import { AddWidgetItemButton } from '../../AddWidgetItemButton'
import { addNewItemToList } from '../utils'
import { QAItem, getInitialQAItemData } from './QAItem'

export const QA = ({ validationPrefix, cmsQAWidgetItems }) => {
  const qaItemsFieldName = `${validationPrefix}.cmsQAWidgetItems`
  return (
    <>
      <Field name={qaItemsFieldName}>
        {({ meta }) => (
          <Flex flexDirection="column">
            <Text mt={SPACE.PX_20} fontSize={FONT_SIZE.PX_16}>
              Questions & Answers:
            </Text>
            {typeof meta?.error === 'string' && (
              <Text
                fontSize={FONT_SIZE.PX_12}
                mt={SPACE.PX_5}
                color={COLOR.SECONDARY_1}
              >
                {meta.error}
              </Text>
            )}
          </Flex>
        )}
      </Field>
      <Field>
        {({ form: { setFieldValue } }) => (
          <AddWidgetItemButton
            onClick={() => {
              setFieldValue(qaItemsFieldName, [
                getInitialQAItemData(),
                ...(cmsQAWidgetItems ?? []),
              ])
            }}
          >
            Add QA item
          </AddWidgetItemButton>
        )}
      </Field>
      {cmsQAWidgetItems &&
        cmsQAWidgetItems.map((cmsQAWidgetItem, index) => (
          <div key={cmsQAWidgetItem.clientId}>
            <QAItem
              index={index}
              cmsQAWidgetItem={cmsQAWidgetItem}
              qaItemsFieldName={qaItemsFieldName}
              cmsQAWidgetItems={cmsQAWidgetItems}
            />
            <Field>
              {({ form: { setFieldValue } }) => (
                <AddWidgetItemButton
                  onClick={() => {
                    addNewItemToList({
                      setFieldValue,
                      index,
                      array: cmsQAWidgetItems,
                      initialData: getInitialQAItemData(),
                      fieldLocation: qaItemsFieldName,
                    })
                  }}
                >
                  Add QA item
                </AddWidgetItemButton>
              )}
            </Field>
          </div>
        ))}
    </>
  )
}

QA.propTypes = {
  validationPrefix: PropTypes.string.isRequired,
  cmsQAWidgetItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
}
