import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Input } from 'components/Input'
import { Text } from 'components/Typography'
import { SPACE, FONT_SIZE } from 'Theme'
import { AddWidgetItemButton } from '../../AddWidgetItemButton'
import { addNewItemToList } from '../utils'
import { ArticlesItem, getInitialArticlesItemData } from './ArticlesItem'

export const Articles = ({ validationPrefix, cmsArticlesWidgetItems }) => {
  const articlesField = `${validationPrefix}.cmsArticlesWidgetItems`
  return (
    <>
      <Field name={`${validationPrefix}.title`}>
        {({ field, meta }) => (
          <Input
            label="Title"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.subtitle`}>
        {({ field, meta }) => (
          <Input
            label="Subtitle"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Text mt={SPACE.PX_20} fontSize={FONT_SIZE.PX_16}>
        Articles:
      </Text>
      <Field>
        {({ form: { setFieldValue } }) => (
          <>
            <AddWidgetItemButton
              onClick={() => {
                setFieldValue(articlesField, [
                  getInitialArticlesItemData(),
                  ...(cmsArticlesWidgetItems ?? []),
                ])
              }}
            >
              Add Article
            </AddWidgetItemButton>
            {cmsArticlesWidgetItems &&
              cmsArticlesWidgetItems.map((cmsArticlesWidgetItem, index) => (
                <div key={cmsArticlesWidgetItem.clientId}>
                  <ArticlesItem
                    cmsArticlesWidgetItems={cmsArticlesWidgetItems}
                    cmsArticlesWidgetItem={cmsArticlesWidgetItem}
                    articlesField={articlesField}
                    setFieldValue={setFieldValue}
                    index={index}
                  />
                  <AddWidgetItemButton
                    onClick={() => {
                      addNewItemToList({
                        setFieldValue,
                        index,
                        array: cmsArticlesWidgetItems,
                        initialData: getInitialArticlesItemData(),
                        fieldLocation: articlesField,
                      })
                    }}
                  >
                    Add Article
                  </AddWidgetItemButton>
                </div>
              ))}
          </>
        )}
      </Field>
    </>
  )
}

Articles.propTypes = {
  widgetIndex: PropTypes.number,
  setFieldValue: PropTypes.func.isRequired,
  validationPrefix: PropTypes.string.isRequired,
  cmsArticlesWidgetItems: PropTypes.arrayOf(
    PropTypes.shape({
      clientId: PropTypes.string.isRequired,
      article: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        subtitle: PropTypes.string,
      }),
    })
  ),
}
