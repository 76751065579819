import { Field } from 'formik'
import dynamic from 'next/dynamic'
import React from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { SPACE, COLOR_INTENT } from 'Theme'
import { useChangeVideoThumbnailMutation } from 'types/graphql-generated'
import { ATTACHMENT_TYPE, UploadAttachment } from '../UploadAttachment'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

export interface VideoAttachmentFieldProps {
  widgetIndex: number
  validationPrefix: string
}

export const VideoAttachmentField = ({
  validationPrefix,
  widgetIndex,
}: VideoAttachmentFieldProps) => {
  const [executeChangeVideoThumbnail] = useChangeVideoThumbnailMutation()
  return (
    <Flex width="100%" flexShrink={0}>
      <Field name={`${validationPrefix}.videoAttachment`}>
        {({ field, meta, form }) => {
          let content = (
            <UploadAttachment
              inputAccept={ATTACHMENT_TYPE.VIDEO}
              onSelect={async (videoAttachment) => {
                form.setFieldValue(
                  `${validationPrefix}.videoAttachment`,
                  videoAttachment
                )
                const thumbnailAttachmentId =
                  form.values.widgets[widgetIndex]?.content?.thumbnailAttachment
                    ?.id
                if (thumbnailAttachmentId && videoAttachment) {
                  await executeChangeVideoThumbnail({
                    variables: {
                      input: {
                        videoId: videoAttachment.id,
                        thumbnailAttachmentId,
                      },
                    },
                  })
                }
              }}
            />
          )
          if (field?.value?.url) {
            content = (
              <Flex flexDirection="column" flex={1} width="100%">
                <ReactPlayer
                  url={field?.value?.url}
                  controls
                  pip={false}
                  width="100%"
                  height="auto"
                />
                <Button
                  mt={SPACE.PX_15}
                  onClick={() => {
                    form.setFieldValue(
                      `${validationPrefix}.videoAttachment`,
                      {}
                    )
                  }}
                >
                  Change video
                </Button>
              </Flex>
            )
          }
          return (
            <Flex flexDirection="column" flex={1} width="100%">
              <Text mb={meta.error === undefined ? SPACE.PX_20 : 0}>
                Video*
              </Text>
              {meta.error && (
                <Text
                  color={COLOR_INTENT.WIDGETS.VIDEO_SECTION.HINT_ERROR_TEXT}
                >
                  {meta.error.id}
                </Text>
              )}
              {content}
            </Flex>
          )
        }}
      </Field>
    </Flex>
  )
}
