import * as Yup from 'yup'

export const collectionHeaderSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    title: Yup.string().trim().max(40).required().label('Title'),
    subtitle: Yup.string().trim().max(100).nullable().label('Subtitle'),
    isWhiteTextColor: Yup.boolean().nullable(),
    headerImageAttachment: Yup.object().shape({
      id: Yup.number().required().label('Header image'),
    }),
    mobileImageAttachment: Yup.object()
      .shape({
        id: Yup.number().nullable().label('Mobile header image'),
      })
      .nullable(),
  }),
})
