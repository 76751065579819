import React from 'react'
import { Flex } from 'components/Layout'
import { SPACE } from 'Theme'
import { Card } from '../components/Card'
import { FIELD_NAMES } from './validationSchema'

interface ThreeColumnCardProps {
  validationPrefix: string
}

export const ThreeColumnCard: React.FC<React.PWC<ThreeColumnCardProps>> = ({
  validationPrefix,
}) => (
  <Flex flexDirection="column" gap={SPACE.PX_20}>
    <Card validationPrefix={`${validationPrefix}.${FIELD_NAMES.CARDS}[0]`} />
    <Card validationPrefix={`${validationPrefix}.${FIELD_NAMES.CARDS}[1]`} />
    <Card validationPrefix={`${validationPrefix}.${FIELD_NAMES.CARDS}[2]`} />
  </Flex>
)
