import React from 'react'
import { Modal } from 'components/Modal'
import { MODAL_MAX_WIDTH } from 'Theme'
import { PagePicker } from './PagePicker'

const MODAL_TITLE = 'Pick a Page'

interface PagePickerModalProps {
  isModalOpen: boolean
  onModalClose: () => void
  onSelect: (cmsPage: any) => void
  selectedPageId: number
  filter?: any
}

export const PagePickerModal: React.FC<React.PWC<PagePickerModalProps>> = ({
  isModalOpen,
  onModalClose,
  onSelect,
  selectedPageId,
  filter = null,
}) => (
  <Modal
    maxWidth={MODAL_MAX_WIDTH.PX_600}
    ariaLabel={MODAL_TITLE}
    title={MODAL_TITLE}
    isOpen={isModalOpen}
    isWithFooter={false}
    onModalClose={onModalClose}
    overflow="none"
  >
    <PagePicker
      onSelect={onSelect}
      selectedPageId={selectedPageId}
      filter={filter}
    />
  </Modal>
)
