import { v4 as uuidv4 } from 'uuid'

export const getInitialProductsItemData = () => ({
  pretitle: '',
  title: '',
  posttitle: '',
  description: '',
  href: '',
  attachment: {},
  clientId: uuidv4(),
})
