import { Menu, MenuItem, SubMenu } from '@szhsin/react-menu'
import { menuItemSelector } from '@szhsin/react-menu/style-utils'
import { get } from 'lodash'
import { rem } from 'polished'
import React from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Button } from 'components/Button'
import { Flex } from 'components/Layout'
import { BORDER_WIDTH, COLOR_INTENT, Z_INDEX } from 'Theme'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

const MENU_OFFSET_Y = 6
const MENU_ARROW_SIZE = rem(7)

const StyledMenu = styled(Menu)`
  z-index: ${Z_INDEX.CONTEXT_MENU};
  ${menuItemSelector.submenu} {
    &::after {
      content: '';
      display: inline-block;
      border-right: ${BORDER_WIDTH.PX_2} solid ${COLOR_INTENT.GRAY_70};
      border-bottom: ${BORDER_WIDTH.PX_2} solid ${COLOR_INTENT.GRAY_70};
      width: ${MENU_ARROW_SIZE};
      height: ${MENU_ARROW_SIZE};
      transform: rotate(-45deg);
    }
  }
`

type MenuItemType = {
  name: string
  __typename?: string
  items?: readonly MenuItemType[]
}

const generateMenuItems = ({
  menuItems,
  onClick,
}: {
  menuItems: readonly MenuItemType[]
  onClick: (__typename: string) => void
}) =>
  menuItems.map(({ name, ...menuItem }) => {
    const items = get(menuItem, 'items')
    if (items) {
      return (
        <SubMenu key={name} label={name}>
          {generateMenuItems({ menuItems: items, onClick })}
        </SubMenu>
      )
    }
    return (
      <MenuItem
        key={name}
        onClick={() => {
          onClick(get(menuItem, '__typename'))
        }}
      >
        {name}
      </MenuItem>
    )
  })

interface ContextMenuButtonProps {
  menuItems?: readonly MenuItemType[]
  onClick: (__typename: string) => void
}

export const ContextMenuButton: React.FC<React.PWC<ContextMenuButtonProps>> = ({
  menuItems,
  onClick,
  children,
}) => (
  <Flex justifyContent="center">
    <StyledMenu
      menuButton={
        <Button
          width="max-content"
          variant="outline"
          data-test-id={DATA_TEST_ID.ADMIN_ADD_WIDGET_BTN}
        >
          {children}
        </Button>
      }
      gap={MENU_OFFSET_Y}
      transition
    >
      {generateMenuItems({ menuItems, onClick })}
    </StyledMenu>
  </Flex>
)
