import { Field } from 'formik'
import dynamic from 'next/dynamic'
import React, { useState } from 'react'
import { ShowroomPagePickerModal } from '@admin/components/ShowroomPagePicker'
import { ImageAttachment } from '@admin/routes/Cms/components/ImageAttachment'
import { WidgetWrapper } from '@admin/routes/Cms/components/WidgetWrapper'
import { ClickableReadonlyInput, Input } from 'components/Input'
import { Box, Flex } from 'components/Layout'
import { Tooltip } from 'components/Tooltip'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { SPACE } from 'Theme'
import { type CmsExpertCollectionsWidgetItem } from 'types/graphql-generated'
import { getNestedStringProperty } from 'utils/formatters'
import {
  deleteItemFromFormList,
  moveItemDownInFormList,
  moveItemUpInFormList,
} from '../../utils'

interface ExpertCollectionsItemProps {
  cmsExpertCollectionsWidgetItems: CmsExpertCollectionsWidgetItem[]
  expertCollectionsItemsFieldName: string
  index: number
}

const ExpertPickerModal = dynamic(
  () =>
    import('@admin/components/ExpertPicker').then(
      (module) => module.ExpertPickerModal
    ),
  {
    ssr: false,
  }
)

export const ExpertCollectionsItem: React.FC<ExpertCollectionsItemProps> = ({
  cmsExpertCollectionsWidgetItems,
  expertCollectionsItemsFieldName,
  index,
}) => {
  const [isShowroomPagePickerModalOpen, setIsShowroomPagePickerModalOpen] =
    useState(false)
  const [isExpertPickerModalOpen, setIsExpertPickerModalOpen] = useState(false)
  const collectionActionParams = {
    index,
    array: cmsExpertCollectionsWidgetItems,
    formLocation: expertCollectionsItemsFieldName,
  }
  const expertCollectionsItemPrefix = `${expertCollectionsItemsFieldName}[${index}]`
  return (
    <Field>
      {({ form: { setFieldValue } }) => (
        <WidgetWrapper
          widgetName={`${index + 1}. Collection`}
          isMoveDownDisabled={
            index === cmsExpertCollectionsWidgetItems.length - 1
          }
          isMoveUpDisabled={index === 0}
          onDeleteWidget={() => {
            deleteItemFromFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
          onMoveDownWidget={() => {
            moveItemDownInFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
          onMoveUpWidget={() => {
            moveItemUpInFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
        >
          <Flex flexDirection="column" width="100%">
            <Flex flexDirection="row" mb={SPACE.PX_30}>
              <Flex alignSelf="start">
                <ImageAttachment
                  label="Preview image"
                  aspectRatio={ASPECT_RATIO.PRODUCTS}
                  fieldName={`${expertCollectionsItemPrefix}.previewImageAttachment`}
                  hasPaddingOnTop={false}
                />
              </Flex>
              <Flex alignSelf="start" ml={SPACE.PX_15}>
                <ImageAttachment
                  label="Profile image"
                  aspectRatio={ASPECT_RATIO.PROFILE}
                  fieldName={`${expertCollectionsItemPrefix}.profileImageAttachment`}
                  hasPaddingOnTop={false}
                />
              </Flex>
            </Flex>
            <Field name={`${expertCollectionsItemPrefix}.cmsShowroomPage`}>
              {({ field: { name }, meta }) => {
                const { cmsShowroomPage } =
                  cmsExpertCollectionsWidgetItems[index]
                return (
                  <Box mb={SPACE.PX_15}>
                    <ShowroomPagePickerModal
                      selectedShowroomPage={cmsShowroomPage}
                      selectedShowroomPageId={cmsShowroomPage?.id}
                      onSelect={(selectedShowroomPage) => {
                        setFieldValue(name, selectedShowroomPage)
                        setIsShowroomPagePickerModalOpen(false)
                      }}
                      isModalOpen={isShowroomPagePickerModalOpen}
                      onModalClose={() => {
                        setIsShowroomPagePickerModalOpen(false)
                      }}
                    />
                    <Tooltip
                      body="This field is deprecated"
                      mouseLeaveDelay={0}
                    >
                      <ClickableReadonlyInput
                        onClick={() => {
                          setIsShowroomPagePickerModalOpen(true)
                        }}
                        label="Showroom Page"
                        placeholder="Click to select a Showroom Page..."
                        value={cmsShowroomPage?.cmsPage?.title ?? ''}
                        isError={meta.error !== undefined}
                        hint={meta.error?.id || ''}
                      />
                    </Tooltip>
                  </Box>
                )
              }}
            </Field>
            <Field name={`${expertCollectionsItemPrefix}.expert`}>
              {({ field, meta, form }) => (
                <Box mb={SPACE.PX_15}>
                  <ExpertPickerModal
                    name={field.name}
                    value={{
                      label: field.value?.profileName,
                      value: field.value,
                    }}
                    onChange={({ value }: any) => {
                      setFieldValue(field.name, value)
                      form.setFieldTouched(field.name, true)
                      setIsExpertPickerModalOpen(false)
                    }}
                    isModalOpen={isExpertPickerModalOpen}
                    onModalClose={() => {
                      setIsExpertPickerModalOpen(false)
                    }}
                  />
                  <ClickableReadonlyInput
                    onClick={() => {
                      setIsExpertPickerModalOpen(true)
                    }}
                    label="Expert"
                    value={field.value?.profileName ?? ''}
                    placeholder="Click to select an expert..."
                    isError={meta.error !== undefined}
                    hint={
                      meta.error ? getNestedStringProperty(meta.error) : null
                    }
                    hasHint
                  />
                </Box>
              )}
            </Field>
            <Field name={`${expertCollectionsItemPrefix}.caption`}>
              {({ field, meta }) => (
                <Input
                  label="Caption"
                  isError={meta.error !== undefined}
                  hint={meta.error ?? ''}
                  hasHint
                  {...field}
                />
              )}
            </Field>
          </Flex>
        </WidgetWrapper>
      )}
    </Field>
  )
}
