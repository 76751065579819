import { Field } from 'formik'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { EXPERT_USER_CAPABILITIES } from 'shared-constants'
import { WidgetWrapper } from '@admin/routes/Cms/components/WidgetWrapper'
import { ClickableReadonlyInput } from 'components/Input'
import { Flex } from 'components/Layout'
import { SPACE } from 'Theme'
import { getNestedStringProperty } from 'utils/formatters'
import {
  deleteItemFromFormList,
  moveItemDownInFormList,
  moveItemUpInFormList,
} from '../../utils'

const ExpertPickerModal = dynamic(
  () =>
    import('@admin/components/ExpertPicker').then(
      (module) => module.ExpertPickerModal
    ),
  {
    ssr: false,
  }
)

export const ExpertsItem = ({
  cmsExpertsWidgetItems,
  setFieldValue,
  expertsField,
  index,
}) => {
  const [isExpertPickerModalOpen, setIsExpertPickerModalOpen] = useState(false)
  const expertsItemActionParams = {
    setFieldValue,
    index,
    array: cmsExpertsWidgetItems,
    formLocation: expertsField,
  }
  return (
    <WidgetWrapper
      widgetName={`${index + 1}. Expert`}
      isMoveDownDisabled={index === cmsExpertsWidgetItems.length - 1}
      isMoveUpDisabled={index === 0}
      onDeleteWidget={() => {
        deleteItemFromFormList(expertsItemActionParams)
      }}
      onMoveDownWidget={() => {
        moveItemDownInFormList(expertsItemActionParams)
      }}
      onMoveUpWidget={() => {
        moveItemUpInFormList(expertsItemActionParams)
      }}
    >
      <Flex flexDirection="column" width="100%">
        <Flex mb={SPACE.PX_20}>
          <Field name={`${expertsField}[${index}].expert`}>
            {({ field, meta, form }) => (
              <>
                <ExpertPickerModal
                  name={field.name}
                  value={field.value}
                  onChange={(expert) => {
                    setFieldValue(field.name, expert)
                    form.setFieldTouched(field.name, true)
                    setIsExpertPickerModalOpen(false)
                  }}
                  isModalOpen={isExpertPickerModalOpen}
                  onModalClose={() => {
                    setIsExpertPickerModalOpen(false)
                  }}
                  capabilitiesIncluded={[EXPERT_USER_CAPABILITIES.CONSULTATION]}
                />
                <ClickableReadonlyInput
                  onClick={() => {
                    setIsExpertPickerModalOpen(true)
                  }}
                  label="Expert"
                  value={field.value?.label}
                  placeholder="Click to select an expert..."
                  isError={meta.error !== undefined}
                  hint={meta.error ? getNestedStringProperty(meta.error) : null}
                  hasHint
                />
              </>
            )}
          </Field>
        </Flex>
      </Flex>
    </WidgetWrapper>
  )
}

const cmsExpertsWidgetItemPropType = PropTypes.shape({
  clientId: PropTypes.string.isRequired,
  expert: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.shape({
      id: PropTypes.number,
      profileName: PropTypes.string,
      businessName: PropTypes.string,
    }),
  }),
})

ExpertsItem.propTypes = {
  cmsExpertsWidgetItems: PropTypes.arrayOf(cmsExpertsWidgetItemPropType),
  cmsExpertsWidgetItem: cmsExpertsWidgetItemPropType,
  setFieldValue: PropTypes.func.isRequired,
  expertsField: PropTypes.string.isRequired,
  index: PropTypes.number,
}
