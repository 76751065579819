import React, { useState } from 'react'
import { Select } from 'components/Select'
import { Tooltip } from 'components/Tooltip'
import { useDebouncedInput } from 'hooks'
import { MODAL_SELECT_DROPDOWN_HEIGHT } from 'Theme'
import {
  type AdminCmsPagesQuery,
  CmsPageSorterField,
  SortDirection,
  useAdminCmsPagesQuery,
} from 'types/graphql-generated'
import { truncateStringWithEllipsisInTheBeginning } from 'utils/truncateStringWithEllipsis'

type Edges = AdminCmsPagesQuery['adminCmsPages']['edges']
type Node = Edges[0]['node']

const getOptions = (edges: Edges) =>
  edges.map(({ node }) => ({
    value: node.id,
    label: truncateStringWithEllipsisInTheBeginning(node.slug),
    payload: node,
  }))

interface PagePickerProps {
  onSelect: (cmsPage?: Node) => void
  selectedPageId: number
  filter: any
}

export const PagePicker: React.FC<React.PWC<PagePickerProps>> = ({
  onSelect,
  selectedPageId,
  filter = {
    isPublished: true,
    isStatic: false,
  },
}) => {
  const [searchValue, setSearchValue] = useState('')
  const { isBouncing, debouncedValue } = useDebouncedInput(searchValue)

  const { data, loading: isLoading } = useAdminCmsPagesQuery({
    variables: {
      input: {
        filter: {
          ...filter,
        },
        sorter: {
          field: CmsPageSorterField.Id,
          direction: SortDirection.Asc,
        },
        search: debouncedValue,
        pagination: {
          limit: 8,
        },
      },
    },
  })

  const edges = data?.adminCmsPages?.edges ?? []
  const options = getOptions(edges)

  return (
    <Select
      isSearchable
      isLoading={isLoading}
      height={MODAL_SELECT_DROPDOWN_HEIGHT}
      options={isBouncing ? [] : options}
      tooltip={(children, payload) => (
        <Tooltip body={payload.slug}>{children}</Tooltip>
      )}
      value={options.find(({ value }) => value === selectedPageId) || null}
      onInputChange={setSearchValue}
      onChange={({ value: id, payload: cmsPage }: any) => {
        if (id) {
          onSelect(cmsPage)
        } else {
          onSelect()
        }
      }}
      name="page picker"
    />
  )
}
