import { Field } from 'formik'
import React from 'react'
import { CheckboxGroup } from 'components/Checkbox'

interface ExpertsListingProps {
  validationPrefix: string
}

export const ExpertsListing: React.FC<React.PWC<ExpertsListingProps>> = ({
  validationPrefix,
}) => (
  <CheckboxGroup>
    <Field name={`${validationPrefix}.isFilterEnabled`} type="checkbox">
      {({ field: { checked, ...field } }) => (
        <CheckboxGroup.Item isChecked={checked} {...field}>
          Enable filter
        </CheckboxGroup.Item>
      )}
    </Field>
  </CheckboxGroup>
)
