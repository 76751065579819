import { Field } from 'formik'
import React from 'react'
import { ImageAttachment } from '@admin/routes/Cms/components/ImageAttachment'
import { WidgetWrapper } from '@admin/routes/Cms/components/WidgetWrapper'
import { Box } from 'components/Layout'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { CATEGORY_HEADER_WIDGET_TYPE } from 'constants/common'
import {
  type Attachment,
  type CmsCategoryHeaderWidget,
} from 'types/graphql-generated'
import {
  deleteItemFromFormList,
  moveItemDownInFormList,
  moveItemUpInFormList,
} from '../../utils'
import { FIELD_NAMES } from './categoryHeaderItemValidationSchema'

export type CMSCategoryHeaderWidgetItemType = {
  id: string
  attachment: Pick<Attachment, 'id' | 'url' | 'alt'>
}

interface CategoryHeaderItemProps {
  index: number
  cmsCategoryHeaderWidgetItems: CMSCategoryHeaderWidgetItemType[]
  categoryHeaderWidgetItemsFieldName: string
  widgetType: CmsCategoryHeaderWidget['type']
}

export const CategoryHeaderItem: React.FC<
  React.PWC<CategoryHeaderItemProps>
> = ({
  cmsCategoryHeaderWidgetItems,
  categoryHeaderWidgetItemsFieldName,
  index,
  widgetType,
}) => {
  const collectionActionParams = {
    index,
    array: cmsCategoryHeaderWidgetItems,
    formLocation: categoryHeaderWidgetItemsFieldName,
  }
  const categoryHeaderItemPrefix = `${categoryHeaderWidgetItemsFieldName}[${index}]`
  return (
    <Field>
      {({ form: { setFieldValue } }) => (
        <WidgetWrapper
          widgetName={`${index + 1}. Image`}
          isMoveDownDisabled={index === cmsCategoryHeaderWidgetItems.length - 1}
          isMoveUpDisabled={index === 0}
          onDeleteWidget={() => {
            deleteItemFromFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
          onMoveDownWidget={() => {
            moveItemDownInFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
          onMoveUpWidget={() => {
            moveItemUpInFormList({
              setFieldValue,
              ...collectionActionParams,
            })
          }}
        >
          <Box maxWidth="50%" m="0 auto">
            <ImageAttachment
              fieldName={`${categoryHeaderItemPrefix}.${FIELD_NAMES.ATTACHMENT}`}
              hasPaddingOnTop={false}
              aspectRatio={
                widgetType === CATEGORY_HEADER_WIDGET_TYPE.CAROUSEL
                  ? ASPECT_RATIO.CATEGORY_HEADER_CAROUSEL
                  : ASPECT_RATIO.CATEGORY_HEADER_SINGLE_IMAGE
              }
            />
          </Box>
        </WidgetWrapper>
      )}
    </Field>
  )
}
