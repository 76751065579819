import { Field } from 'formik'
import { get } from 'lodash'
import React from 'react'
import { AttachmentPickerField } from '@admin/routes/Navigation/AttachmentPickerField'
import { CHECKBOX_SIZE } from 'components/Checkbox'
import { CheckboxGroup } from 'components/Checkbox/CheckboxGroup'
import { Input } from 'components/Input'
import { Flex, Grid } from 'components/Layout'
import { ASPECT_RATIO } from 'components/UploadImage'
import { INPUT_SIZE, SPACE } from 'Theme'

interface HeroMainProps {
  validationPrefix: string
}

export const HeroMain: React.FC<React.PWC<HeroMainProps>> = ({
  validationPrefix,
}) => (
  <Grid gridGap={SPACE.PX_20} gridTemplateColumns="1fr 2fr">
    <Flex alignItems="start">
      <AttachmentPickerField
        name={`${validationPrefix}.attachment`}
        aspectRatio={ASPECT_RATIO.MENU_SUB_ITEM}
      />
    </Flex>
    <Flex flexDirection="column">
      <Field name={`${validationPrefix}.headline`}>
        {({ field, meta }) => (
          <Input
            label="Headline*"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            size={INPUT_SIZE.M}
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.subheadline`}>
        {({ field, meta }) => (
          <Input
            label="Subheadline"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.mainButtonText`}>
        {({ field, meta }) => (
          <Input
            label="Main button text*"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.mainButtonLink`}>
        {({ field, meta }) => (
          <Input
            label="Main button link*"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <CheckboxGroup size={CHECKBOX_SIZE.M} label="" hint="" mb={SPACE.PX_20}>
        <Field type="checkbox" name={`${validationPrefix}.hasSecondaryButton`}>
          {({
            form: { values, setFieldValue },
            field: { checked, ...restField },
          }) => {
            const checkboxValue =
              get(values, `${validationPrefix}.hasSecondaryButton`) ===
              undefined
                ? setFieldValue(`${validationPrefix}.hasSecondaryButton`, true)
                : checked

            return (
              // @ts-ignore - CheckboxGroup needs to be transpiled to Typescript
              <CheckboxGroup.Item
                isChecked={checkboxValue}
                {...restField}
                onChange={({ target }) => {
                  setFieldValue(
                    `${validationPrefix}.hasSecondaryButton`,
                    target.checked
                  )
                  if (!target.checked) {
                    setFieldValue(`${validationPrefix}.secondaryButtonText`, '')
                    setFieldValue(`${validationPrefix}.secondaryButtonLink`, '')
                  }
                }}
              >
                Enable secondary button
                {/* @ts-ignore - CheckboxGroup needs to be transpiled to Typescript */}
              </CheckboxGroup.Item>
            )
          }}
        </Field>
      </CheckboxGroup>
      <Field name={`${validationPrefix}.secondaryButtonText`}>
        {({ field, meta, form: { values } }) => (
          <Input
            label={`Secondary button text${
              get(values, `${validationPrefix}.hasSecondaryButton`) ? '*' : ''
            }`}
            isDisabled={!get(values, `${validationPrefix}.hasSecondaryButton`)}
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.secondaryButtonLink`}>
        {({ field, meta, form: { values } }) => (
          <Input
            label={`Secondary button link${
              get(values, `${validationPrefix}.hasSecondaryButton`) ? '*' : ''
            }`}
            isDisabled={!get(values, `${validationPrefix}.hasSecondaryButton`)}
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
    </Flex>
  </Grid>
)
