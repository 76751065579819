import * as Yup from 'yup'

export const FIELD_NAMES = {
  TEXT: 'text',
  LINK: 'link',
}

export const buttonWidgetItemValidationSchema = Yup.object().shape({
  content: Yup.object().shape({
    [FIELD_NAMES.TEXT]: Yup.string().max(30).label('Button text').required(),
    [FIELD_NAMES.LINK]: Yup.string().max(255).label('Button URL').required(),
  }),
})
