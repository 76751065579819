import { getInitialThreeColumnCardWidgetItemValues } from './ThreeColumnCard'
import { getInitialTwoColumnCardWidgetItemValues } from './TwoColumnCard'

export const ALLOWED_WIDGET_ITEM_TYPES = {
  BUTTON: 'CMSWidgetItemButton',
  HEADLINE: 'CMSWidgetItemHeadline',
  TWO_COLUMN_CARD: 'CMSWidgetItemTwoColumnCard',
  THREE_COLUMN_CARD: 'CMSWidgetItemThreeColumnCard',
} as const

export const WIDGET_ITEM_NAMES = {
  BUTTON: {
    __typename: ALLOWED_WIDGET_ITEM_TYPES.BUTTON,
    name: 'Button',
  },
  HEADLINE: {
    __typename: ALLOWED_WIDGET_ITEM_TYPES.HEADLINE,
    name: 'Headline',
  },
  TWO_COLUMN_CARD: {
    __typename: ALLOWED_WIDGET_ITEM_TYPES.TWO_COLUMN_CARD,
    name: 'Two-column card',
  },
  THREE_COLUMN_CARD: {
    __typename: ALLOWED_WIDGET_ITEM_TYPES.THREE_COLUMN_CARD,
    name: 'Three-column card',
  },
} as const

export const WIDGET_ITEM_MENU_ITEMS = [
  WIDGET_ITEM_NAMES.BUTTON,
  WIDGET_ITEM_NAMES.HEADLINE,
  WIDGET_ITEM_NAMES.TWO_COLUMN_CARD,
  WIDGET_ITEM_NAMES.THREE_COLUMN_CARD,
] as const

export const INITIAL_WIDGET_ITEM_VALUES = {
  [ALLOWED_WIDGET_ITEM_TYPES.TWO_COLUMN_CARD]:
    getInitialTwoColumnCardWidgetItemValues(),
  [ALLOWED_WIDGET_ITEM_TYPES.THREE_COLUMN_CARD]:
    getInitialThreeColumnCardWidgetItemValues(),
} as const
