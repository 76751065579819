import { Field } from 'formik'
import { find, get, isString } from 'lodash'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { AddWidgetItemButton } from '@admin/routes/Cms/components/AddWidgetItemButton'
import { CardNavigationItem } from '@admin/routes/Cms/components/Widgets/CardNavigation/CardNavigationItem'
import { CHECKBOX_SIZE, CheckboxGroup } from 'components/Checkbox'
import { Input } from 'components/Input'
import { Flex } from 'components/Layout'
import { Select } from 'components/Select'
import { Text } from 'components/Typography'
import { WIDGET_PLACEMENT } from 'constants/common'
import { FONT_SIZE, getTextColor, SPACE } from 'Theme'
import { type CmsCardNavigationWidgetFieldsFragment } from 'types/graphql-generated'
import { WIDGET_PLACEMENT_OPTIONS } from '../constants'
import { addNewItemToList } from '../utils'

type CardNavigationWidgetItem =
  CmsCardNavigationWidgetFieldsFragment['cmsCardNavigationWidgetItems'][0] & {
    clientId: string
  }

interface CardNavigationProps {
  widgetIndex: number
  validationPrefix: string
  cmsCardNavigationWidgetItems: CardNavigationWidgetItem[]
}

const defaultPlacementValue = find(WIDGET_PLACEMENT_OPTIONS, {
  value: WIDGET_PLACEMENT.HERO,
})

const getInitialItemData = () => ({
  clientId: uuidv4(),
})
export const CardNavigation: React.FC<React.PWC<CardNavigationProps>> = ({
  validationPrefix,
  cmsCardNavigationWidgetItems = [],
}) => {
  const cardNavigationItemsFieldName = `${validationPrefix}.cmsCardNavigationWidgetItems`
  return (
    <Field>
      {({ form: { setFieldValue, values } }) => {
        const hasButton = get(values, `${validationPrefix}.hasButton`)

        return (
          <Flex flexDirection="column">
            <Field name={`${validationPrefix}.placement`}>
              {({ field, meta }) => (
                <Select
                  defaultValue={defaultPlacementValue}
                  label="Placement*"
                  options={WIDGET_PLACEMENT_OPTIONS}
                  isError={meta.error !== undefined}
                  hint={meta.error ?? ''}
                  hasHint
                  {...field}
                  onChange={({ value }: any) => {
                    setFieldValue(field.name, value)
                  }}
                />
              )}
            </Field>
            <Field name={`${validationPrefix}.title`}>
              {({ field, meta }) => (
                <Input
                  label="Title"
                  isError={meta.error !== undefined}
                  hint={meta.error ?? ''}
                  hasHint
                  {...field}
                />
              )}
            </Field>
            <Field name={`${validationPrefix}.subtitle`}>
              {({ field, meta }) => (
                <Input
                  label="Subtitle"
                  isError={meta.error !== undefined}
                  hint={meta.error ?? ''}
                  hasHint
                  {...field}
                />
              )}
            </Field>

            <CheckboxGroup
              size={CHECKBOX_SIZE.M}
              label=""
              hint=""
              mb={SPACE.PX_20}
            >
              <Field type="checkbox" name={`${validationPrefix}.hasButton`}>
                {({ form, field: { checked, ...restField } }) => {
                  const checkboxValue =
                    get(form.values, `${validationPrefix}.hasButton`) ===
                    undefined
                      ? form.setFieldValue(
                          `${validationPrefix}.hasButton`,
                          true
                        )
                      : checked

                  return (
                    <CheckboxGroup.Item
                      isChecked={checkboxValue}
                      {...restField}
                      onChange={({ target }) => {
                        form.setFieldValue(
                          `${validationPrefix}.hasButton`,
                          target.checked,
                          true
                        )
                        if (!target.checked) {
                          form.setFieldValue(
                            `${validationPrefix}.buttonText`,
                            ''
                          )
                          form.setFieldValue(
                            `${validationPrefix}.buttonLink`,
                            ''
                          )
                        }
                      }}
                    >
                      Enable button
                    </CheckboxGroup.Item>
                  )
                }}
              </Field>
            </CheckboxGroup>
            <Field name={`${validationPrefix}.buttonText`}>
              {({ field, meta }) => (
                <Input
                  label={`Button text${hasButton ? '*' : ''}`}
                  isDisabled={!hasButton}
                  isError={meta.error !== undefined}
                  hint={meta.error ?? ''}
                  hasHint
                  {...field}
                />
              )}
            </Field>
            <Field name={`${validationPrefix}.buttonLink`}>
              {({ field, meta }) => (
                <Input
                  label={`Button link${hasButton ? '*' : ''}`}
                  isDisabled={!hasButton}
                  isError={meta.error !== undefined}
                  hint={meta.error ?? ''}
                  hasHint
                  {...field}
                />
              )}
            </Field>

            <Field name={cardNavigationItemsFieldName}>
              {({ meta }) => (
                <>
                  {isString(meta?.error) && (
                    <Flex flexDirection="column">
                      <Text
                        fontSize={FONT_SIZE.PX_12}
                        mt={SPACE.PX_5}
                        color={getTextColor({ isError: true })}
                      >
                        {meta.error}
                      </Text>
                    </Flex>
                  )}
                  <AddWidgetItemButton
                    onClick={() => {
                      setFieldValue(cardNavigationItemsFieldName, [
                        getInitialItemData(),
                        ...cmsCardNavigationWidgetItems,
                      ])
                    }}
                  >
                    Add navigation item
                  </AddWidgetItemButton>
                </>
              )}
            </Field>

            {cmsCardNavigationWidgetItems?.map((item, index) => (
              <React.Fragment key={item?.clientId}>
                <CardNavigationItem
                  index={index}
                  cmsCardNavigationWidgetItems={cmsCardNavigationWidgetItems}
                  cardNavigationItemsFieldName={cardNavigationItemsFieldName}
                />
                <Field>
                  {() => (
                    <AddWidgetItemButton
                      onClick={() => {
                        addNewItemToList({
                          setFieldValue,
                          index,
                          array: cmsCardNavigationWidgetItems,
                          fieldLocation: cardNavigationItemsFieldName,
                          initialData: getInitialItemData(),
                        })
                      }}
                    >
                      Add navigation item
                    </AddWidgetItemButton>
                  )}
                </Field>
              </React.Fragment>
            ))}
          </Flex>
        )
      }}
    </Field>
  )
}
