import { Field, useFormikContext } from 'formik'
import { find, get } from 'lodash'
import dynamic from 'next/dynamic'
import React, { useState } from 'react'
import { EXPERT_USER_CAPABILITIES } from 'shared-constants'
import { EMPTY_EXPERT_PICKER_OPTION } from '@admin/components/ExpertPicker'
import { ClickableReadonlyInput, Input } from 'components/Input'
import { Flex } from 'components/Layout'
import { Select } from 'components/Select'
import { Text } from 'components/Typography'
import { CATEGORY_HEADER_WIDGET_TYPE } from 'constants/common'
import { FONT_SIZE, getTextColor } from 'Theme'
import { getNestedStringProperty } from 'utils/formatters'
import { AddWidgetItemButton } from '../../AddWidgetItemButton'
import { CATEGORY_HEADER_WIDGET_TYPE_OPTIONS } from '../constants'
import { addNewItemToList } from '../utils'
import {
  CategoryHeaderItem,
  type CMSCategoryHeaderWidgetItemType,
} from './CategoryHeaderItem/CategoryHeaderItem'
import { getInitialCategoryHeaderItemData } from './CategoryHeaderItem/categoryHeaderItemValidationSchema'
import { FIELD_NAMES } from './categoryHeaderSchemaValidation'

const ExpertPickerModal = dynamic(
  () =>
    import('@admin/components/ExpertPicker').then(
      (module) => module.ExpertPickerModal
    ),
  {
    ssr: false,
  }
)

interface CategoryHeaderProps {
  validationPrefix: string
  cmsCategoryHeaderWidgetItems?: CMSCategoryHeaderWidgetItemType[]
  widgetIndex: number
}

export const CategoryHeader: React.FC<React.PWC<CategoryHeaderProps>> = ({
  widgetIndex,
  validationPrefix,
  cmsCategoryHeaderWidgetItems = [],
}) => {
  const [isExpertPickerModalOpen, setIsExpertPickerModalOpen] = useState(false)
  const {
    values: formikValues,
    errors: formikErrors,
    setFieldValue,
  } = useFormikContext()
  const categoryHeaderWidgetItemsFieldName = `${validationPrefix}.${FIELD_NAMES.ITEMS}`

  const widgetItemsError = get(formikErrors, [
    'widgets',
    widgetIndex,
    'content',
    FIELD_NAMES.ITEMS,
  ])

  const widgetType = get(formikValues, [
    'widgets',
    widgetIndex,
    'content',
    FIELD_NAMES.TYPE,
  ])

  const isCarouselType = widgetType === CATEGORY_HEADER_WIDGET_TYPE.CAROUSEL

  return (
    <>
      <Flex flexDirection="column">
        <Field name={`${validationPrefix}.${FIELD_NAMES.TYPE}`}>
          {({ field, meta }) => (
            <Select
              defaultValue={find(CATEGORY_HEADER_WIDGET_TYPE_OPTIONS, {
                value: CATEGORY_HEADER_WIDGET_TYPE.SINGLE_IMAGE,
              })}
              label="Type"
              options={CATEGORY_HEADER_WIDGET_TYPE_OPTIONS}
              isError={meta.error !== undefined}
              hint={meta.error ?? ''}
              hasHint
              {...field}
              onChange={({ value }: any) => {
                setFieldValue(
                  `${validationPrefix}.${FIELD_NAMES.EXPERT}`,
                  EMPTY_EXPERT_PICKER_OPTION
                )
                setFieldValue(categoryHeaderWidgetItemsFieldName, [])
                setFieldValue(field.name, value)
              }}
            />
          )}
        </Field>
        <Field name={`${validationPrefix}.${FIELD_NAMES.TITLE}`}>
          {({ field, meta }) => (
            <Input
              label="Title"
              isError={meta.error !== undefined}
              hint={meta.error ?? ''}
              hasHint
              {...field}
            />
          )}
        </Field>
        <Field name={`${validationPrefix}.${FIELD_NAMES.SUBTITLE}`}>
          {({ field, meta }) => (
            <Input
              label="Subtitle"
              isError={meta.error !== undefined}
              hint={meta.error ?? ''}
              hasHint
              {...field}
            />
          )}
        </Field>
        {isCarouselType && (
          <Field name={`${validationPrefix}.${FIELD_NAMES.EXPERT}`}>
            {({ field, meta, form }) => (
              <>
                <ExpertPickerModal
                  name={field.name}
                  value={field.value}
                  onChange={(expert) => {
                    setFieldValue(field.name, expert)
                    form.setFieldTouched(field.name, true)
                    setIsExpertPickerModalOpen(false)
                  }}
                  isModalOpen={isExpertPickerModalOpen}
                  onModalClose={() => {
                    setIsExpertPickerModalOpen(false)
                  }}
                  capabilitiesIncluded={[EXPERT_USER_CAPABILITIES.CONSULTATION]}
                />
                <ClickableReadonlyInput
                  onClick={() => {
                    setIsExpertPickerModalOpen(true)
                  }}
                  label="Expert"
                  value={field.value?.label}
                  placeholder="Click to select an expert..."
                  isError={meta.error !== undefined}
                  hint={meta.error ? getNestedStringProperty(meta.error) : null}
                  hasHint
                />
              </>
            )}
          </Field>
        )}
      </Flex>
      {typeof widgetItemsError === 'string' && (
        <Text
          fontSize={FONT_SIZE.PX_12}
          color={getTextColor({ isWarning: false, isError: true })}
        >
          {widgetItemsError}
        </Text>
      )}
      {(isCarouselType ||
        (!isCarouselType && cmsCategoryHeaderWidgetItems.length === 0)) && (
        <AddWidgetItemButton
          onClick={() => {
            setFieldValue(categoryHeaderWidgetItemsFieldName, [
              getInitialCategoryHeaderItemData(),
              ...cmsCategoryHeaderWidgetItems,
            ])
          }}
        >
          Add Image
        </AddWidgetItemButton>
      )}
      {cmsCategoryHeaderWidgetItems.map((item, index) => (
        <React.Fragment key={item.id}>
          <CategoryHeaderItem
            widgetType={widgetType}
            index={index}
            cmsCategoryHeaderWidgetItems={cmsCategoryHeaderWidgetItems}
            categoryHeaderWidgetItemsFieldName={
              categoryHeaderWidgetItemsFieldName
            }
          />
          {isCarouselType && (
            <AddWidgetItemButton
              onClick={() => {
                addNewItemToList({
                  setFieldValue,
                  index,
                  array: cmsCategoryHeaderWidgetItems,
                  fieldLocation: categoryHeaderWidgetItemsFieldName,
                  initialData: getInitialCategoryHeaderItemData(),
                })
              }}
            >
              Add Image
            </AddWidgetItemButton>
          )}
        </React.Fragment>
      ))}
    </>
  )
}
