import * as Yup from 'yup'

export const FIELD_NAMES = {
  ATTACHMENT: 'attachment',
}

export const getInitialCategoryHeaderItemData = () => ({
  [FIELD_NAMES.ATTACHMENT]: {},
})

export const categoryHeaderItemSchemaValidation = Yup.array().of(
  Yup.object().shape({
    [FIELD_NAMES.ATTACHMENT]: Yup.object().shape({
      id: Yup.number().required().label('Image'),
    }),
  })
)
