import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Input } from 'components/Input'
import { Text } from 'components/Typography'
import { SPACE, FONT_SIZE } from 'Theme'
import { AddWidgetItemButton } from '../../AddWidgetItemButton'
import { addNewItemToList } from '../utils'
import { GalleryItem, getInitialGalleryItemData } from './GalleryItem'

export const Gallery = ({
  validationPrefix,
  cmsGalleryWidgetItems,
  setFieldValue,
  widgetIndex,
}) => {
  const galleryImageField = `${validationPrefix}.cmsGalleryWidgetItems`
  return (
    <>
      <Field name={`${validationPrefix}.title`}>
        {({ field, meta }) => (
          <Input
            label="Title"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Text mt={SPACE.PX_20} fontSize={FONT_SIZE.PX_16}>
        Gallery items:
      </Text>
      <AddWidgetItemButton
        onClick={() => {
          setFieldValue(galleryImageField, [
            getInitialGalleryItemData(),
            ...(cmsGalleryWidgetItems ?? []),
          ])
        }}
      >
        Add gallery item
      </AddWidgetItemButton>
      {cmsGalleryWidgetItems &&
        cmsGalleryWidgetItems.map((cmsGalleryWidgetItem, index) => (
          <div key={cmsGalleryWidgetItem.clientId}>
            <GalleryItem
              widgetIndex={widgetIndex}
              cmsGalleryWidgetItems={cmsGalleryWidgetItems}
              cmsGalleryWidgetItem={cmsGalleryWidgetItem}
              galleryImageField={galleryImageField}
              setFieldValue={setFieldValue}
              index={index}
            />
            <AddWidgetItemButton
              onClick={() => {
                addNewItemToList({
                  setFieldValue,
                  index,
                  array: cmsGalleryWidgetItems,
                  initialData: getInitialGalleryItemData(),
                  fieldLocation: galleryImageField,
                })
              }}
            >
              Add gallery item
            </AddWidgetItemButton>
          </div>
        ))}
    </>
  )
}

Gallery.propTypes = {
  widgetIndex: PropTypes.number,
  setFieldValue: PropTypes.func.isRequired,
  validationPrefix: PropTypes.string.isRequired,
  cmsGalleryWidgetItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
}
