import { Field } from 'formik'
import { isString } from 'lodash'
import React, { type ComponentProps } from 'react'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { SPACE, FONT_SIZE, COLOR } from 'Theme'
import { AddWidgetItemButton } from '../../AddWidgetItemButton'
import { addNewItemToList } from '../utils'
import {
  ExpertCollectionsItem,
  getInitialExpertCollectionsItemData,
} from './ExpertCollectionsItem'

interface ExpertCollectionsProps
  extends Pick<
    ComponentProps<typeof ExpertCollectionsItem>,
    'cmsExpertCollectionsWidgetItems'
  > {
  validationPrefix: string
}

export const ExpertCollections = ({
  validationPrefix,
  cmsExpertCollectionsWidgetItems,
}: ExpertCollectionsProps) => {
  const expertCollectionsItemsFieldName = `${validationPrefix}.cmsExpertCollectionsWidgetItems`
  return (
    <>
      <Field name={expertCollectionsItemsFieldName}>
        {({ form: { setFieldValue }, meta }) => (
          <>
            {isString(meta?.error) && (
              <Flex flexDirection="column">
                <Text
                  fontSize={FONT_SIZE.PX_12}
                  mt={SPACE.PX_5}
                  color={COLOR.SECONDARY_1}
                >
                  {meta.error}
                </Text>
              </Flex>
            )}
            <AddWidgetItemButton
              onClick={() => {
                setFieldValue(expertCollectionsItemsFieldName, [
                  getInitialExpertCollectionsItemData(),
                  ...(cmsExpertCollectionsWidgetItems ?? []),
                ])
              }}
            >
              Add collection
            </AddWidgetItemButton>
          </>
        )}
      </Field>
      {cmsExpertCollectionsWidgetItems?.map(
        (cmsExpertCollectionsWidgetItem, index) => (
          <React.Fragment key={cmsExpertCollectionsWidgetItem.id}>
            <ExpertCollectionsItem
              cmsExpertCollectionsWidgetItems={cmsExpertCollectionsWidgetItems}
              expertCollectionsItemsFieldName={expertCollectionsItemsFieldName}
              index={index}
            />
            <Field>
              {({ form: { setFieldValue } }) => (
                <AddWidgetItemButton
                  onClick={() => {
                    addNewItemToList({
                      setFieldValue,
                      index,
                      array: cmsExpertCollectionsWidgetItems,
                      fieldLocation: expertCollectionsItemsFieldName,
                      initialData: getInitialExpertCollectionsItemData(),
                    })
                  }}
                >
                  Add collection
                </AddWidgetItemButton>
              )}
            </Field>
          </React.Fragment>
        )
      )}
    </>
  )
}
