import { gql } from '@apollo/client'
import emojiRegex from 'emoji-regex'
import { isPlainObject, reduce, flatMap } from 'lodash'
import urlRegexSafe from 'url-regex-safe'
import validUrl from 'valid-url'
import * as Yup from 'yup'
import {
  PASSWORD_VALIDATION,
  CONTAINS_LETTERS_REGEX_PATTERN,
  VALID_URL_SLUG_PATTERN,
} from 'constants/common'

const VALIDATE_EXPERT_SLUG = gql`
  query validateAvailableExpertSlug($input: ValidateExpertSlugInput!) {
    validateAvailableExpertSlug(input: $input)
  }
`

const VALIDATE_EXPERT_BUSINESS_NAME = gql`
  query validateAvailableExpertBusinessName(
    $input: ValidateExpertBusinessNameInput!
  ) {
    validateAvailableExpertBusinessName(input: $input)
  }
`

export const VALIDATE_APPLICATION_EMAIL = gql`
  query validateAvailableExpertApplicationEmail(
    $input: ValidateAvailableExpertApplicationEmailInput!
  ) {
    validateAvailableExpertApplicationEmail(input: $input)
  }
`

export const isUrlValid = (url) => {
  if (url === '' || url === undefined) {
    return true
  }
  return !!validUrl.isWebUri(url)
}

export const isRichTextEditorEmpty = (editorState) =>
  !editorState.getEditorState().getCurrentContent().hasText()

export const passwordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .label('Password')
    .min(8, 'Minimum 8 characters')
    .matches(...Object.values(PASSWORD_VALIDATION))
    .required(),
  confirmPassword: Yup.string()
    .required()
    .label('Confirm password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export const safeStringSchema = Yup.string().test(
  'safe-string',
  'Cannot contain URL or emoji',
  (value) =>
    !value ||
    !(value.match(emojiRegex()) || value.match(urlRegexSafe({ strict: true })))
)

export const slugValidationSchema = ({ apolloClient, expertId }) =>
  Yup.object().shape({
    slug: Yup.string()
      .label('Profile URL')
      .min(5)
      .test(
        'contains-letters',
        'URL has to contain at least 1 letter',
        (slug) => {
          if (slug) {
            return !!slug.match(CONTAINS_LETTERS_REGEX_PATTERN)
          }
          return true
        }
      )
      .test(
        'valid-url',
        'Only letters, numbers or hyphens are allowed',
        (slug) => {
          if (slug) {
            return !!slug.match(VALID_URL_SLUG_PATTERN)
          }
          return true
        }
      )
      .test('profile-url-not-taken', 'URL is already taken', async (slug) => {
        if (
          slug &&
          !!slug.match(CONTAINS_LETTERS_REGEX_PATTERN) &&
          !!slug.match(VALID_URL_SLUG_PATTERN)
        ) {
          try {
            const {
              data: { validateAvailableExpertSlug },
            } = await apolloClient.query({
              query: VALIDATE_EXPERT_SLUG,
              fetchPolicy: 'network-only',
              variables: {
                input: {
                  slug,
                  expertId,
                },
              },
            })
            return validateAvailableExpertSlug
          } catch (error) {
            return false
          }
        }
        return true
      })
      .required(),
  })

export const expertBusinessNameValidationSchema = ({ apolloClient }) =>
  Yup.object().shape({
    businessName: Yup.string()
      .label('Firm name')
      .max(255)
      .test(
        'business-name-not-taken',
        'Firm name is already taken',
        async (businessName) => {
          if (businessName) {
            try {
              const {
                data: { validateAvailableExpertBusinessName },
              } = await apolloClient.query({
                query: VALIDATE_EXPERT_BUSINESS_NAME,
                fetchPolicy: 'network-only',
                variables: {
                  input: {
                    businessName,
                  },
                },
              })
              return validateAvailableExpertBusinessName
            } catch (error) {
              return false
            }
          }
          return true
        }
      )
      .required(),
  })

export const isExpertApplicationEmailValid = async ({
  email,
  apolloClient,
  omitRejected,
}) => {
  try {
    const { data, errors } = await apolloClient.query({
      query: VALIDATE_APPLICATION_EMAIL,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          email,
          omitRejected,
        },
      },
    })
    if (data && data.validateAvailableExpertApplicationEmail) {
      return false
    }

    if (errors && errors.length > 0) {
      return false
    }
  } catch (error) {
    return false
  }
  return true
}

export const mapKeysRecursively = (obj, prefix) =>
  flatMap(obj, (_, key) => {
    const targetKey = prefix ? `${prefix}.${key}` : key
    return isPlainObject(obj[key])
      ? mapKeysRecursively(obj[key], targetKey)
      : targetKey
  })

export const runValidationViaRef = async (ref) => {
  const errorFields = await ref.current?.validateForm?.()
  if (errorFields) {
    ref.current?.setTouched?.(
      reduce(
        mapKeysRecursively(errorFields),
        (acc, field) => ({ ...acc, [field]: true }),
        {}
      ),
      false
    )
  }
  return errorFields
}
