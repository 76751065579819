import { useState, useCallback, useRef } from 'react'
import { Toast } from 'components/Toast'

export const VIDEO_VALID_TYPES = ['video/mp4']

export const UploadVideoProvider = ({ children }) => {
  const fileInput = useRef(null)
  const imageRef = useRef(null)
  const [uploadedVideo, setUploadedVideo] = useState()
  const [uploadedVideoPreview, setUploadedVideoPreview] = useState()
  const [isImageLoading, setIsImageLoading] = useState(false)

  const onLoad = useCallback((image) => {
    imageRef.current = image
  }, [])

  const handleFileChange = ({ target: { files } }) => {
    if (files && files.length > 0) {
      setIsImageLoading(true)
      setUploadedVideo(files[0])
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          setUploadedVideoPreview(reader.result)
          setIsImageLoading(false)
        },
        false
      )
      reader.readAsDataURL(files[0])
    }
  }

  const handleDrop = useCallback((event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    if (file && VIDEO_VALID_TYPES.includes(file.type)) {
      setIsImageLoading(true)
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          setUploadedVideoPreview(reader.result)
          setUploadedVideo(reader.result)
          setIsImageLoading(false)
        },
        false
      )
      reader.readAsDataURL(file)
    } else {
      Toast.error('Wrong file type.')
    }
  }, [])

  const resetVideo = useCallback(() => {
    setUploadedVideo(null)
    setUploadedVideoPreview(null)
  }, [])

  return children({
    resetVideo,
    handleDrop,
    isImageLoading,
    uploadedVideo,
    uploadedVideoPreview,
    onLoad,
    handleFileChange,
    fileInput,
  })
}

UploadVideoProvider.propTypes = {}
