import { Field } from 'formik'
import { find } from 'lodash'
import React from 'react'
import { Input } from 'components/Input'
import { Flex } from 'components/Layout'
import { Select } from 'components/Select'
import { ALPHABETICAL_INDEX_WIDGET_TYPE } from 'constants/common'
import { ALPHABETICAL_INDEX_WIDGET_TYPE_OPTIONS } from '../constants'
import { FIELD_NAMES } from './alphabeticalIndexSchemaValidation'

interface Props {
  validationPrefix: string
}

export const AlphabeticalIndex: React.FC<React.PWC<Props>> = ({
  validationPrefix,
}) => (
  <Flex flexDirection="column">
    <Field name={`${validationPrefix}.${FIELD_NAMES.TITLE}`}>
      {({ field, meta }) => (
        <Input
          label="Title"
          isError={meta.error !== undefined}
          hint={meta.error ?? ''}
          hasHint
          {...field}
        />
      )}
    </Field>
    <Field name={`${validationPrefix}.${FIELD_NAMES.TYPE}`}>
      {({ field }) => (
        <Select
          label="Type"
          defaultValue={find(ALPHABETICAL_INDEX_WIDGET_TYPE_OPTIONS, {
            value: ALPHABETICAL_INDEX_WIDGET_TYPE.BRAND,
          })}
          options={ALPHABETICAL_INDEX_WIDGET_TYPE_OPTIONS}
          isDisabled
          // Uncomment when there will be more options
          // isError={meta.error !== undefined}
          // hint={meta.error ?? ''}
          // hasHint
          {...field}
        />
      )}
    </Field>
  </Flex>
)
