import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { ImageAttachment } from '@admin/routes/Cms/components/ImageAttachment'
import { CheckboxGroup } from 'components/Checkbox'
import { Input } from 'components/Input'
import { Grid } from 'components/Layout'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { SPACE } from 'Theme'

const GRID_TEMPLATE_COLUMNS = { TABLET: null, DESKTOP: '1fr 1fr' }

export const CollectionHeader = ({ validationPrefix }) => (
  <>
    <Grid
      gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
      gridGap={`0 ${SPACE.PX_20}`}
    >
      <Field name={`${validationPrefix}.title`}>
        {({ field, meta }) => (
          <Input
            maxLength={40}
            label="Title"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
      <Field name={`${validationPrefix}.subtitle`}>
        {({ field, meta }) => (
          <Input
            maxLength={100}
            label="Subtitle"
            isError={meta.error !== undefined}
            hint={meta.error ?? ''}
            hasHint
            {...field}
          />
        )}
      </Field>
    </Grid>
    <CheckboxGroup mb={SPACE.PX_20}>
      <Field type="checkbox" name={`${validationPrefix}.isWhiteTextColor`}>
        {({ field: { checked, ...restField } }) => (
          <CheckboxGroup.Item isChecked={checked} {...restField}>
            White text on dark background
          </CheckboxGroup.Item>
        )}
      </Field>
    </CheckboxGroup>
    <Grid gridTemplateColumns={GRID_TEMPLATE_COLUMNS} gridGap={SPACE.PX_20}>
      <ImageAttachment
        hasPaddingOnTop={false}
        label="Desktop header image"
        fieldName={`${validationPrefix}.headerImageAttachment`}
        aspectRatio={ASPECT_RATIO.COLLECTION_HEADER}
      />
      <ImageAttachment
        hasPaddingOnTop={false}
        label="Mobile header image"
        fieldName={`${validationPrefix}.mobileImageAttachment`}
        aspectRatio={ASPECT_RATIO.COLLECTION_HEADER_MOBILE}
        overrideImageProps={{
          aspectRatio: ASPECT_RATIO.COLLECTION_HEADER,
          objectFit: 'cover',
          objectPosition: '50% 100%',
        }}
      />
    </Grid>
  </>
)

CollectionHeader.propTypes = {
  validationPrefix: PropTypes.string.isRequired,
}
