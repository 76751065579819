import * as Yup from 'yup'

export const heroSchemaValidation = Yup.object().shape({
  content: Yup.object().shape({
    hasLandingPageButtons: Yup.boolean(),
    title: Yup.string()
      .label('Title')
      .when('hasLandingPageButtons', {
        is: true,
        then: (title) => title.required(),
      }),
    attachment: Yup.object().shape({
      id: Yup.number().required().label('Image'),
    }),
  }),
})
