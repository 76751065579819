import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { WidgetWrapper } from '@admin/routes/Cms/components/WidgetWrapper'
import { Textarea } from 'components/Textarea'
import { TextEditor } from 'components/TextEditor'
import { TEXT_EDITOR_FORMAT } from 'constants/common'
import {
  deleteItemFromFormList,
  moveItemDownInFormList,
  moveItemUpInFormList,
} from '../../utils'

export const QAItem = ({ qaItemsFieldName, index, cmsQAWidgetItems }) => {
  const qaItemActionProps = {
    index,
    array: cmsQAWidgetItems,
    formLocation: qaItemsFieldName,
  }

  const qaItemName = `${qaItemsFieldName}[${index}]`

  return (
    <Field>
      {({ form: { setFieldValue } }) => (
        <WidgetWrapper
          widgetName={`${index + 1}. QA item`}
          isMoveDownDisabled={index === cmsQAWidgetItems.length - 1}
          isMoveUpDisabled={index === 0}
          onDeleteWidget={() => {
            deleteItemFromFormList({
              setFieldValue,
              ...qaItemActionProps,
            })
          }}
          onMoveDownWidget={() => {
            moveItemDownInFormList({
              setFieldValue,
              ...qaItemActionProps,
            })
          }}
          onMoveUpWidget={() => {
            moveItemUpInFormList({
              setFieldValue,
              ...qaItemActionProps,
            })
          }}
        >
          <Field name={`${qaItemName}.question`}>
            {({ field, meta }) => (
              <Textarea
                {...field}
                hint={meta.error ?? ''}
                isError={meta.error !== undefined}
                hasHint
                rows="3"
                onPaste={(value) => {
                  setFieldValue(field.name, value)
                }}
                label="Question"
              />
            )}
          </Field>
          <Field name={`${qaItemName}.answer`}>
            {({ field, meta }) => (
              <TextEditor
                {...field}
                label="Answer"
                format={TEXT_EDITOR_FORMAT.MARKDOWN}
                onChange={(editorValue) => {
                  setFieldValue(field.name, editorValue)
                }}
                areLinkButtonsVisible
                areHistoryButtonsVisible
                hasHint
                isError={meta.error !== undefined}
                hint={meta.error ?? ''}
              />
            )}
          </Field>
        </WidgetWrapper>
      )}
    </Field>
  )
}

const ItemType = PropTypes.shape({
  id: PropTypes.number,
  question: PropTypes.string,
  answer: PropTypes.string,
})

QAItem.propTypes = {
  cmsQAWidgetItems: PropTypes.arrayOf(ItemType),
  cmsQAWidgetItem: ItemType,
  qaItemsFieldName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}
